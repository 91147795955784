import { FC } from 'react'
import { Box, BoxProps } from '@mui/material'

const DEFAULT_HEIGHT = 32
const DEFAULT_WIDTH = 138

interface LogoProps extends BoxProps {
  variant?: 'basic' | 'full'
}

const Logo: FC<LogoProps> = ({ variant = 'full', ...boxProps }) => {
  return (
    <Box
      component="img"
      src={`/logo/logo${variant === 'full' ? '_full' : ''}.svg`}
      {...boxProps}
      sx={variant === 'full' ? { height: DEFAULT_HEIGHT, width: DEFAULT_WIDTH, ...boxProps.sx } : boxProps.sx}
    />
  )
}

export default Logo
