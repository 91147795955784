import { useMemo } from 'react'
import { Box } from '@mui/material'
import { VerticalStepper } from '@/components/stepper'
import useOnboarding from '@/hooks/useOnboarding'
import { Steps } from './utils'

const OnboardingStepper = () => {
  const { activeStep } = useOnboarding()

  const steps = useMemo(() => {
    return Steps.map(({ label }, index) => ({ id: index, label }))
  }, [])

  return (
    <Box sx={{ marginTop: 14.75 }}>
      <VerticalStepper activeStep={activeStep} steps={steps} />
    </Box>
  )
}

export default OnboardingStepper
