import { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { TextField, alpha, useTheme } from '@mui/material'
import CommunityService from '@/api/CommunityService'
import { Autocomplete, Text } from '@/components'
import useDebounce from '@/hooks/useDebounce'
import { useCommunityDetailsModal } from '@/hooks'
import NFTCommunityDropdownItem from './NFTCommunityDropdownItem'
import { NFTCommunityDropdownProps } from './types'

const NFTCommunityDropdown: FC<NFTCommunityDropdownProps> = ({
  mode,
  shouldShowInfo,
  value,
  setValue,
  showSelectedCommunity,
  title,
  inputLabel,
  disabled,
  ...dropdownProps
}) => {
  const theme = useTheme()
  const [searchQuery, setSearchQuery] = useState(value?.name || '')
  const debouncedQuery = useDebounce(searchQuery, 250)
  const { open, close } = useCommunityDetailsModal()
  const { data, isLoading } = useQuery(
    ['NFTCommunity', debouncedQuery],
    () => CommunityService.searchCommunity(debouncedQuery),
    {
      keepPreviousData: true,
    }
  )

  return (
    <>
      <Text variant="h6" marginBottom={1}>
        {title}
      </Text>
      <Autocomplete
        disabled={disabled}
        loading={isLoading}
        multiple={false}
        inputValue={searchQuery}
        onInputChange={(_event, value) => setSearchQuery(value)}
        value={showSelectedCommunity ? value : null}
        onChange={(e, val) => {
          if (showSelectedCommunity) {
            typeof val !== 'string' && setValue(val || undefined)
          } else {
            val && typeof val !== 'string' && setValue(val)
            setSearchQuery(searchQuery)
          }
        }}
        ListboxProps={{
          sx: {
            backgroundColor: 'grey.800',
            '& .MuiAutocomplete-option': {
              backgroundColor: alpha(theme.palette.grey[500], 0.08),
              padding: 1,

              '&:hover': {
                backgroundColor: alpha(theme.palette.grey[500], 0.16),
              },
            },
          },
        }}
        options={data || []}
        isOptionEqualToValue={(option, value) => (value ? option.address === value.address : false)}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
        renderOption={(props, option) => (
          <NFTCommunityDropdownItem
            {...props}
            community={option}
            mode={mode}
            showInfo={() =>
              open({
                community: option,
                onAdd: (community) => {
                  setValue(community)
                  close()
                },
              })
            }
          />
        )}
        renderInput={(params) => <TextField {...params} label={inputLabel} variant="outlined" fullWidth />}
        {...dropdownProps}
      />
    </>
  )
}

export default NFTCommunityDropdown
