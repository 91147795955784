import { Autocomplete, TextField, Box } from '@mui/material'
import { Controller } from 'react-hook-form'
import { Country, countries } from '@pangea/web-shared/constants/countries'

interface Props {
  control: any
  disabled?: boolean
}

function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode
}

const RHFCountryDropdown = ({ control, disabled }: Props) => {
  return (
    <Controller
      defaultValue={countries.find((c) => c.code === 'US')}
      name="country"
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          disabled={disabled}
          fullWidth
          onChange={(e, data) => onChange(data)}
          value={value}
          autoHighlight
          options={countries}
          getOptionLabel={(option: Country) => option.label}
          renderOption={(props, option: Country) => (
            <Box component="li" {...props} sx={{ px: '8px !important' }}>
              <Box component="span" sx={{ flexShrink: 0, mr: 2, fontSize: 22 }}>
                {countryToFlag(option.code)}
              </Box>
              {option.label} ({option.code})
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              error={!!error}
              helperText={error ? error?.message : ' '}
              {...params}
              placeholder="Country"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'country',
              }}
            />
          )}
        />
      )}
    />
  )
}

export default RHFCountryDropdown
