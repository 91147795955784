import { IntegrationType } from '@pangea/types'
import { useContext } from 'react'
import { IntegrationEvents, IntegrationModalContext } from '@/contexts/IntegrationModalContext'

const useIntegrationModal = ({ onError, onSuccess }: IntegrationEvents) => {
  const { open, close } = useContext(IntegrationModalContext)

  return {
    openModal: (type: IntegrationType) => open(type, { onSuccess, onError }),
    closeModal: close,
  }
}

export default useIntegrationModal
