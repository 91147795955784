import { lazy } from 'react'
import { ProjectOnboardingStatus, ProjectType } from '@pangea/types'
import loadable from '@loadable/component'
import { Project } from '@/types/project'
import { ConfigureExperienceSteps } from './configureYourExperience/types'

export const Steps = [
  {
    id: ProjectOnboardingStatus.CONFIGURE_EXPERIENCE,
    label: 'Configure Your Experience',
    component: loadable(() => import('@/modules/ConfigureYourExperience')),
  },
  {
    id: ProjectOnboardingStatus.SETUP_WORKSPACE,
    label: 'Set Up Your Workspace',
    component: lazy(() => import('@/modules/SetUpYourWorkspace')),
  },
  {
    id: ProjectOnboardingStatus.SELECT_PLAN,
    label: 'Select Plan',
    component: loadable(() => import('@/modules/SelectPlan')),
  },
  {
    id: ProjectOnboardingStatus.START_JOURNEY,
    label: 'Start Your Journey',
    component: lazy(() => import('@/modules/StartYourJourney')),
  },
]

export function getStepIndexById(stepId?: ProjectOnboardingStatus): number {
  if (!stepId) {
    return 0
  }
  return Math.max(
    Steps.findIndex(({ id }) => id === stepId),
    0
  )
}

export function getConfigureYourExperienceInitialState({ id, type, contractAddress, websiteUrl }: Partial<Project>) {
  const initialStep = id
    ? ConfigureExperienceSteps.COMPLETE
    : type
    ? ConfigureExperienceSteps.BRAND_ENTRY
    : ConfigureExperienceSteps.SELECT_GOAL

  const initialState = {
    ...{
      step: initialStep,
      type: type as ProjectType,
      id,
      websiteUrl,
      contractAddress,
    },
  }
  return initialState
}
