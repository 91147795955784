import { useQuery } from 'react-query'
import { Controller, useFormContext } from 'react-hook-form'
import { CampaignTemplateCode, castRewardDefinitionData, NftCampaignDetail } from '@pangea/types'
import { Error, Loader, Text, TextField } from '@/components'
import { useProject } from '@/hooks'
import { SquareService } from '@/api'
import Container from '../../Container'

interface SquareRewardDefinitionProps {
  campaign: NftCampaignDetail
}

const SquareRewardDefinition: React.FC<SquareRewardDefinitionProps> = ({ campaign }) => {
  const { control, setValue } = useFormContext()
  const { project } = useProject()
  const { data, isLoading, isError } = useQuery(['loyalty-program', project?.id], () =>
    SquareService.getLoyaltyProgram(project!.id, campaign.integration!.id)
  )
  //todo: frane added this, check if it's good for FE standards
  const rewardDefinitionData = castRewardDefinitionData<CampaignTemplateCode.SQUARE_LOYALTY_POINTS>(
    campaign.rewardDefinition.rewardDefinitionData
  )

  return (
    <>
      <Container mt={3}>
        <Text variant="h5">Reward Definition</Text>
        <Text variant="caption" color="text.secondary" display="block" mb={2}>
          Select a reward
        </Text>
        <Container mb={4} px={2} pb={2.5}>
          {isLoading ? (
            <Loader />
          ) : isError || !data ? (
            <Error msg="Failed to load reward tiers" />
          ) : (
            <>
              {data.loyaltyProgram?.rewardTiers.map((tier) => (
                <Text
                  variant="subtitle1"
                  key={tier.name}
                  p={1}
                  mb={0.5}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: 'background.neutral',
                      borderRadius: 1,
                    },
                  }}
                  onClick={() => setValue('loyaltyPoints', tier.points)}
                >
                  {tier.name} - <Text display="inline">{tier.points} loyalty points</Text>
                </Text>
              ))}
            </>
          )}
        </Container>
        <Controller
          name="loyaltyPoints"
          defaultValue={rewardDefinitionData.points}
          control={control}
          render={({ field }) => <TextField label="Loyalty points" {...field} width={300} />}
        />
      </Container>
    </>
  )
}

export default SquareRewardDefinition
