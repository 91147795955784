import { Box, BoxProps, Skeleton } from '@mui/material'

interface NftCommunityListItemSkeletonProps extends BoxProps {
  detailed?: boolean
}

const NftCommunityListItemSkeleton: React.FC<NftCommunityListItemSkeletonProps> = ({ detailed, ...boxProps }) => {
  return (
    <Box display="flex" justifyContent="space-between" p={detailed ? 2 : 1} borderRadius={1} {...boxProps}>
      <Box flexGrow={1} display="flex" alignItems="center">
        <Skeleton variant="rounded" width={48} height={48} sx={{ mx: 2 }} />
        <Box>
          <Skeleton variant="text" width={125} height={32} />
          {detailed && (
            <Box display="flex">
              <Skeleton variant="text" width={30} />
              <Skeleton variant="text" width={30} sx={{ mx: 1 }} />
              <Skeleton variant="text" width={30} />
            </Box>
          )}
        </Box>
      </Box>
      {detailed ? (
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Skeleton variant="text" width={80} />
            <Skeleton variant="text" width={40} />
          </Box>
          <Box>
            <Skeleton variant="text" width={80} />
            <Skeleton variant="text" width={40} />
          </Box>
        </Box>
      ) : (
        <Box flexGrow={1} display="flex" alignItems="center" justifyContent="flex-end">
          <Skeleton variant="rounded" width={24} height={24} sx={{ mr: 2 }} />
          <Skeleton variant="text" width={100} height={32} />
        </Box>
      )}
    </Box>
  )
}

export default NftCommunityListItemSkeleton
