import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function List(theme: Theme) {
  return {
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.neutral,
          border: 'solid',
          borderWidth: 1,
          borderColor: theme.palette.action.disabled,
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .MuiListItemIcon-root': {
            paddingRight: theme.spacing(1.5),
          },
          '& .MuiListItemAvatar-root': {
            paddingRight: theme.spacing(1.5),
          },
          '& .MuiBox-root': {
            padding: 0,
            margin: 0,
            '& .MuiButtonBase-root': {
              paddingRight: theme.spacing(1.5),
              paddingLeft: 0,
              paddingTop: 0,
              paddingBottom: 0,
              margin: 0,
            },
          },
        },
        padding: {
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          color: 'inherit',
          minWidth: 'auto',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1.5),
          color: 'inherit',
          minWidth: 'auto',
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
        },
      },
    },
  }
}
