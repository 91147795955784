import { Box } from '@mui/material'
import { CommunityCategoryIcon, Text } from '@/components'
import { undefinedValue } from '../utils'
import { TradingDataProps } from '../types'

const TradingData = ({ isAdvanced = true, icon, title, body = undefinedValue }: TradingDataProps) => {
  return (
    <Box
      display="flex"
      flexDirection={isAdvanced ? 'column' : 'row'}
      alignItems={isAdvanced ? 'start' : 'center'}
      gap={isAdvanced ? 0 : 1}
    >
      <Text variant="caption" color="grey.400">
        {title}
      </Text>
      <Box display="flex" flexDirection="row" gap={0.6}>
        {icon && <CommunityCategoryIcon category={icon} />}
        <Text width={isAdvanced ? 'auto' : 46} variant="subtitle2">
          {body}
        </Text>
      </Box>
    </Box>
  )
}

export default TradingData
