import { useMemo } from 'react'
import { Popover as MuiPopover, PopoverOrigin, PopoverProps } from '@mui/material'
import { StyledArrow } from './styles'
import getPosition from './getPosition'

export type PopoverArrowValue =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right'
  | 'left-top'
  | 'left-center'
  | 'left-bottom'
  | 'right-top'
  | 'right-center'
  | 'right-bottom'

interface IPopoverProps extends PopoverProps {
  arrow?: PopoverArrowValue
  disabledArrow?: boolean
}

export default function Popover({
  anchorEl,
  arrow = 'top-right',
  children,
  disabledArrow,
  open,
  sx,
  ...props
}: IPopoverProps) {
  const { style, anchorOrigin, transformOrigin } = useMemo(() => getPosition(arrow), [arrow])

  return (
    <MuiPopover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin as PopoverOrigin}
      transformOrigin={transformOrigin as PopoverOrigin}
      PaperProps={{
        sx: {
          ...style,
          ...sx,
        },
      }}
      {...props}
    >
      {!disabledArrow && <StyledArrow arrow={arrow} />}

      {children}
    </MuiPopover>
  )
}
