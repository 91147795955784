/**
 * **@CampaignTemplateCode** Enum representing the possible codes for a **@CampaignTemplate**.
 * @example CampaignTemplateCode.MINDBODY_ITEM_GIVEAWAY
 */
export var CampaignTemplateCode;
(function (CampaignTemplateCode) {
    /** The **@CampaignTemplateCode** for a giveaway item in MindBody */
    CampaignTemplateCode["MINDBODY_ITEM_GIVEAWAY"] = "MINDBODY_ITEM_GIVEAWAY";
    /** The **@CampaignTemplateCode** for a coupon code in MindBody */
    CampaignTemplateCode["MINDBODY_COUPON_CODE"] = "MINDBODY_COUPON_CODE";
    /** The **@CampaignTemplateCode** for loyalty points in Square */
    CampaignTemplateCode["SQUARE_LOYALTY_POINTS"] = "SQUARE_LOYALTY_POINTS";
    /** The **@CampaignTemplateCode** for loyalty points in Square */
    CampaignTemplateCode["SHOPIFY_DISCOUNT_CODE"] = "SHOPIFY_DISCOUNT_CODE";
    /** The **@CampaignTemplateCode** for loyalty points in Square */
    CampaignTemplateCode["STRIPE_DISCOUNT_CODE"] = "STRIPE_DISCOUNT_CODE";
    /** The **@CampaignTemplateCode** for gift cards in Lightspeed */
    CampaignTemplateCode["LIGHTSPEED_GIFT_CARD"] = "LIGHTSPEED_GIFT_CARD";
    /** The **@CampaignTemplateCode** for discount codes in Wix */
    CampaignTemplateCode["WIX_DISCOUNT_CODE"] = "WIX_DISCOUNT_CODE";
    /** The **@CampaignTemplateCode** for discount codes in BigCommerce */
    CampaignTemplateCode["BIGCOMMERCE_DISCOUNT_CODE"] = "BIGCOMMERCE_DISCOUNT_CODE";
    /** The **@CampaignTemplateCode** for secret reveal, internal integration */
    CampaignTemplateCode["INTERNAL_SECRET"] = "INTERNAL_SECRET";
})(CampaignTemplateCode || (CampaignTemplateCode = {}));
/**
 * **@RewardStatus** Enum representing the possible statuses for a reward.
 * @example RewardStatus.CLAIMED
 */
export var RewardStatus;
(function (RewardStatus) {
    /** The **@RewardStatus** for a claimed reward */
    RewardStatus["CLAIMED"] = "CLAIMED";
    /** The **@RewardStatus** for a created reward */
    RewardStatus["CREATED"] = "CREATED";
    /** The **@RewardStatus** for a shared reward */
    RewardStatus["SHARED"] = "SHARED";
    /** The **@RewardStatus** for a redeemed reward */
    RewardStatus["REDEEMED"] = "REDEEMED";
})(RewardStatus || (RewardStatus = {}));
export function toRewardDefinition(rewardDefinition) {
    return {
        id: rewardDefinition.id,
        projectId: rewardDefinition.projectId,
        campaignId: rewardDefinition.campaignId,
        integrationId: rewardDefinition.integrationId ?? undefined,
        rewardDefinitionData: rewardDefinition.rewardDefinitionData,
        rewardTemplateData: castRewardTemplateData(rewardDefinition.rewardTemplateData),
    };
}
export function castRewardTemplateData(rewardTemplateData) {
    return rewardTemplateData;
}
