import { Box, BoxProps } from '@mui/material'
import { useNavigate } from 'react-router'
import {
  CampaignTemplate,
  CampaignTemplateCode,
  IntegrationStatus,
  RulesType,
  SupportedBlockchains,
} from '@pangea/types'
import { Text } from '@/components'
import { CampaignService, IntegrationService } from '@/api'
import { useProject, useSelectedCommunities } from '@/hooks'
import useIntegrationModal from '@/modules/useIntegrationModal'
import { getCampaignWizardUrl } from '@/utils/links'
import { isCampaignIntegrationRequired } from '@/utils/campaign'
import TemplateTypeIcon from './TemplateTypeIcon'

interface IPredefinedTemplateProps extends BoxProps {
  template: CampaignTemplate
}

const templateDescription: Record<CampaignTemplateCode, string> = {
  [CampaignTemplateCode.MINDBODY_ITEM_GIVEAWAY]: 'Easily create and manage giveaways',
  [CampaignTemplateCode.MINDBODY_COUPON_CODE]: 'Create a shareable promotional code',
  [CampaignTemplateCode.SQUARE_LOYALTY_POINTS]: 'Easily create and manage giveaways',
  [CampaignTemplateCode.INTERNAL_SECRET]: 'Share a secret with NFT holders',
  [CampaignTemplateCode.SHOPIFY_DISCOUNT_CODE]: 'Reward NFT holders with Discount Codes',
  [CampaignTemplateCode.STRIPE_DISCOUNT_CODE]: 'Reward NFT holders with Discount Codes',
  [CampaignTemplateCode.LIGHTSPEED_GIFT_CARD]: 'Reward NFT holders with Gift Cards containing exclusive discounts',
  [CampaignTemplateCode.WIX_DISCOUNT_CODE]: 'Reward NFT holders with Discount Codes',
  [CampaignTemplateCode.BIGCOMMERCE_DISCOUNT_CODE]: 'Reward NFT holders with Discount Codes',
}

const enabledTemplateCodes: CampaignTemplateCode[] = [
  CampaignTemplateCode.INTERNAL_SECRET,
  CampaignTemplateCode.MINDBODY_ITEM_GIVEAWAY,
  CampaignTemplateCode.SQUARE_LOYALTY_POINTS,
  CampaignTemplateCode.SHOPIFY_DISCOUNT_CODE,
  CampaignTemplateCode.STRIPE_DISCOUNT_CODE,
  CampaignTemplateCode.LIGHTSPEED_GIFT_CARD,
  CampaignTemplateCode.WIX_DISCOUNT_CODE,
  CampaignTemplateCode.BIGCOMMERCE_DISCOUNT_CODE,
]

const PredefinedTemplate: React.FunctionComponent<IPredefinedTemplateProps> = ({ template, ...boxProps }) => {
  const navigate = useNavigate()
  const { project } = useProject()
  const { selectedCommunities, clearCommunities } = useSelectedCommunities()
  const disabled = !enabledTemplateCodes.includes(template.templateCode)
  const { openModal } = useIntegrationModal({
    onError: () => {},
    onSuccess: () => {},
  })

  const createCampaign = async () => {
    try {
      const integrationRequired = isCampaignIntegrationRequired(template.integrationType)

      const integrations = await IntegrationService.getAll(project!.id)
      const activeIntegration = integrations.find(
        (i) => i.integrationType === template.integrationType && i.status === IntegrationStatus.ACTIVE
      )

      if (!integrationRequired || activeIntegration) {
        const campaign = await CampaignService.create(project!.id, {
          integrationId: activeIntegration?.id,
          templateCode: template.templateCode,
          nftRules: {
            rules: selectedCommunities.map((c) => ({
              blockchain: c.blockchain as SupportedBlockchains,
              smartContractId: c.address,
              requiredTraits: [],
              count: 1,
            })),
            type: RulesType.NFT,
          },
        })
        clearCommunities()
        navigate(getCampaignWizardUrl(campaign.id))
      } else openModal(template.integrationType)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Box
        {...boxProps}
        bgcolor="rgba(145, 158, 171, 0.16)"
        borderRadius={2}
        p={3}
        display="flex"
        sx={{
          opacity: disabled ? 0.5 : 1,
          cursor: !disabled ? 'pointer' : undefined,
        }}
        onClick={!disabled ? createCampaign : undefined}
      >
        <TemplateTypeIcon type={template.templateCode} size={64} mr={3} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Text variant="subtitle1">{template.templateTitle}</Text>
          <Text variant="caption">{templateDescription[template.templateCode]}</Text>
        </Box>
      </Box>
    </>
  )
}

export default PredefinedTemplate
