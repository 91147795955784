import {
  MindbodyActivationCode,
  MindbodyActivationStatus,
  MindbodyAuthUrl,
  MindbodyPricingOption,
  Paginated,
} from '@pangea/types'
import { AuthenticatedClient } from '.'

const authorizeAccess = async (projectId: string, siteId: string) => {
  const { data } = await AuthenticatedClient.get<MindbodyAuthUrl>(
    `/project/${projectId}/integrations/mindbody/auth?siteId=${siteId}`
  )
  return data
}

const getActivationCode = async (projectId: string, integrationId: string) => {
  const { data } = await AuthenticatedClient.get<MindbodyActivationCode>(
    `/project/${projectId}/integrations/mindbody/activation-code?integrationId=${integrationId}`
  )
  return data
}

const syncActivation = async (projectId: string, integrationId: string) => {
  const { data } = await AuthenticatedClient.post<MindbodyActivationStatus, never>(
    `/project/${projectId}/integrations/mindbody/${integrationId}/sync-activation-status`
  )
  return data
}

const getPricingOptions = async (projectId: string) => {
  const { data } = await AuthenticatedClient.get<Paginated<MindbodyPricingOption>>(
    `/project/${projectId}/integrations/mindbody/pricing-options`
  )
  return data
}

const IntegrationService = {
  authorizeAccess,
  getActivationCode,
  syncActivation,
  getPricingOptions,
}

export default IntegrationService
