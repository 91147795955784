export const QUEUE_NAME = 'DefaultQueue';
export const queueDefaultSettings = {
    name: QUEUE_NAME,
    /** Important: we only want to trigger a single lambda for each delivery **/
    batchSize: 1,
    visibilityTimeoutMinutes: 15,
};
export var RequestType;
(function (RequestType) {
    RequestType["ENRICHMENT"] = "enrichment";
    RequestType["MINTING"] = "minting";
    RequestType["IMPORTING"] = "importing";
})(RequestType || (RequestType = {}));
export function safeCastQueueRequest(queueRequest, type) {
    if (queueRequest.requestType === type) {
        return queueRequest;
    }
    throw new Error('Casting error!');
}
