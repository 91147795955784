import { Box } from '@mui/material'
import OnboardingStepHeader, { OnboardingStepHeaderProps } from './OnboardingStepHeader'

interface OnboardingStepProps extends OnboardingStepHeaderProps {
  actions?: React.ReactNode
  children: React.ReactNode
}

/**
 * Component that will be used in every step as a wrapper for content
 * @param children - step children
 * @param title - step title, will be passed to header
 * @param description - step description, will be passed to header [optional]
 */
const OnboardingStep: React.FC<OnboardingStepProps> = ({ actions, children, ...headerProps }) => {
  return (
    // TODO: We need to depend on flax basis here instead of using hc marginTop
    // to enable flexible layouts in vertical space
    <Box flex={1} marginTop={16.75} display="flex" flexDirection="column">
      <OnboardingStepHeader {...headerProps} />
      <Box flex={10}>{children}</Box>
    </Box>
  )
}

export default OnboardingStep
