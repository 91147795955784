import { Box, Stack } from '@mui/material'
import { getProjectLogoUrl } from '@shared/utils/project'
import { useNavigate } from 'react-router-dom'
import { ProjectOnboardingStatus } from '@pangea/types'
import { useCallback } from 'react'
import { Menu, Iconify, Text, Avatar } from '@/components'
import { useProject } from '@/hooks'
import { URLS } from '@/utils/routes'
import { Project } from '@/types/project'
import { AuthService } from '@/api'

interface ProjectDropdownProps {
  anchorEl: HTMLElement | null
  onClose: () => void
}

const ProjectDropdown: React.FC<ProjectDropdownProps> = ({ onClose, anchorEl }) => {
  const navigate = useNavigate()
  const { projects, setSelectedProject } = useProject()

  const handleNewProject = useCallback(() => {
    navigate(URLS.ONBOARDING)
    setSelectedProject(undefined)
  }, [navigate, setSelectedProject])

  const handleProjectSelect = useCallback(
    (project: Project) => {
      setSelectedProject(project)
      onClose()
    },
    [onClose, setSelectedProject]
  )

  return (
    <Menu
      anchorEl={anchorEl}
      items={[
        {
          children: (
            <Box display="flex" alignItems="center">
              <Iconify icon="tabler:plus" sx={{ width: 24, height: 24, mr: 2 }} />
              <Text variant="subtitle1">Create new project</Text>
            </Box>
          ),
          onClick: handleNewProject,
        },
        ...(projects?.map((project) => ({
          children: (
            <Stack direction="row" alignItems="center">
              <Avatar src={getProjectLogoUrl(project.id)} alt={project.name} sx={{ width: 24, height: 24, mr: 2 }} />
              <Stack direction="column">
                <Text variant="subtitle1">{project.name}</Text>
                {project.onboardingStatus !== ProjectOnboardingStatus.COMPLETE && (
                  <Text variant="caption" color="text.secondary">
                    Draft
                  </Text>
                )}
              </Stack>
            </Stack>
          ),
          onClick: () => handleProjectSelect(project),
        })) || []),
        {
          children: (
            <Box display="flex" alignItems="center">
              <Iconify
                icon="tabler:logout"
                sx={{ width: 24, height: 24, mr: 2, color: (theme) => theme.palette.error.main }}
              />
              <Text variant="subtitle1" color="error">
                Logout
              </Text>
            </Box>
          ),
          onClick: AuthService.logout,
        },
      ]}
      open
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        variant: 'outlined',
      }}
      sx={{
        '& .MuiPaper-root': { width: 240, marginTop: 11, p: 0, overflow: 'hidden' },
        '& .MuiBackdrop-root': { backdropFilter: 'none' },
        '& .MuiList-root': { border: 'none', p: 0 },
        '& .MuiMenuItem-root': { py: 1.5, pr: 1, pl: 2, borderRadius: 0 },
        '& .MuiMenuItem-root:last-child': { borderTop: (theme) => `1px solid ${theme.palette.divider}` },
      }}
    />
  )
}

export default ProjectDropdown
