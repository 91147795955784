import { AddProjectUser, GetProjectUser, ProjectRole, UpdateProjectUser } from '@pangea/types'
import { AuthenticatedClient } from '.'

const getUsers = async (projectId: string) => {
  const res = await AuthenticatedClient.get<GetProjectUser[]>(`/project/${projectId}/users`)
  return res.data
}

const addUser = async (projectId: string, user: AddProjectUser) => {
  const res = await AuthenticatedClient.post<GetProjectUser, AddProjectUser>(`/project/${projectId}/users`, user)
  return res.data
}

interface UpdateUserRes {
  customerId: string
  role: ProjectRole
}

const updateUser = async (projectId: string, user: string, role: ProjectRole) => {
  await AuthenticatedClient.patch<UpdateUserRes, UpdateProjectUser>(`/project/${projectId}/users/${user}`, {
    role,
  })

  return { customerId: user, role }
}

const removeUser = async (projectId: string, user: string) => {
  const res = await AuthenticatedClient.del(`/project/${projectId}/users/${user}`)

  return res.data
}

const ProjectUserService = {
  getUsers,
  addUser,
  updateUser,
  removeUser,
}

export default ProjectUserService
