import { NFTCommunity } from '@pangea/types'
import { UseQueryOptions, useQuery } from 'react-query'
import { Box } from '@mui/material'
import { splitIntoEqualChunks } from '@shared/utils/splitArray'
import GenericError from '../GenericError'
import NftCommunityListItem from './NftCommunityListItem'
import NftCommunityListItemSkeleton from './NftCommunityListItem/NftCommunityListItemSkeleton'

interface NFTCommunityListProps {
  communityQuery: UseQueryOptions<NFTCommunity[]>
  showDetails?: boolean
  rowGap?: number
  columnGap?: number
  columns?: number
}

const NFTCommunityList: React.FC<NFTCommunityListProps> = ({
  communityQuery,
  showDetails,
  rowGap,
  columnGap,
  columns = 1,
}) => {
  const { data, isLoading, isError } = useQuery(communityQuery)

  if (!isLoading && (isError || !data)) return <GenericError msg="Failed to load communities" />

  const communityColumns = splitIntoEqualChunks(data || [], columns)

  return (
    <Box display="flex">
      {isLoading
        ? [[...Array(4)], [...Array(4)]].map((items, i) => (
            <Box key={i} flexBasis="100%" mr={i < communityColumns.length - 1 ? columnGap : ''}>
              {items.map((_, i) => (
                <NftCommunityListItemSkeleton key={i} detailed={showDetails} />
              ))}
            </Box>
          ))
        : communityColumns.map((communities, i) => (
            <Box key={communities[0].address} flexBasis="100%" mr={i < communityColumns.length - 1 ? columnGap : ''}>
              {communities.map((community, j) => (
                <NftCommunityListItem
                  key={community.address}
                  community={community}
                  index={i < 1 ? j : j + communityColumns[i].length}
                  detailed={showDetails}
                  mt={j > 0 ? rowGap : ''}
                />
              ))}
            </Box>
          ))}
    </Box>
  )
}

export default NFTCommunityList
