import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import useSelectedCommunities from '@/hooks/useSelectedCommunities'
import { Iconify, Text, AvatarGroup } from '@/components'
import { ReactComponent as DesignIcon } from '@/assets/icons/ic-design.svg'

const SelectedCommunitiesBanner = () => {
  const [isVisible, setIsVisible] = useState('')
  const { selectedCommunities, removeCommunity } = useSelectedCommunities()
  const navigate = useNavigate()

  const handleAdd = () => navigate('/campaigns/create')

  return (
    <Box
      px={3}
      py={2}
      sx={{ background: (theme) => theme.gradients.main }}
      width="100%"
      mb={4}
      borderRadius={2}
      display="flex"
      alignItems="center"
    >
      <Box
        width={48}
        height={48}
        display="flex"
        alignItems="center"
        justifyContent="center"
        mr={2}
        bgcolor="rgba(255, 255, 255, 0.5)"
        borderRadius={1}
        onClick={handleAdd}
        sx={{ cursor: 'pointer' }}
      >
        <Iconify icon="fluent:add-24-filled" color="black" />
      </Box>
      <Box p={1.25} flexGrow={1}>
        <Text variant="subtitle1">Create a new campaign</Text>
        <Text variant="caption">Start from scratch and create your utility from ground up!</Text>
      </Box>
      {selectedCommunities.length ? (
        <AvatarGroup
          avatars={selectedCommunities.map((c) => ({
            src: c.images.imageUrl,
            title: c.name,
            sx: { width: 32, height: 32, border: '2px solid white !important' },
            onMouseEnter: () => setIsVisible(c.address),
            onMouseLeave: () => setIsVisible(''),
            badgeProps: {
              components: {
                Badge: () => (
                  <Iconify
                    display={isVisible === c.address ? 'block' : 'none'}
                    icon="iconamoon:close-bold"
                    color="black"
                    position="absolute"
                    top={-4}
                    right={-4}
                    sx={{ bgcolor: 'white', borderRadius: '50%', width: 16, height: 16 }}
                    onClick={() => removeCommunity(c)}
                    onMouseEnter={() => setIsVisible(c.address)}
                    onMouseLeave={() => setIsVisible('')}
                  />
                ),
              },
            },
          }))}
          max={8}
        />
      ) : (
        <DesignIcon fill="white" opacity={0.5} />
      )}
    </Box>
  )
}

export default SelectedCommunitiesBanner
