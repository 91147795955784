/**
 * **@ProjectType** Enum representing the type of a Pangea project.
 * @example ProjectType.Brand
 */
var ProjectType;
(function (ProjectType) {
    ProjectType["BRAND"] = "brand";
    ProjectType["NFT"] = "nft";
})(ProjectType || (ProjectType = {}));
/**
 * **@ProjectOnboardingStatus** Enum representing the onboarding status of a Pangea project.
 * @example ProjectOnboardingStatus.SELECT_PLAN
 * @description 1- CONFIGURE_EXPERIENCE, 2- SETUP_WORKSPACE, 3- SELECT_PLAN, 4- START_JOURNEY, 5- COMPLETE
 */
var ProjectOnboardingStatus;
(function (ProjectOnboardingStatus) {
    ProjectOnboardingStatus["CONFIGURE_EXPERIENCE"] = "CONFIGURE_EXPERIENCE";
    ProjectOnboardingStatus["SETUP_WORKSPACE"] = "SETUP_WORKSPACE";
    ProjectOnboardingStatus["SELECT_PLAN"] = "SELECT_PLAN";
    ProjectOnboardingStatus["START_JOURNEY"] = "START_JOURNEY";
    ProjectOnboardingStatus["COMPLETE"] = "COMPLETE";
})(ProjectOnboardingStatus || (ProjectOnboardingStatus = {}));
/**
 * **@ProjectRole** Enum representing the role of a Pangea project user.
 * @example ProjectRole.Owner
 */
var ProjectRole;
(function (ProjectRole) {
    ProjectRole["OWNER"] = "owner";
    ProjectRole["ADMIN"] = "admin";
    ProjectRole["VIEWER"] = "viewer";
})(ProjectRole || (ProjectRole = {}));
/**
 * **@ProjectStatus** Enum representing the status of a Pangea project.
 * @example ProjectStatus.COMPLETE
 * @description 1- INCOMPLETE, 2- COMPLETE
 */
var ProjectStatus;
(function (ProjectStatus) {
    ProjectStatus["INCOMPLETE"] = "INCOMPLETE";
    ProjectStatus["COMPLETE"] = "COMPLETE";
})(ProjectStatus || (ProjectStatus = {}));
/**
 @DataEnrichmentStatus Enum representing the data enrichment status of a Pangea project.
 @example DataEnrichmentStatus.NOT_STARTED
 @description 1- NOT_STARTED, 2- STARTED, 3- DATA_EXTRACTED, 4- FAILED, 5- COMPLETED
 */
var DataEnrichmentStatus;
(function (DataEnrichmentStatus) {
    DataEnrichmentStatus["NOT_STARTED"] = "NOT_STARTED";
    DataEnrichmentStatus["STARTED"] = "STARTED";
    DataEnrichmentStatus["DATA_EXTRACTED"] = "DATA_EXTRACTED";
    DataEnrichmentStatus["FAILED"] = "FAILED";
    DataEnrichmentStatus["COMPLETED"] = "COMPLETED";
})(DataEnrichmentStatus || (DataEnrichmentStatus = {}));
function isProjectRole(test) {
    return Object.values(ProjectRole).indexOf(test) !== -1;
}
export { ProjectType, ProjectOnboardingStatus, ProjectRole, ProjectStatus, isProjectRole, DataEnrichmentStatus, };
