import { Stack, Button, Typography } from '@mui/material'

export default function NavDocs() {
  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        display: 'block',
        textAlign: 'center',
      }}
    >
      <div>
        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          Need help, <br /> Please check our docs
        </Typography>
      </div>

      <Button variant="contained" href={process.env.REACT_APP_DOCS_URL}>
        Documentation
      </Button>
    </Stack>
  )
}
