import { PaginationInput } from '@pangea/types'
import { URLS } from './routes'

export function getOpenSeaCommunityURL(slug: string): string {
  return `https://opensea.io/collection/${slug}`
}

export function getTwitterURL(username: string): string {
  return `https://twitter.com/${username}`
}

export function getDiscordURL(username: string): string {
  return `https://discord.gg/${username}`
}

export function getInstagramURL(username: string): string {
  return `https://www.instagram.com/${username}`
}

export function getCampaignUrl(campaignId: string): string {
  return `${URLS.NFT_CAMPAIGNS}/${campaignId}`
}

export const getCampaignWizardUrl = (campaignId: string) => {
  return `/campaigns/${campaignId}`
}

export const isValidUrl = (url: string) => {
  try {
    const validUrl = new URL(url)
    return validUrl.protocol === 'https:' && validUrl.host.endsWith('opensea.io')
  } catch (error) {
    return false
  }
}

export const withPagination = (route: string, pagination?: PaginationInput) => {
  if (!pagination) return route

  const { page, pageSize } = pagination
  return `${route}?page=${page}&size=${pageSize}`
}
