// import PaymentSuccess from './PaymentSuccess.png'
// import PaymentFailure from './PaymentFailure.png'
import rocket from './rocket.png'

export { default as SentIcon } from './SentIcon'
export { default as PasswordIcon } from './PasswordIcon'
export { default as PlanFreeIcon } from './PlanFreeIcon'
export { default as EmailInboxIcon } from './EmailInboxIcon'
export { default as PlanStarterIcon } from './PlanStarterIcon'
export { default as PlanPremiumIcon } from './PlanPremiumIcon'

export const images = {
  success: rocket,
  failure: rocket,
}
