import { Controller, useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import { InternalSecretReward, NftCampaignDetail } from '@pangea/types'
import { Text, TextField } from '@/components'

import Container from '../../Container'
export interface InternalSecretRewardDefinitionProps {
  campaign: NftCampaignDetail
}

const InternalSecretRewardDefinition: React.FC<InternalSecretRewardDefinitionProps> = ({ campaign }) => {
  const { control } = useFormContext()
  const initialValue = campaign.rewardDefinition.rewardDefinitionData as InternalSecretReward

  return (
    <>
      <Container mt={3}>
        <Text variant="h5" mb={3}>
          Reward Definition
        </Text>
        <Box display="flex">
          <Controller
            name="reward"
            defaultValue={initialValue ? initialValue.reward : null}
            control={control}
            render={({ field }) => <TextField label="Secret Value" {...field} sx={{ flexGrow: 1 }} />}
          />
        </Box>
      </Container>
    </>
  )
}

export default InternalSecretRewardDefinition
