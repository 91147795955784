import { AuthenticatedClient } from './'

const importOpenSeaCommunity = async (projectId: string, openSeaUrl: string) => {
  const url = `/project/${projectId}/community/import/slug?openSeaUrl=${openSeaUrl}`
  const res = await AuthenticatedClient.get<{ message: string }>(url)

  return res.data
}

const CommunityImportService = {
  importOpenSeaCommunity,
}

export default CommunityImportService
