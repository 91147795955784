import { TabContext, TabPanel } from '@mui/lab'
import { Box } from '@mui/material'
import { useState } from 'react'
import { NftCampaignDetail } from '@pangea/types'
import { getProjectLogoUrl } from '@shared/utils/project'
import { useEnv } from '@/hooks'
import getBanner from '@/components/Snippets/Banner'
import getClaimRewardButton from '@/components/Snippets/ClaimButton'
import { Reward, Tabs } from '@/components'

enum PreviewTabs {
  offerBanner = 'Offer banner',
  claimButton = 'Claim button',
  reward = 'Reward',
  campaignPage = 'Campaign page',
}

interface PreviewTabProps {
  campaign: NftCampaignDetail
}

const tabStyles = {
  bgcolor: 'background.neutral',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
}

const PreviewTab: React.FC<PreviewTabProps> = ({ campaign }) => {
  const [currentTab, setCurrentTab] = useState(PreviewTabs.offerBanner)
  const { REACT_APP_NOTA_URL } = useEnv()
  const campaignUrl = `${REACT_APP_NOTA_URL}/campaign/${campaign.id}`
  const campaignClaimUrl = `${campaignUrl}/claim`

  const banner = getBanner({
    ...campaign.rewardDefinition.rewardTemplateData.campaignDesign,
    campaignUrl: campaignClaimUrl,
  })
  const claimButton = getClaimRewardButton(campaignClaimUrl)

  return (
    <Box
      border={(theme) => `1px solid ${theme.palette.divider}`}
      borderRadius={1}
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <TabContext value={currentTab}>
        <Tabs
          value={currentTab}
          onChange={(e, val) => setCurrentTab(val)}
          variant="fullWidth"
          tabs={[
            { value: PreviewTabs.offerBanner },
            { value: PreviewTabs.claimButton },
            { value: PreviewTabs.reward },
            { value: PreviewTabs.campaignPage },
          ]}
          sx={{ width: 550 }}
        />
        <TabPanel
          value={PreviewTabs.offerBanner}
          sx={{ ...tabStyles, display: currentTab !== PreviewTabs.offerBanner ? 'none' : 'flex' }}
        >
          <div dangerouslySetInnerHTML={{ __html: banner }} />
        </TabPanel>
        <TabPanel
          value={PreviewTabs.claimButton}
          sx={{ ...tabStyles, display: currentTab !== PreviewTabs.claimButton ? 'none' : 'flex' }}
        >
          <div dangerouslySetInnerHTML={{ __html: claimButton }} />
        </TabPanel>
        <TabPanel
          value={PreviewTabs.reward}
          sx={{ ...tabStyles, display: currentTab !== PreviewTabs.reward ? 'none' : 'flex' }}
        >
          <Reward
            brandImg={getProjectLogoUrl(campaign.projectId)}
            brandName={campaign.projectName || ''}
            title={campaign.rewardDefinition.rewardTemplateData.rewardDesign.title}
            description={campaign.rewardDefinition.rewardTemplateData.rewardDesign.description}
          />
        </TabPanel>
        <TabPanel
          value={PreviewTabs.campaignPage}
          sx={{ ...tabStyles, display: currentTab !== PreviewTabs.campaignPage ? 'none' : 'flex' }}
        >
          <iframe src={campaignUrl} style={{ width: 500, height: 800, border: 0 }} title="Campaign banner" />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default PreviewTab
