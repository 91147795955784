import { BoxProps } from '@mui/material'
import { forwardRef } from 'react'
import { Iconify } from '@/components'
import RoundContainer from './RoundContainer'

const Info: React.FC<BoxProps> = forwardRef((props, ref) => (
  <RoundContainer {...{ ...props, ref }} bgOnHover>
    <Iconify icon="ci:info" color="text.secondary" />
  </RoundContainer>
))

Info.displayName = 'Info'

export default Info
