import { FC } from 'react'
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio as MUIRadio,
  RadioProps as MUIRadioProps,
  SxProps,
} from '@mui/material'

export interface RadioProps
  extends MUIRadioProps,
    Pick<FormControlLabelProps, 'labelPlacement' | 'disableTypography' | 'label' | 'disabled'> {
  labelSx?: SxProps
}

const Radio: FC<RadioProps> = ({ label, value, labelPlacement, labelSx, disabled, ...radioProps }) => (
  <FormControlLabel
    labelPlacement={labelPlacement}
    value={value}
    label={label}
    disabled={disabled}
    sx={labelSx}
    control={<MUIRadio {...radioProps} />}
  />
)

export default Radio
