import { useContext } from 'react'
import { UserPreferencesContext } from '@/contexts/UserPreferencesContext'

/**
 * Stores user preferences, currently only alert visibility state
 */
const useUserPreferences = () => {
  return useContext(UserPreferencesContext)
}

export default useUserPreferences
