import { Button, Image, Text } from '@/components'
import ErrorPageContainer from './ErrorPageContainer'

const SomethingWentWrong = () => {
  document.title = 'Page not found | Pangea'

  return (
    <ErrorPageContainer>
      <Image src="/assets/illustrations/404.svg" alt="Page not found illustration" maxWidth={440} />
      <Text variant="h1" fontSize={64} mt="60px" mb={2} textAlign="center">
        This page is not found.
      </Text>
      <Text textAlign="center" maxWidth={480}>
        You may have mistyped the address or the page may have moved.
      </Text>
      <Button sx={{ marginTop: '70px' }} onClick={() => window.location.replace('/')}>
        Return to Homepage
      </Button>
    </ErrorPageContainer>
  )
}

export default SomethingWentWrong
