// @mui
import { Stack, Box } from '@mui/material'
// config
import { NAV } from '../../config-global'
// utils
import { hideScrollbarX } from '../../utils/cssStyles'
// components
import Logo from '../Logo'
import { NavSectionMini } from '../NavSection'
//
import navConfig from './config-navigation'
import NavToggleButton from './NavToggleButton'
import NavAccount from './NavAccount'

// ----------------------------------------------------------------------

export default function NavMini() {
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <Box height={104} display="flex" alignItems="center">
          <Logo sx={{ mx: 'auto', my: 2 }} variant="basic" />
        </Box>
        <Box mx={1}>
          <NavAccount variant="mini" />
        </Box>

        <NavSectionMini data={navConfig} />
      </Stack>
    </Box>
  )
}
