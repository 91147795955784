import { Box, Stack, useTheme } from '@mui/material'
import { FC } from 'react'
import { Iconify, Button, Text } from '@/components'
import { ButtonProps } from '@/components/Button/Button'

interface OnboardingStepFooterProps {
  forwardButtonProps?: ButtonProps
  secondaryButtonProps?: ButtonProps
  backButtonProps?: ButtonProps
}

const defaultSecondaryButtonProps: ButtonProps = { children: 'Skip' }
const defaultForwardButtonProps: ButtonProps = { children: 'Next' }
const defaultBackButtonProps: ButtonProps = {
  children: (
    <>
      <Iconify icon="ci:chevron-left" />
      <Text>Back</Text>
    </>
  ),
}

const OnboardingStepFooter: FC<OnboardingStepFooterProps> = ({
  forwardButtonProps,
  secondaryButtonProps,
  backButtonProps,
}) => {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="end"
      marginBottom={6}
      maxWidth={theme.breakpoints.values['onboarding-content-wider']}
    >
      <Box
        display="flex"
        gap={4}
        justifyContent="flex-end"
        // flex="1"
      >
        {backButtonProps && (
          <Button
            sx={{ padding: 1.5 }}
            variant="text"
            color="inherit"
            {...defaultBackButtonProps}
            {...backButtonProps}
          />
        )}
        <Box flex="1" alignItems="center" justifyContent="flex-end" display="flex">
          <Stack direction="row" gap={1} sx={{ mt: 1 }}>
            {secondaryButtonProps && (
              <Button
                sx={{ padding: 1.5 }}
                variant="text"
                color="inherit"
                {...defaultSecondaryButtonProps}
                {...secondaryButtonProps}
              />
            )}
            {forwardButtonProps && (
              <Button
                sx={{ width: 222, paddingY: 1.5, paddingX: 3, alignSelf: 'flex-end' }}
                {...defaultForwardButtonProps}
                {...forwardButtonProps}
              />
            )}
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default OnboardingStepFooter
