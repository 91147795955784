import { useTheme } from '@mui/material/styles'
import { Box, BoxProps } from '@mui/material'
import { forwardRef } from 'react'
import { StyledLabel, iconStyle } from './styles'

// ----------------------------------------------------------------------

export type LabelColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'

export type LabelVariant = 'filled' | 'outlined' | 'soft'

export interface LabelProps extends BoxProps {
  startIcon?: React.ReactElement | null
  endIcon?: React.ReactElement | null
  color?: LabelColor
  variant?: LabelVariant
}

// ----------------------------------------------------------------------

const Label = forwardRef<HTMLSpanElement, LabelProps>(
  (
    {
      children,
      color = 'default',
      variant = 'soft',
      startIcon,
      endIcon,
      sx,
      ...props
    },
    ref
  ) => {
    const theme = useTheme()

    return (
      <StyledLabel
        ref={ref}
        component="span"
        ownerState={{ color, variant }}
        sx={{
          ...(startIcon && { pl: 0.75 }),
          ...(endIcon && { pr: 0.75 }),
          ...sx,
        }}
        theme={theme}
        {...props}
      >
        {startIcon && <Box sx={{ mr: 0.75, ...iconStyle }}> {startIcon} </Box>}

        {children}

        {endIcon && <Box sx={{ ml: 0.75, ...iconStyle }}> {endIcon} </Box>}
      </StyledLabel>
    )
  }
)

Label.displayName = 'Label'
export default Label
