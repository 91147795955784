import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { parseToken } from '../session/session';

const DEFAULT_TIMEOUT = 30 * 1000;

export interface IApiClient {
  client: AxiosInstance;
  sessionClient: AxiosInstance;
  getAccessToken: () => string | undefined;
  setAccessToken: (token: string) => void;

  get<TResponse>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>>;

  post<TResponse, TBody>(url: string, body?: TBody, config?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>>;

  patch<TResponse, TBody>(url: string, body?: TBody, config?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>>;

  put<TResponse, TBody>(url: string, body?: TBody, config?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>>;

  del<TResponse>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>>;
}

interface ApiClientParams {
  apiPrefix?: string;
  baseUrl?: string;
  sessionApiBaseUrl?: string;
  onRefreshFail: () => void;
  onRefreshSuccess: (newToken: string) => void;
  refreshTokenUrl?: string;
  logoutUrl?: string;
}

interface QueueItem {
  resolve: (value: unknown) => void;
  reject: (reason?: unknown) => void;
}

/**
 * ApiClient is a fetcher instance to be used inside the project
 * @param apiPrefix
 * @param baseUrl
 * @param refreshTokenUrl
 */
export class ApiClient implements IApiClient {
  private ACCESS_TOKEN: string | undefined;
  public client: AxiosInstance;
  public sessionClient: AxiosInstance;
  private isTokenRefreshing = false;
  private queue: Array<QueueItem> = [];
  private refreshTokenUrl;
  private logoutUrl;
  public onRefreshFail: () => void | undefined;
  public onRefreshSuccess: (accessToken: string) => void | undefined;
  public baseUrl: string;

  constructor(params: ApiClientParams) {
    this.refreshTokenUrl = params.refreshTokenUrl;
    this.logoutUrl = params.logoutUrl;
    this.onRefreshFail = params.onRefreshFail;
    this.onRefreshSuccess = params.onRefreshSuccess;

    this.baseUrl = params.baseUrl || '';
    const apiPrefix = params.apiPrefix === undefined ? '/api' : '';
    const sessionBaseUrl = params.sessionApiBaseUrl || '';

    this.client = axios.create({
      baseURL: `${this.baseUrl}${apiPrefix}`,
      responseType: 'json' as const,
      timeout: DEFAULT_TIMEOUT,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: false,
    });

    this.sessionClient = axios.create({
      baseURL: `${sessionBaseUrl}${apiPrefix}`,
      responseType: 'json' as const,
      timeout: DEFAULT_TIMEOUT,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    void this.initInterceptors();
  }

  private async initInterceptors() {
    await this.initRequestInterceptors();
    await this.initResponseInterceptors();
  }

  private async initRequestInterceptors() {
    this.client.interceptors.request.use(async (config) => {
      if (config.url === this.refreshTokenUrl || config.url === this.logoutUrl) {
        // Do not intercept token calls.
        return config;
      }

      const authHeader = config.headers.Authorization;
      // No header present
      if (!authHeader || !authHeader.toString().startsWith('Bearer ')) {
        return config;
      }

      // Get access token expiry
      const tokenStr = authHeader.toString().split(' ')[1];
      let accessToken: any = '';
      try {
        accessToken = parseToken(tokenStr);
      } catch (err) {
        console.error('Token is not valid!');
        console.log(err);
        this.onRefreshFail();
        return config;
      }

      const now = new Date();
      // Move exp 15s backwards to compensate for expirations midway through the call.
      const expiry = new Date((accessToken.exp - 15) * 1000);

      if (expiry <= now) {
        // Token has expired
        try {
          this.isTokenRefreshing = true;
          // Refresh token and notify dependencies
          const newToken = await this.refreshAccessToken();
          this.setAccessToken(newToken);
          this.onRefreshSuccess(newToken);

          // Update current call's header
          config.headers.set('Authorization', 'Bearer ' + newToken);
        } catch (e) {
          console.error('Error refreshing token: ' + e);
          this.onRefreshFail();
        } finally {
          this.isTokenRefreshing = false;
        }
      }
      return config;
    }, undefined);
  }

  private async initResponseInterceptors() {
    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };
        if (
          !!this.refreshTokenUrl &&
          !originalRequest._retry &&
          error.response.status === 401 &&
          (error.response.headers?.['X-Token-Expired'] === 'true' ||
            error.response.headers?.['x-token-expired'] === 'true') &&
          // Disable refetching when calling this endpoint directly
          originalRequest.url !== this.refreshTokenUrl
        ) {
          if (this.isTokenRefreshing) {
            return (
              new Promise((resolve, reject) => {
                this.queue.push({ resolve, reject });
              })
                // Passing token to queued items
                .then((token) => {
                  originalRequest.headers!['Authorization'] = 'Bearer ' + token;
                  return this.client(originalRequest);
                })
                .catch((err) => {
                  return Promise.reject(err);
                })
            );
          }

          originalRequest._retry = true;
          this.isTokenRefreshing = true;

          return new Promise((resolve, reject) => {
            this.refreshAccessToken()
              .then(async (newToken) => {
                // Set access token on instance and default headers
                this.setAccessToken(newToken);
                // Notify dependents
                this.onRefreshSuccess(newToken);
                // Rewrite header for original request
                originalRequest.headers!['Authorization'] = `Bearer ${newToken}`;
                // Process queue
                void this.processQueue(null, newToken);
                resolve(this.client(originalRequest));
              })
              // If refreshing token failed, throw and clear the queue
              .catch(async (err) => {
                this.onRefreshFail();
                void this.processQueue(err, null);
                reject(err);
              })
              .finally(() => {
                this.isTokenRefreshing = false;
              });
          });
        }

        // Normal flow of interceptor is to reject the error state
        return Promise.reject(error);
      }
    );
  }

  /**
   * Gets new access token
   * @returns promise
   */
  public async refreshAccessToken() {
    const res = await this.sessionPost<{ token: string }, undefined>(this.refreshTokenUrl!, undefined, {
      withCredentials: true,
    });
    const newAccessToken = res.data.token;
    return newAccessToken;
  }

  private async processQueue(error: unknown, token: string | null = null) {
    this.queue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    this.queue = [];
  }

  /**
   * Sets access token on the instance
   * @param accessToken
   */
  public setAccessToken(accessToken: string) {
    this.ACCESS_TOKEN = accessToken;
    this.client.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  public getAccessToken(): string | undefined {
    return this.ACCESS_TOKEN;
  }

  async get<TResponse>(url: string, config?: AxiosRequestConfig) {
    return this.client.get<TResponse>(url, config);
  }

  async post<TResponse, TBody>(url: string, body?: TBody, config?: AxiosRequestConfig) {
    return this.client.post<TResponse>(url, body, config);
  }

  async patch<TResponse, TBody>(url: string, body?: TBody, config?: AxiosRequestConfig) {
    return this.client.patch<TResponse>(url, body, config);
  }

  async put<TResponse, TBody>(url: string, body?: TBody, config?: AxiosRequestConfig) {
    return this.client.put<TResponse>(url, body, config);
  }

  async del<TResponse>(url: string, config?: AxiosRequestConfig) {
    return this.client.delete<TResponse>(url, config);
  }

  public async logout() {
    try {
      await this.sessionPost<undefined, undefined>(this.logoutUrl!, undefined, {
        withCredentials: true,
      });
    } catch (e) {
      console.error('Logout call failed', e);
    }

    // That's a hack :)
    this.onRefreshFail();
  }

  async sessionPost<TResponse, TBody>(url: string, body?: TBody, config?: AxiosRequestConfig) {
    return this.sessionClient.post<TResponse>(url, body, config);
  }
}

export default ApiClient;
