// @mui
import { styled } from '@mui/material/styles'
import { TextField, TextFieldProps } from '@mui/material'

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  width?: number
}

const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'width',
})<Props>(({ width, theme }) => ({
  '& .MuiOutlinedInput-root': {
    width,
    transition: theme.transitions.create(['border-color', 'box-shadow', 'width'], {
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
      boxShadow: theme.customShadows.z20,
      ...(width && {
        [theme.breakpoints.up('sm')]: {
          width: width + 60,
        },
      }),
    },
  },
}))

export default CustomTextField
