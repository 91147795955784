import { Box } from '@mui/material'
import { Dialog, Text, Button } from '@/components'

interface ConfirmNavigationModalProps {
  onCancel: () => void
  onContinue: () => void
}

const ConfirmNavigationModal: React.FC<ConfirmNavigationModalProps> = ({ onCancel, onContinue }) => {
  return (
    <Dialog open onClose={() => {}} maxWidth={'xs'}>
      <Text align="center">Your changes are not saved, are you sure you want to continue?</Text>
      <Box display="flex" mt={4}>
        <Button fullWidth onClick={onCancel} sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button
          fullWidth
          onClick={onContinue}
          sx={{
            bgcolor: (theme) => theme.palette.grey[700],
            '&:hover': {
              bgcolor: (theme) => theme.palette.grey[700],
              boxShadow: 'none',
            },
          }}
        >
          Continue
        </Button>
      </Box>
    </Dialog>
  )
}

export default ConfirmNavigationModal
