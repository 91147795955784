import { useState, useEffect, useRef, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { NavListProps } from '../types'
import { StyledPopover } from './styles'
import NavItem from './NavItem'
import useActiveLink from '@/hooks/useActiveLink'

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps
  depth: number
  hasChild: boolean
}

export default function NavList({ data, depth, hasChild }: NavListRootProps) {
  const navRef = useRef(null)

  const { pathname } = useLocation()

  const { active, isExternalLink } = useActiveLink(data.path)

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    const appBarEl = Array.from(
      document.querySelectorAll('.MuiAppBar-root')
    ) as Array<HTMLElement>

    // Reset styles when hover
    const styles = () => {
      document.body.style.overflow = ''
      document.body.style.padding = ''
      // Apply for Window
      appBarEl.forEach((elem) => {
        elem.style.padding = ''
      })
    }

    if (open) {
      styles()
    } else {
      styles()
    }
  }, [open])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <NavItem
        ref={navRef}
        item={data}
        depth={depth}
        open={open}
        active={active}
        isExternalLink={isExternalLink}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      />

      {hasChild && (
        <StyledPopover
          open={open}
          anchorEl={navRef.current}
          anchorOrigin={
            depth === 1
              ? { vertical: 'bottom', horizontal: 'left' }
              : { vertical: 'center', horizontal: 'right' }
          }
          transformOrigin={
            depth === 1
              ? { vertical: 'top', horizontal: 'left' }
              : { vertical: 'center', horizontal: 'left' }
          }
          PaperProps={{
            onMouseEnter: handleOpen,
            onMouseLeave: handleClose,
          }}
        >
          {data.children.map((list: NavListProps) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={depth + 1}
              hasChild={!!list.children}
            />
          ))}
        </StyledPopover>
      )}
    </>
  )
}
