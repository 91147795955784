import { ShopifyOAuthAuthorizeResponse } from '@pangea/types'
import { AuthenticatedClient } from '.'

const authorize = async (shopUrl: string, projectId: string) => {
  const res = await AuthenticatedClient.get<ShopifyOAuthAuthorizeResponse>(
    `/project/${projectId}/integrations/shopify/authorize?shop=${encodeURIComponent(shopUrl)}`,
    { withCredentials: true }
  )
  return res.data
}

const ShopifyService = {
  authorize,
}

export default ShopifyService
