declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xl: true
    '2xl': true
    'onboarding-content': true
    'onboarding-content-wider': true
  }
}

const customBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1440,
    '2xl': 1536,

    // Onboarding content breakpoints
    'onboarding-content': 760,
    'onboarding-content-wider': 960,
  },
}

export default customBreakpoints
