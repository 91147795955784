import { Country } from '@shared/constants/countries'
import * as yup from 'yup'

export interface IFormInput {
  zipCode: string
  country?: Country
  companyName: string
  cardHolder: string
  billingAddress: string
}

export const BillingDetailsSchema = yup.object().shape({
  zipCode: yup.string().required('Zip Code is required'),
  companyName: yup.string().required('Company Name is required'),
  cardHolder: yup.string().required('Card Holder Name is required'),
  billingAddress: yup.string().required('Billing Address is required'),
  country: yup.object().shape({ code: yup.string().required('Country is required') }),
})
