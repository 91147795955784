import { Box, Stack, CircularProgress, BoxProps } from '@mui/material'
import { Text } from '@/components'

const Loader: React.FC<BoxProps> = (boxProps) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" {...boxProps}>
      <Stack gap={2} display="flex" alignItems="center" justifyContent="center" maxWidth="400px" width="100%">
        <CircularProgress />
        <Text>Loading...</Text>
      </Stack>
    </Box>
  )
}

export default Loader
