import { useState } from 'react'
import { Iconify } from '@/components'

interface BlockchainIconProps {
  blockchain: string | undefined
  size?: number
}

const getIcon = (blockchain?: string) => {
  if (!blockchain) return null

  try {
    const icon = require(`../../../public/assets/icons/brands/ic_brand_${blockchain}.svg`)
    return icon
  } catch (e) {
    return null
  }
}

const BlockchainIcon: React.FC<BlockchainIconProps> = ({ blockchain, size }) => {
  const [icon] = useState(getIcon(blockchain))

  return icon ? (
    <img alt={`${blockchain} icon`} src={icon} width={size} height={size} />
  ) : (
    <Iconify sx={{ width: 32, height: 'auto' }} icon="fluent-mdl2:unknown" />
  )
}

export default BlockchainIcon
