import { IntegrationStatus as Status } from '@pangea/types'
import Label from '../Label'
import { LabelColor } from '../Label/Label'

const statusColor: Record<Status, LabelColor> = {
  [Status.ACTIVE]: 'success',
  [Status.PENDING]: 'warning',
  [Status.INACTIVE]: 'error',
}

export const IntegrationStatus: React.FC<{ status: Status }> = ({ status }) => {
  return (
    <Label color={statusColor[status]} sx={{ textTransform: 'capitalize' }}>
      {status === Status.INACTIVE ? 'Not connected' : status.toLowerCase()}
    </Label>
  )
}

export default IntegrationStatus
