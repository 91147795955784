/**
 * **@SocialAccountType** Enum to specify the type of the social account.
 * @example SocialAccountType.GOOGLE
 */
export var SocialAccountType;
(function (SocialAccountType) {
    SocialAccountType["GOOGLE"] = "google";
    SocialAccountType["FACEBOOK"] = "facebook";
})(SocialAccountType || (SocialAccountType = {}));
export function extractUserData(userData, requiredFields) {
    const userDataPoints = [];
    // Extract data from the userData field of NotaUser
    requiredFields.forEach((field) => {
        const name = field.name;
        let value = undefined;
        if (name in userData) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value = userData[name];
        }
        if (value !== undefined) {
            userDataPoints.push({
                name,
                available: true,
                value,
            });
        }
        else {
            userDataPoints.push({
                name,
                sources: field.sources,
                available: false,
            });
        }
    });
    return userDataPoints;
}
export function parseUserData(user) {
    return user.userData;
}
