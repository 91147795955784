export var AuthProviders;
(function (AuthProviders) {
    AuthProviders["GOOGLE"] = "google";
    AuthProviders["FACEBOOK"] = "facebook";
    AuthProviders["EMAIL_MAGIC_LINK"] = "magiclink";
    AuthProviders["PHONE_SMS"] = "phone_sms";
    AuthProviders["DUMMY"] = "dummy";
    AuthProviders["DUMMY_ONBOARDED"] = "dummy_onboarded";
})(AuthProviders || (AuthProviders = {}));
export const MAIL_AUTH_PROVIDERS = [
    AuthProviders.GOOGLE,
    AuthProviders.FACEBOOK,
    AuthProviders.EMAIL_MAGIC_LINK,
];
export const PHONE_AUTH_PROVIDERS = [
    AuthProviders.PHONE_SMS,
    AuthProviders.DUMMY,
    AuthProviders.DUMMY_ONBOARDED,
];
export var ProfileType;
(function (ProfileType) {
    ProfileType["EMAIL"] = "EMAIL";
    ProfileType["PHONE"] = "PHONE";
})(ProfileType || (ProfileType = {}));
/** A more specific, domain-specific error code (use the ErrorCodes enum) */
export var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["INSUFFICIENT_PERMISSIONS"] = "INSUFFICIENT_PERMISSIONS";
    ErrorCodes["INTERNAL_SERVER_ERROR"] = "INTERNAL_SERVER_ERROR";
    ErrorCodes["INVALID_PHONE_NUMBER"] = "INVALID_PHONE_NUMBER";
    ErrorCodes["INVALID_CONTENT_TYPE"] = "INVALID_CONTENT_TYPE";
    ErrorCodes["LOGIN_EXPIRED"] = "LOGIN_EXPIRED";
    ErrorCodes["MISSING_USER_INFO"] = "MISSING_USER_INFO";
    ErrorCodes["NOT_FOUND"] = "NOT_FOUND";
    ErrorCodes["RECORD_NOT_FOUND"] = "RECORD_NOT_FOUND";
    ErrorCodes["TOO_MANY_REQUESTS"] = "TOO_MANY_REQUESTS";
    ErrorCodes["UNAUTHORIZED"] = "UNAUTHORIZED";
    ErrorCodes["VALIDATION_ERROR"] = "VALIDATION_ERROR";
    ErrorCodes["WALLET_ALREADY_CONNECTED"] = "WALLET_ALREADY_CONNECTED";
    ErrorCodes["WALLET_VERIFICATION_ERROR"] = "WALLET_VERIFICATION_ERROR";
    ErrorCodes["UNSUPPORTED_CHAIN"] = "UNSUPPORTED_CHAIN";
    ErrorCodes["OAUTH_INVALID_STATE"] = "OAUTH_INVALID_STATE";
    ErrorCodes["INVALID_WEBSITE_URL"] = "INVALID_WEBSITE_URL";
    ErrorCodes["INVALID_EVM_ADDRESS"] = "INVALID_EVM_ADDRESS";
    ErrorCodes["MISSING_REWARD_DEFINITION_DATA"] = "MISSING_REWARD_DEFINITION_DATA";
    ErrorCodes["CONFLICT_LIVE_CAMPAIGNS"] = "CONFLICT_LIVE_CAMPAIGNS";
    ErrorCodes["NO_MORE_CAMPAIGN_REWARDS"] = "NO_MORE_CAMPAIGN_REWARDS";
    ErrorCodes["INVALID_CAMPAIGN_STATUS"] = "INVALID_CAMPAIGN_STATUS";
    ErrorCodes["INVALID_RULE_TYPE"] = "INVALID_RULE_TYPE";
    ErrorCodes["PROJECT_OWNER_CONFLICT"] = "PROJECT_OWNER_CONFLICT";
    ErrorCodes["PROJECT_OWNER_REMOVAL"] = "PROJECT_OWNER_REMOVAL";
    ErrorCodes["CUSTOMER_ALREADY_ADDED"] = "CUSTOMER_ALREADY_ADDED";
    ErrorCodes["DISABLED"] = "DISABLED";
    ErrorCodes["CONNECT_REQUEST_EXPIRED"] = "CONNECT_REQUEST_EXPIRED";
    ErrorCodes["INVALID_CODE"] = "INVALID_CODE";
    ErrorCodes["TWILIO_CODE_VERIFICATION_ERROR"] = "TWILIO_CODE_VERIFICATION_ERROR";
    ErrorCodes["MEMBER_LIST_PROCESSING_ERROR"] = "MEMBER_LIST_PROCESSING_ERROR";
    ErrorCodes["INVALID_CAMPAIGN_INTEGRATION_STATE"] = "INVALID_CAMPAIGN_INTEGRATION_STATE";
})(ErrorCodes || (ErrorCodes = {}));
