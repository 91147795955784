import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  MenuItemProps,
  Select as MUISelect,
  SelectProps as MUISelectProps,
} from '@mui/material'

type ValueType = string | number | readonly string[] | undefined

export interface SelectOption {
  label: React.ReactNode
  value: ValueType
}

interface SelectProps extends MUISelectProps {
  disabled?: boolean
  error?: boolean
  helperText?: string
  label: string
  options: (SelectOption & MenuItemProps)[]
  required?: boolean
  startAdornment?: React.ReactNode
  containerProps?: FormControlProps
}

const Select = ({
  disabled,
  error,
  helperText,
  label,
  options,
  required,
  startAdornment,
  containerProps,
  ...selectProps
}: SelectProps) => {
  return (
    <FormControl disabled={disabled} error={error} required={required} {...containerProps}>
      <InputLabel>{label}</InputLabel>
      <MUISelect
        label={label}
        startAdornment={<InputAdornment position="start">{startAdornment}</InputAdornment>}
        {...selectProps}
      >
        {options.map(({ value, label, ...optionProps }: SelectOption) => (
          <MenuItem key={String(value)} value={value} {...optionProps}>
            {value ? label : <em>{label}</em>}
          </MenuItem>
        ))}
      </MUISelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Select
