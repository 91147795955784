import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { IntegrationType, NftCampaignDetail } from '@pangea/types'
import { Text, TextField } from '@/components'
import { FormValues } from '@/modules/NFTCampaignWizard'
import BigCommerceRewardDefinition from '@/components/NFTCampaign/Wizard/RewardDefinition/BigCommerce'
import Container from '../Container'
import {
  InternalSecretRewardDefinition,
  MindbodyRewardDefinition,
  ShopifyRewardDefinition,
  SquareRewardDefinition,
  StripeRewardDefinition,
  LightspeedRewardDefinition,
  WixRewardDefinition,
} from './RewardDefinition'
// import CampaignImageUpload from './CampaignImageUpload'

interface RewardTabProps {
  campaign: NftCampaignDetail
}

const RewardTab: React.FC<RewardTabProps> = ({ campaign }) => {
  const { control } = useFormContext<FormValues>()
  // const [files, setFiles] = useState<string[]>([])

  return (
    <>
      <Container>
        <Text variant="h5">Campaign Design</Text>
        <Controller
          name="campaignTitle"
          control={control}
          defaultValue={campaign.rewardDefinition.rewardTemplateData.campaignDesign.title}
          render={({ field }) => <TextField label="Title" sx={{ my: 4 }} {...field} />}
        />
        <Controller
          name="campaignDescription"
          defaultValue={campaign.rewardDefinition.rewardTemplateData.campaignDesign.description}
          control={control}
          render={({ field }) => <TextField label="Description" {...field} />}
        />
        {/* <CampaignImageUpload files={files} setFiles={setFiles} /> */}
      </Container>

      <Container mt={3}>
        <Text variant="h5">Campaign Reward</Text>
        <Controller
          name="rewardTitle"
          control={control}
          defaultValue={campaign.rewardDefinition.rewardTemplateData.rewardDesign.title}
          render={({ field }) => <TextField label="Campaign Reward Title" sx={{ my: 4 }} {...field} />}
        />
        <Controller
          name="rewardDescription"
          control={control}
          defaultValue={campaign.rewardDefinition.rewardTemplateData.rewardDesign.description}
          render={({ field }) => <TextField label="Campaign Reward Description" {...field} />}
        />
      </Container>
      {campaign.integrationType === IntegrationType.INTERNAL && <InternalSecretRewardDefinition campaign={campaign} />}
      {campaign.integrationType === IntegrationType.MINDBODY && <MindbodyRewardDefinition />}
      {campaign.integrationType === IntegrationType.SQUARE && <SquareRewardDefinition campaign={campaign} />}
      {campaign.integrationType === IntegrationType.SHOPIFY && <ShopifyRewardDefinition campaign={campaign} />}
      {campaign.integrationType === IntegrationType.STRIPE && <StripeRewardDefinition campaign={campaign} />}
      {campaign.integrationType === IntegrationType.LIGHTSPEED && <LightspeedRewardDefinition campaign={campaign} />}
      {campaign.integrationType === IntegrationType.WIX && <WixRewardDefinition campaign={campaign} />}
      {campaign.integrationType === IntegrationType.BIGCOMMERCE && <BigCommerceRewardDefinition campaign={campaign} />}
    </>
  )
}

export default RewardTab
