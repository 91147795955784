import { FC } from 'react'
import { Box, BoxProps, TextFieldProps } from '@mui/material'
import { Text, TextField } from '@/components'

type TextFieldWithLabelProps = TextFieldProps & {
  label: string
  containerProps?: BoxProps
}

const TextFieldWithLabel: FC<TextFieldWithLabelProps> = ({ label, containerProps, ...inputProps }) => {
  return (
    <Box {...containerProps}>
      <Text variant="h6" color="primary.light">
        {label}
      </Text>
      <Box mt={1}>
        <TextField {...inputProps} />
      </Box>
    </Box>
  )
}

export default TextFieldWithLabel
