import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  TextField,
  ChipTypeMap,
  AutocompleteRenderInputParams,
} from '@mui/material'

interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
> extends Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>, 'renderInput'> {
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
}

const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>({
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>) => {
  return (
    <MuiAutocomplete
      {...{
        ...props,
        renderInput: props.renderInput ? props.renderInput : (props) => <TextField {...props} />,
      }}
    />
  )
}

export default Autocomplete
