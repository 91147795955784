import { createContext, FC, useState } from 'react'
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { ProjectOnboardingStatus } from '@pangea/types'
import { useUser } from '@/hooks'
import { ProjectService } from '@/api'
import { LoadingScreen } from '@/components'
import { URLS } from '@/utils/routes'
import { Project } from '@/types/project'
import { getStoredProjectId, storeProjectId } from '@/utils/project'

export interface IProjectContext {
  project?: Project
  projects: Project[]
  refetch: () => Promise<any>
  setSelectedProject: (project?: Project) => void
}

export const ProjectContext = createContext<IProjectContext | undefined>(undefined)

const ProjectContextProvider: FC = () => {
  const [params, setPrams] = useSearchParams()
  const { pathname } = useLocation()
  const { projects } = useUser()
  const [selectedProject, setSelectedProject] = useState<Project>()

  const selectProject = (project?: Project) => {
    setSelectedProject(project)
    if (project?.onboardingStatus === ProjectOnboardingStatus.COMPLETE) {
      storeProjectId(project.id)
    }
  }

  const getBrokenProjects = (projects: Project[]) => {
    const brokenProjects = projects.filter(
      (project) =>
        project.onboardingStatus === ProjectOnboardingStatus.SELECT_PLAN &&
        'dataEnrichmentStatus' in project &&
        (project.dataEnrichmentStatus === 'FAILED' || project.dataEnrichmentStatus === 'NOT_STARTED')
    )

    return brokenProjects
  }

  const { data, isLoading, refetch } = useQuery(
    ['projects', projects],
    () => Promise.all(projects.map((p) => ProjectService.getProject(p.projectId))),
    {
      enabled: !!projects.length,
      onSuccess: async (data) => {
        if (data.length) {
          const brokenProjects = getBrokenProjects(data)

          if (brokenProjects.length) {
            await Promise.all(
              brokenProjects.map((project) =>
                ProjectService.updateProject({
                  id: project.id,
                  onboardingStatus: ProjectOnboardingStatus.COMPLETE,
                })
              )
            )
            refetch()
            return
          }

          const sharedProject = params.get('projectId')
          if (sharedProject) {
            storeProjectId(sharedProject)
            setPrams()
          }

          const storedProject = data.find((p) => p.id === getStoredProjectId())
          // If project is stored, select it
          if (storedProject) {
            selectProject(storedProject)
            return
          }

          // Otherwise select first onboarded project
          const onboardedProject = data.find((p) => p.onboardingStatus === ProjectOnboardingStatus.COMPLETE)
          onboardedProject && selectProject(onboardedProject)
        }
      },
    }
  )

  if (isLoading) {
    return <LoadingScreen />
  }

  if (
    (!selectedProject || selectedProject.onboardingStatus !== ProjectOnboardingStatus.COMPLETE) &&
    pathname !== URLS.ONBOARDING
  )
    return <Navigate to={URLS.ONBOARDING} />

  return (
    <ProjectContext.Provider
      value={{
        project: selectedProject,
        projects: data || [],
        refetch,
        setSelectedProject: selectProject,
      }}
    >
      <Outlet />
    </ProjectContext.Provider>
  )
}

export default ProjectContextProvider
