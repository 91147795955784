import Box, { BoxProps } from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

export default function Progress({ ...other }: BoxProps) {
  return (
    <Box sx={{ width: '100%' }} {...other}>
      <LinearProgress />
    </Box>
  )
}
