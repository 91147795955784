import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { IntegrationType, NftCampaignDetail } from '@pangea/types'
import { useFormContext } from 'react-hook-form'
import CodeIcon from '@mui/icons-material/Code'
import { useState } from 'react'
import { Button, Iconify, Text, IntegrationIcon } from '@/components'
import CampaignStatusLabel from '../CampaignStatus'
import EditCampaignNameInput from './EditCampaignNameInput'
import CampaignPublishedModal from './CampaignPublishedModal'

interface NFTCampaignWizardHeaderProps {
  campaign: NftCampaignDetail
  saveChanges: () => void
  publishCampaign: () => void
  deactivateCampaign: () => void
}

const NFTCampaignWizardHeader: React.FC<NFTCampaignWizardHeaderProps> = ({
  campaign,
  saveChanges,
  publishCampaign,
  deactivateCampaign,
}) => {
  const navigate = useNavigate()
  const isPublished = campaign.status === 'publish'
  const {
    formState: { isDirty },
  } = useFormContext()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      minHeight={116}
      borderBottom="1px solid rgba(145, 158, 171, 0.32)"
      px={6}
    >
      <Box display="flex" alignItems="center">
        <Iconify icon="ic:round-chevron-left" sx={{ width: 28, height: 28 }} onClick={() => navigate(-1)} />

        <Box display="flex" flexDirection="column">
          <EditCampaignNameInput />
          <Box display="flex" alignItems="center" ml={1.75}>
            <IntegrationIcon integration={campaign.integrationType as IntegrationType} height={14} />
            <Box display="flex" mx={2}>
              <Box
                borderRadius={0.5}
                sx={{ background: (theme) => theme.gradients.main }}
                width={20}
                height={20}
                display="flex"
                justifyContent="center"
                alignItems="center"
                mr={1}
              >
                <Iconify icon="ant-design:trophy-filled" width={12} height={12} />
              </Box>
              <Text variant="caption" textTransform="capitalize">
                {campaign.rewardTemplateCode.toLowerCase().replaceAll('_', ' ')}
              </Text>
            </Box>
            {campaign.publishStatus && <CampaignStatusLabel status={campaign.publishStatus} />}
          </Box>
        </Box>
        <Button sx={{ ml: 2 }} startIcon={<CodeIcon />} onClick={() => setIsOpen(true)}>
          Embed Campaign
        </Button>
      </Box>
      <Box>
        <Button
          sx={{
            mr: 2,
            bgcolor: (theme) => theme.palette.success.dark,
            borderRadius: 58,
            '&:hover': {
              background: 'rgba(145, 158, 171, 0.24)',
              boxShadow: (theme) => (isDirty ? theme.customShadows.primary : 'none'),
            },
          }}
          onClick={saveChanges}
          disabled={!isDirty}
        >
          Save Changes
        </Button>
        <Button
          sx={{
            background: (theme) => theme.gradients.main,
            borderRadius: 58,
          }}
          onClick={isPublished ? deactivateCampaign : publishCampaign}
        >
          {isPublished ? 'Deactivate' : 'Publish'}
        </Button>
      </Box>
      {isOpen && <CampaignPublishedModal campaign={campaign} onClose={() => setIsOpen(false)} />}
    </Box>
  )
}

export default NFTCampaignWizardHeader
