import { PaginationInput } from '@pangea/types';
import { useState } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';

export interface UsePaginatedQueryProps<T> extends Omit<UseQueryOptions<T>, 'queryFn'> {
  queryFn: (pagination: PaginationInput) => Promise<T>;
  initialPaginationConfig?: Partial<PaginationInput>;
}

const usePaginatedQuery = <T>(props: UsePaginatedQueryProps<T>) => {
  const { queryKey, queryFn, initialPaginationConfig, ...queryOptions } = props;
  const { refetchInterval, refetchOnWindowFocus, refetchOnReconnect, refetchOnMount, useErrorBoundary, ...test } =
    queryOptions;
  const [page, setPage] = useState(initialPaginationConfig?.page || 1);
  const [pageSize, setPageSize] = useState(initialPaginationConfig?.pageSize || 10);
  // prettier-ignore
  const query = useQuery([queryKey, page, pageSize], () => queryFn({ page, pageSize }), {
    ...test,
    keepPreviousData: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    refetchInterval, refetchOnWindowFocus, refetchOnReconnect, refetchOnMount, useErrorBoundary,
    // all of this point to the same type definition but TS is throwing an error for some reason 
  });

  return { query, pagination: { page, pageSize, setPage, setPageSize } };
};

export default usePaginatedQuery;
