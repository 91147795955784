import { Box, Grid, Stack } from '@mui/material'
import { Label, Text } from '@/components'
// import { images } from '@/assets/pricing'
import { OnboardingStepFooter } from '../onboarding'
import { EnrichedPlan } from './utils'

interface Props {
  selectedPlan: EnrichedPlan
  onNextStep: VoidFunction
  onBackStep: VoidFunction
}

const PaymentMethodAdded = ({ selectedPlan, onNextStep, onBackStep }: Props) => {
  return (
    <Stack gap={2} height="100%">
      <Box>
        <Stack gap={2}>
          <Text variant="h5">Plan details</Text>
          <Grid item xs={12} md={4} sx={{ my: 2 }}>
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between">
                <Text variant="body2" sx={{ color: 'text.secondary' }}>
                  Subscription
                </Text>
                <Label color="error">{selectedPlan.subscription}</Label>
              </Stack>
              {/* <Stack direction="row" justifyContent="space-between">
                <Text variant="body2" sx={{ color: 'text.secondary' }}>
                  Title
                </Text>
                <Text>{selectedPlan.title}</Text>
              </Stack> */}
              {selectedPlan.lists.map((t) => (
                <Stack key={t.label} direction="row" justifyContent="space-between">
                  <Text variant="body2" sx={{ color: 'text.secondary' }}>
                    {t.label}
                  </Text>
                  <Text>{t.text}</Text>
                </Stack>
              ))}
            </Stack>
          </Grid>

          {/* <Box width={200}>
            <Image src={images.success} />
          </Box> */}
          <Text variant="h6">Finalize your workspace creation by clicking "Next".</Text>
        </Stack>
      </Box>
      <OnboardingStepFooter forwardButtonProps={{ onClick: onNextStep }} backButtonProps={{ onClick: onBackStep }} />
    </Stack>
  )
}

export default PaymentMethodAdded
