import { PangeaPlanDTO, PangeaPlanType, PangeaPaidPlanDTO } from '@pangea/types'
import { formatCurrencyAmount, humanizeNumber } from '@/utils/formats'

export enum SubscriptionEnum {
  BASIC = 'BASIC',
  STARTER = 'STARTER',
  BUSINESS = 'BUSINESS',
}

export const getAdditionalCosts = (plans: PangeaPlanDTO[]): { apiCost: number; claimCost: number } | null => {
  for (const plan of plans) {
    if (plan.claimsPrice && plan.licensePrice) {
      const apiCost = plan.apiPrice ? plan.apiPrice.tier.additionalCostInUSDPerUnit : 0
      const claimCost = plan.claimsPrice.tier.additionalCostInUSDPerUnit
      return { apiCost, claimCost }
    }
  }
  return null // Return null if no plan with both claimPrice and licensePrice is found
}

export const isFreePlan = (type: PangeaPlanType): boolean => type === PangeaPlanType.FREE

const standardPlanTypes = new Set([PangeaPlanType.STANDARD_MONTHLY, PangeaPlanType.STANDARD_ANNUAL])
const businessPlanTypes = new Set([PangeaPlanType.BUSINESS_MONTHLY, PangeaPlanType.BUSINESS_ANNUAL])

export const isStandardPlan = ({ type }: PangeaPlanDTO): boolean => standardPlanTypes.has(type as PangeaPlanType)

export const isBusinessPlan = ({ type }: PangeaPlanDTO): boolean => businessPlanTypes.has(type as PangeaPlanType)

export function isMonthlyBilling(type: PangeaPlanType | string): boolean {
  return [PangeaPlanType.STANDARD_MONTHLY, PangeaPlanType.BUSINESS_MONTHLY].includes(type as PangeaPlanType)
}

export function isAnnualBilling(type: PangeaPlanType | string): boolean {
  return [PangeaPlanType.STANDARD_ANNUAL, PangeaPlanType.BUSINESS_ANNUAL].includes(type as PangeaPlanType)
}

export const getFreePlan = (plans: PangeaPlanDTO[]): PangeaPlanDTO | undefined => plans.find((p) => isFreePlan(p.type))

export const getStandardPlan = (plans: PangeaPlanDTO[], isMonthly: boolean): PangeaPlanDTO | undefined =>
  plans.filter(isStandardPlan).find(({ type }) => (isMonthly ? isMonthlyBilling(type) : isAnnualBilling(type)))

export const getPremiumPlan = (plans: PangeaPlanDTO[], isMonthly: boolean): PangeaPlanDTO | undefined =>
  plans.filter(isBusinessPlan).find(({ type }) => (isMonthly ? isMonthlyBilling(type) : isAnnualBilling(type)))

export type EnrichedPlan = PangeaPlanDTO & {
  subscription: SubscriptionEnum
  title: string
  labelAction: string
  lists: List[]
  pricing: Pricing
}

export type Pricing = {
  monthly: number | null
  yearly: number | null
}

type PlanPricing = {
  [key in PangeaPlanType]: Pricing
}

export const getPricing = (plans: PangeaPlanDTO[]): PlanPricing => {
  let planPricing: PlanPricing = {
    FREE: { monthly: null, yearly: null },
    STANDARD_MONTHLY: { monthly: null, yearly: null },
    STANDARD_ANNUAL: { monthly: null, yearly: null },
    BUSINESS_MONTHLY: { monthly: null, yearly: null },
    BUSINESS_ANNUAL: { monthly: null, yearly: null },
    ENTERPRISE: { monthly: null, yearly: null },
  }

  for (let plan of plans) {
    if ((plan as PangeaPaidPlanDTO).licensePrice) {
      const paidPlan = plan as PangeaPaidPlanDTO
      const recurring = paidPlan.licensePrice.recurring
      const price = paidPlan.licensePrice.pricePerIntervalInUSD

      if (recurring.interval === 'month') {
        planPricing[plan.type].monthly = price
        planPricing[plan.type].yearly = price * 12
      } else if (recurring.interval === 'year') {
        planPricing[plan.type].monthly = price / 12
        planPricing[plan.type].yearly = price
      }
    }
  }

  return planPricing
}

export const enrichData =
  (pricing: PlanPricing) =>
  (plan: PangeaPlanDTO): EnrichedPlan => {
    return { ...plan, ..._getSubscriptionMeta(plan, pricing) }
  }

const formatClaims = (claims: number) => `${claims} Claims`
const RequestMultiplier = 1000
const formatRequests = (req: number) => `${humanizeNumber(req * RequestMultiplier)} API requests`
const formatRequestRate = (rr: number) => `${rr} requests/min`
type List = { text: string; isAvailable: boolean; label: string }

export function generateLists(p: PangeaPlanDTO | PangeaPaidPlanDTO, options: { requestRate?: number } = {}): List[] {
  let claims = formatClaims(5)
  let requests = formatRequests(5)

  if (
    'apiPrice' in p &&
    'claimsPrice' in p &&
    'licensePrice' in p &&
    p?.apiPrice?.pricingType === 'tiered' &&
    p?.claimsPrice?.pricingType === 'tiered'
  ) {
    const { apiPrice, claimsPrice } = p as PangeaPaidPlanDTO
    if (p && claimsPrice && apiPrice) {
      claims = formatClaims(claimsPrice.tier.includedAmount)
      requests = formatRequests(apiPrice.tier.includedAmount)
    }
  }

  const result: List[] = [
    { text: claims, isAvailable: true, label: 'Claims' },
    { text: requests, isAvailable: true, label: 'API Requests' },
  ]

  const { requestRate } = options
  if (requestRate) {
    result.push({ text: formatRequestRate(requestRate), isAvailable: true, label: 'Request Rate' })
  }

  return result
}

const getStarterSavings = (pricing: PlanPricing) => {
  const annualPricing = pricing[PangeaPlanType.STANDARD_ANNUAL].yearly
  const monthlyPricing = pricing[PangeaPlanType.STANDARD_MONTHLY].yearly
  if (!monthlyPricing || !annualPricing) {
    return 0
  }
  return monthlyPricing - annualPricing
}

const getBusinessSavings = (pricing: PlanPricing) => {
  const annualPricing = pricing[PangeaPlanType.BUSINESS_ANNUAL].yearly
  const monthlyPricing = pricing[PangeaPlanType.BUSINESS_MONTHLY].yearly
  if (!monthlyPricing || !annualPricing) {
    return 0
  }
  return monthlyPricing - annualPricing
}

const AnnualPaymentTemplate = (saving: number) => `Saving ${formatCurrencyAmount(saving)}`
const MonthlyPaymentTemplate = (saving: number) => `Switch to annual to save ${formatCurrencyAmount(saving)}`

const _getSubscriptionMeta = (plan: PangeaPlanDTO, pricing: PlanPricing) => {
  if (isStandardPlan(plan)) {
    return {
      subscription: SubscriptionEnum.STARTER,
      title: plan.name,
      labelAction: 'Choose Starter',
      lists: generateLists(plan, {
        requestRate: 120,
      }),
      pricing: pricing[plan.type],
      description: isAnnualBilling(plan.type)
        ? AnnualPaymentTemplate(getStarterSavings(pricing))
        : MonthlyPaymentTemplate(getStarterSavings(pricing)),
    }
  }
  if (isBusinessPlan(plan)) {
    return {
      subscription: SubscriptionEnum.BUSINESS,
      title: plan.name,
      labelAction: 'Choose Business',
      pricing: pricing[plan.type],
      lists: generateLists(plan, {
        requestRate: 500,
      }),
      description: isAnnualBilling(plan.type)
        ? AnnualPaymentTemplate(getBusinessSavings(pricing))
        : MonthlyPaymentTemplate(getBusinessSavings(pricing)),
    }
  }

  return {
    subscription: SubscriptionEnum.BASIC,
    title: plan.name,
    labelAction: 'Start for free',
    pricing: pricing[plan.type],
    lists: generateLists(plan, {
      requestRate: 30,
    }),
    description: 'Forever',
  }
}

export const links = {
  terms: 'https://www.pangealabs.xyz',
  pricing: 'https://www.pangealabs.xyz/pricing',
}
