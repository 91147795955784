import { useEffect, useState } from 'react'

/**
 * A custom hook that returns a debounced value, which only updates after a specified delay.
 * @param value The value to debounce.
 * @param delay The debounce delay in milliseconds, default is 500.
 */

function useDebounce<T>(value: T, delay: number = 500): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  if (delay < 0) {
    throw new Error('Delay must be a positive number.')
  }

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
