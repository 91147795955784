import jwtDecode from 'jwt-decode';

export type Session = any;

const SESSION_KEY = 'authToken';

export { jwtDecode };

export function parseToken(token: string): Session {
  return {
    ...jwtDecode(token),
    token,
  };
}

export function getSession(): Session | undefined {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const sessionString = localStorage.getItem(SESSION_KEY);
  if (sessionString) {
    try {
      return parseToken(sessionString);
    } catch (e) {
      console.error('Error decoding token: ' + e);
      return undefined;
    }
  } else {
    return undefined;
  }
}

export function setSessionToken(token: string) {
  if (typeof window === 'undefined') {
    return;
  }
  localStorage.setItem(SESSION_KEY, token);
}

export function getSessionToken(): string | null {
  if (typeof window === 'undefined') {
    return null;
  }
  return localStorage.getItem(SESSION_KEY);
}

export async function logout() {
  if (typeof window === 'undefined') {
    return;
  }
  localStorage.removeItem(SESSION_KEY);
}
