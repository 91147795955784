import { Box, IconButton, BoxProps } from '@mui/material'
import { useState } from 'react'
import { Text, Iconify } from '@/components'

interface ILabelProps extends BoxProps {
  title: string
  children: React.ReactNode
  collapsible?: boolean
  collapsedHeight?: number
}

const Label: React.FC<ILabelProps> = ({ title, children, collapsible, collapsedHeight = 50, ...boxProps }) => {
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen((prev) => !prev)

  return (
    <Box {...boxProps}>
      <Text variant="caption" color="#919EAB">
        {title}
      </Text>
      {collapsible && !open ? <Box sx={{ height: collapsedHeight, overflow: 'hidden' }}>{children}</Box> : children}
      {collapsible && (
        <Box onClick={toggle}>
          <Text variant="caption" color="#919EAB" display="flex" alignItems="center">
            Show {open ? 'less' : 'more'}
            <IconButton sx={{ ml: 0.25 }} size="small">
              {open ? <Iconify icon="tabler:chevron-up" /> : <Iconify icon="tabler:chevron-down" />}
            </IconButton>
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default Label
