import { useState } from 'react'
import { ButtonProps } from '@mui/material'
import { Button, Iconify } from '@/components'
import ImportCommunityModal from '../NFTCampaign/Wizard/ImportCommunityModal'

interface ImportCommunityButtonProps extends ButtonProps {}

const ImportCommunityButton: React.FC<ImportCommunityButtonProps> = (buttonProps) => {
  const [isImportCommunityModalOpen, setIsImportCommunityModalOpen] = useState(false)

  return (
    <>
      <Button
        size="small"
        startIcon={<Iconify icon="akar-icons:search" width={13} height={13} />}
        onClick={() => setIsImportCommunityModalOpen(true)}
        {...buttonProps}
        sx={{
          ...buttonProps.sx,
          bgcolor: 'rgba(145, 158, 171, 0.08)',
          '&:hover': {
            bgcolor: 'rgba(145, 158, 171, 0.24)',
            boxShadow: 'none',
          },
        }}
      >
        {buttonProps.children || 'Search for your community'}
      </Button>
      {isImportCommunityModalOpen && <ImportCommunityModal onClose={() => setIsImportCommunityModalOpen(false)} />}
    </>
  )
}

export default ImportCommunityButton
