/**
 * Indexing models; used in the enrichment process.
 */
export var IndexedContractModel;
(function (IndexedContractModel) {
    IndexedContractModel["NFT_COMMUNITY_FULL"] = "NFT_COMMUNITY_FULL";
    IndexedContractModel["UNKNOWN"] = "UNKNOWN";
    IndexedContractModel["ERC20"] = "ERC20";
    IndexedContractModel["NFT_COMMUNITY_FILTERED"] = "FILTERED";
})(IndexedContractModel || (IndexedContractModel = {}));
/**
 * Smart Contract types, for now only EVM chains are supported
 */
export var ContractType;
(function (ContractType) {
    ContractType["UNKNOWN"] = "UNKNOWN";
    ContractType["ERC20"] = "ERC-20";
    ContractType["ERC721"] = "ERC-721";
    ContractType["ERC1155"] = "ERC-1155";
})(ContractType || (ContractType = {}));
export function getContractType(input) {
    if (input === undefined || input === null) {
        return ContractType.UNKNOWN;
    }
    const normalizedInput = input.toUpperCase().replace('-', '');
    switch (normalizedInput) {
        case 'ERC20':
            return ContractType.ERC20;
        case 'ERC721':
            return ContractType.ERC721;
        case 'ERC1155':
            return ContractType.ERC1155;
        default:
            return ContractType.UNKNOWN;
    }
}
/**
 * **@MintStatus** Represents the status of the minting process for an **@UserPangeaToken**.
 * @example {
 *   "status": "MINTED"
 * }
 */
export var MintStatus;
(function (MintStatus) {
    MintStatus["INIT"] = "INIT";
    MintStatus["MINTED"] = "MINTED";
    MintStatus["ERROR"] = "ERROR";
})(MintStatus || (MintStatus = {}));
export function isUnknownSmartContract(contract) {
    return contract.contractModel === IndexedContractModel.UNKNOWN;
}
export function isERC20SmartContract(contract) {
    return contract.contractModel === IndexedContractModel.ERC20;
}
export function isNFTSmartContract(contract) {
    return contract.contractModel === IndexedContractModel.NFT_COMMUNITY_FILTERED;
}
export function isNFTCommunityFull(contract) {
    return contract.contractModel === IndexedContractModel.NFT_COMMUNITY_FULL;
}
/**
 * **@SupportedBlockchains** Enumeration of supported blockchains.
 * @example SupportedBlockchains.ETHEREUM
 */
export var SupportedBlockchains;
(function (SupportedBlockchains) {
    SupportedBlockchains["ETHEREUM"] = "ethereum";
    SupportedBlockchains["POLYGON"] = "polygon";
    SupportedBlockchains["BINANCE"] = "binance";
    SupportedBlockchains["AVALANCHE"] = "avalanche";
    SupportedBlockchains["ARBITRUM"] = "arbitrum";
    SupportedBlockchains["FLOW"] = "flow";
})(SupportedBlockchains || (SupportedBlockchains = {}));
/**
 * **@ChainFamily** Enumeration of supported blockchains families.
 * @example ChainFamily.EVM
 */
export var ChainFamily;
(function (ChainFamily) {
    ChainFamily["EMV"] = "EVM";
    ChainFamily["FLOW"] = "FLOW";
})(ChainFamily || (ChainFamily = {}));
/**
 * Categories enum. We already keep this in the database, but will
 * likely move towards the enum model for easier interop.
 */
export var NftCommunityCategory;
(function (NftCommunityCategory) {
    NftCommunityCategory["ART"] = "Art";
    NftCommunityCategory["MUSIC"] = "Music";
    NftCommunityCategory["SPORTS"] = "Sports";
    NftCommunityCategory["GAMING"] = "Gaming";
    NftCommunityCategory["FASHION"] = "Fashion";
    NftCommunityCategory["FILM_TV"] = "Film & TV";
    NftCommunityCategory["VIRTUAL_REAL_ESTATE"] = "Virtual Real Estate";
    NftCommunityCategory["COLLECTIBLES"] = "Collectibles";
    NftCommunityCategory["EVENT_TICKETS"] = "Event Tickets";
    NftCommunityCategory["EDUCATION"] = "Education";
})(NftCommunityCategory || (NftCommunityCategory = {}));
export function createUnknownSmartContract(item) {
    return {
        error: 'Unknown contract',
        lastRefreshed: new Date(),
        blacklist: false,
        contractModel: IndexedContractModel.UNKNOWN,
        contractType: ContractType.UNKNOWN,
        address: item,
        blockchain: SupportedBlockchains.ETHEREUM,
    };
}
