import { useEffect } from 'react'

export interface IntegrationCallbackMessage {
  type: 'INTEGRATION_CALLBACK'
  success: boolean
  error?: string
}

const IntegrationCallbackHandler = () => {
  const isSuccess = window.location.pathname.endsWith('/success')

  let msg: IntegrationCallbackMessage
  if (isSuccess) {
    msg = {
      type: 'INTEGRATION_CALLBACK',
      success: true,
    }
  } else {
    const urlParams = new URLSearchParams(window.location.search)
    const errorMessage = urlParams.get('message')

    msg = {
      type: 'INTEGRATION_CALLBACK',
      success: false,
      error: errorMessage || undefined,
    }
  }

  useEffect(() => {
    window.parent.postMessage(msg)
    window.opener.postMessage(msg)
    window.close()
  }, [msg])

  return null
}

export default IntegrationCallbackHandler
