import { ReactNode, useMemo } from 'react'
// @mui
import { CssBaseline } from '@mui/material'
import {
  createTheme,
  ThemeOptions,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles'
// components
import { useSettingsContext } from '../components/settings'
//
import palette from './palette'
import typography from './typography'
import shadows from './shadows'
import customBreakpoints from './customBreakpoints'
import customShadows from './customShadows'
import componentsOverride from './overrides'
import GlobalStyles from './globalStyles'
import customGradients from './customGradient'

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode
}

export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettingsContext()

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette(themeMode),
      breakpoints: customBreakpoints,
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows(themeMode),
      customShadows: customShadows(themeMode),
      gradients: customGradients,
    }),
    [themeDirection, themeMode]
  )

  const theme = createTheme(themeOptions)

  theme.components = componentsOverride(theme)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
