import { SocialHandles } from '@pangea/types'

interface SocialIconsProps {
  socialHandle: keyof SocialHandles
}

const SocialIcon: React.FC<SocialIconsProps> = ({ socialHandle }) => {
  return <img alt={`${socialHandle} logo`} src={`/assets/icons/brands/ic_brand_${socialHandle}.svg`} />
}

export default SocialIcon
