import {
  AdvancedSearch,
  NFTCommunity,
  NftCommunityCategory,
  Paginated,
  PaginationInput,
  TraitOptionsResponse,
  TraitsResponse,
} from '@pangea/types'
import { withPagination } from '@/utils/links'
import { AuthenticatedClient } from './'

async function searchCommunity(query: string) {
  const res = await AuthenticatedClient.get<Paginated<NFTCommunity>>('/community/search', { params: { query } })
  return res.data.data
}

const getTrending = async (pagination?: PaginationInput) => {
  const res = await AuthenticatedClient.get<Paginated<NFTCommunity>>(withPagination('/community/trending', pagination))
  return res.data.data
}

interface PartnerCommunitiesParams {
  projectId: string
  pagination?: PaginationInput
}

const getPartnerCommunities = async ({ projectId, pagination }: PartnerCommunitiesParams) => {
  const res = await AuthenticatedClient.get<NFTCommunity[]>(
    withPagination(`project/${projectId}/community/partner`, pagination)
  )
  return res.data
}

const getRecommended = async (projectId: string) => {
  const res = await AuthenticatedClient.get<NFTCommunity[]>(`/project/${projectId}/community/recommend`)
  return res.data
}

const getByCategory = async (category: NftCommunityCategory, pagination?: PaginationInput) => {
  const res = await AuthenticatedClient.get<Paginated<NFTCommunity>>(
    withPagination(`/community/trending/${category}`, pagination)
  )

  return res.data.data
}

const getTraitDetails = async (projectId: string, collectionId: string) => {
  const url = `/project/${projectId}/community/traits?collectionId=${collectionId}`
  const res = await AuthenticatedClient.get<TraitsResponse>(url)

  return res.data
}

const getTraitOptions = async (projectId: string, collectionId: string, traitId: string) => {
  const url = `/project/${projectId}/community/traits/options?collectionId=${collectionId}&trait=${traitId}`
  const res = await AuthenticatedClient.get<TraitOptionsResponse>(url)

  return res.data
}

interface AdvancedSearchProps extends AdvancedSearch, PaginationInput {}

const search = async ({ page, pageSize, ...searchParams }: AdvancedSearchProps) => {
  const url = withPagination('/community/search/advanced', { page, pageSize })
  const res = await AuthenticatedClient.post<Paginated<NFTCommunity>, AdvancedSearch>(url, searchParams)

  return res.data
}

const CommunityService = {
  searchCommunity,
  getTrending,
  getRecommended,
  getPartnerCommunities,
  getByCategory,
  getTraitDetails,
  getTraitOptions,
  search,
}

export default CommunityService
