import { FC, useMemo, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Box, Link, useTheme } from '@mui/material'
import { openPopup } from '@shared/utils/browser'
import jwtDecode from 'jwt-decode'
import { ReturnToService } from '@shared/services/ReturnToService'
import { AuthService } from '@/api'
import { Button, Logo, Text } from '@/components'
import { NOTA_WEB_URL } from '@/constants'
import { useSession } from '@/hooks'

const DesktopAuth: FC = () => {
  const navigate = useNavigate()
  const { setSession } = useSession()
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()
  const returnToService = useMemo(() => new ReturnToService(), [])

  const onMessage = (event: MessageEvent, popup: Window | null) => {
    // Filter unwanted messages
    if (event.origin !== window.location.origin && event.origin !== AuthService.getLoginOrigin()) {
      return
    }
    if (!event.data || !(typeof event.data === 'string')) {
      return
    }

    const token = jwtDecode(event.data)
    if (!token) return

    popup?.close()
    setSession(event.data)

    const returnToStr = returnToService.getReturnTo()
    if (returnToStr) {
      returnToService.clearReturnTo()
      navigate(returnToStr)
    } else {
      navigate('/')
    }

    setIsLoading(false)
  }

  const handleLogin = async () => {
    const loginUrl = AuthService.getLoginUrl()

    if (window.opener) return window.location.assign(loginUrl)

    setIsLoading(true)
    const popup = openPopup(loginUrl, 500, 800)

    window.addEventListener('message', (event) => onMessage(event, popup), false)
    const timer = setInterval(() => {
      if (popup?.closed) {
        clearInterval(timer)
        setIsLoading(false)
      }
    }, 1000)
  }

  const handleSignUp = handleLogin

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        paddingX: 4,
        maxWidth: 506,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Text variant="h4" align="center">
          Log In / Sign Up
        </Text>
        <Text variant="body2" align="center">
          with{' '}
          <Link component={RouterLink} to={NOTA_WEB_URL}>
            Pangea Wallet
          </Link>{' '}
          for a secure and frictionless NFT experience.
        </Text>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Button id="btnContinueWithNotaWallet" disabled={isLoading} sx={{ height: 56 }} onClick={handleLogin}>
          {/* <Box component="img" marginRight={1} src="/assets/icons/platforms/ic_nota_wallet.svg" /> */}
          <Logo variant="basic" mr={1} />
          Login with Pangea Wallet
        </Button>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Text variant="caption" align="center" color={theme.palette.grey[500]}>
            Don't have an account?
          </Text>
          <Link onClick={handleSignUp}>
            <Text align="center" variant="body2" color="white">
              Create a new Pangea Wallet account
            </Text>
          </Link>
        </Box>
      </Box>
      <Text sx={{ height: 54, paddingX: 1.5 }} variant="caption" align="center" color={theme.palette.grey[500]}>
        *Pangea and Nota Wallet work together to ensure a smooth and secure journey for your brand and its end-users in
        the world of NFTs and Web 2 integrations.
      </Text>
    </Box>
  )
}

export default DesktopAuth
