import { URLS } from '@/utils/routes'
import SvgColor from '../SvgColor'

// ----------------------------------------------------------------------

const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  integrations: icon('ic_integrations'),
  exploreNFT: icon('ic_explore_nft'),
  apiKeys: icon('ic_api'),
  accountSettings: icon('ic_account_settings'),
}

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Home', path: URLS.DASHBOARD, icon: ICONS.dashboard },
      {
        title: 'NFT Campaign',
        path: URLS.NFT_CAMPAIGNS,
        icon: ICONS.analytics,
      },
      {
        title: 'NFT Communities',
        path: URLS.NFT_COMMUNITIES,
        icon: ICONS.exploreNFT,
        children: [
          {
            title: 'Explore',
            path: URLS.EXPLORE_NFT_COMMUNITIES,
          },
          {
            title: 'Advanced Search',
            path: URLS.SEARCH_NFT_COMMUNITIES,
          },
        ],
      },
      {
        title: 'Integrations',
        path: URLS.INTEGRATIONS,
        icon: ICONS.integrations,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // {
      //   title: 'API Keys',
      //   path: URLS.API_KEYS,
      //   icon: ICONS.apiKeys,
      // },
      {
        title: 'Account Settings',
        path: URLS.ACCOUNT_SETTINGS,
        icon: ICONS.accountSettings,
      },
      { title: 'Users', path: URLS.USERS, icon: ICONS.user },
    ],
  },
]

export default navConfig
