import { objectToInlineStyles } from '@/utils/formats'

const buttonStyles = {
  display: 'flex',
  alignItems: 'center',
  background: 'linear-gradient(103deg, #4776E6 4.35%, #5D26C1 64.71%)',
  width: '125px',
  height: '40px',
  justifyContent: 'center',
  color: 'white',
  border: 'none',
  fontSize: '12px',
  borderRadius: '50px',
  fontWeight: 'bold',
}

const buttonImageStyles = {
  marginRight: '4px',
  width: '21px',
  height: '14px',
}

const getClaimRewardButton = (campaignUrl: string) => `
  <button style="${objectToInlineStyles(buttonStyles)}" onClick="(function(){
    const left = window.screenX + (window.outerWidth - 500) / 2;
    const top = window.screenY + (window.outerHeight - 800) / 2.5;
    window.open('${campaignUrl}', '_blank', 'width=500px, height=800px' + ', top=' + top + ', left=' + left);
})();return false;">
    <img src="${window.location.origin}/logo/logo.svg" alt="pangea logo" style="${objectToInlineStyles(
  buttonImageStyles
)}"/> Claim reward
  </button>
`

export default getClaimRewardButton
