import { DataGrid as MuiDataGrid, DataGridProps } from '@mui/x-data-grid'

interface IDataGridProps extends DataGridProps {}

const DataGrid: React.FC<IDataGridProps> = (props) => {
  return (
    <MuiDataGrid
      disableRowSelectionOnClick
      {...props}
      sx={{
        '.MuiDataGrid-footerContainer': {
          display: props.pagination ? '' : 'none',
        },
        ...props.sx,
      }}
    />
  )
}

export default DataGrid
