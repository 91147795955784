import ApiClient from '@shared/utils/ApiClient'
import { dispatchCustomEvent } from '@shared/utils/browser'
import { SessionEvent } from '@shared/types/session'

export const PublicClient = new ApiClient({
  apiPrefix: '',
  baseUrl: process.env.REACT_APP_API_URL,
  onRefreshFail: () => {},
  onRefreshSuccess: () => {},
})

export const AuthenticatedClient = new ApiClient({
  apiPrefix: '',
  baseUrl: process.env.REACT_APP_API_URL,
  sessionApiBaseUrl: process.env.REACT_APP_NOTA_API_URL,
  onRefreshFail: () => dispatchCustomEvent(SessionEvent.REMOVE),
  onRefreshSuccess: (token: string) => dispatchCustomEvent<{ token: string }>(SessionEvent.UPDATE, { token }),
  refreshTokenUrl: '/token/refresh',
  logoutUrl: '/token/logout',
})

export { default as AuthService } from './AuthService'
export { default as ProjectService } from './ProjectService'
export { default as CommunityService } from './CommunityService'
export { default as BillingService } from './BillingService'
export { default as IntegrationService } from './IntegrationService'
export { default as LightspeedService } from './LightspeedService'
export { default as CampaignService } from './CampaignService'
export { default as TemplateService } from './TemplateService'
export { default as SquareService } from './SquareService'
export { default as ShopifyService } from './ShopifyService'
export { default as CommunityImportService } from './CommunityImportService'
export { default as StripeService } from './StripeService'
export { default as ProjectUserService } from './ProjectUserService'
