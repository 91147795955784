import { Box } from '@mui/material'
import { Text } from '@/components'

export interface OnboardingStepHeaderProps {
  title: string
  description?: string
}

/**
 * Component for rendering header inside OnboardingStep
 * @param title - Step title
 * @param description - Step description [optional]
 */
const OnboardingStepHeader: React.FC<OnboardingStepHeaderProps> = ({ title, description }) => {
  return (
    <Box gap={1} mb={4.5} display="flex" flexDirection="column">
      <Text variant="h3">{title}</Text>
      <Text variant="subtitle1" color="grey.500">
        {description}
      </Text>
    </Box>
  )
}

export default OnboardingStepHeader
