import { Box } from '@mui/material'
import { useQuery } from 'react-query'
import { MindbodyPricingOption } from '@pangea/types'
import { useFormContext } from 'react-hook-form'
import { Text } from '@/components'
import Select from '@/components/form/Select'
import MindbodyService from '@/api/MindbodyService'
import { useProject } from '@/hooks'
import Container from '../../Container'

const mapToPricingOptions = (pricingOptions: MindbodyPricingOption[]): { label: string; value: string }[] => {
  return pricingOptions.map((t, index) => {
    return {
      label: t.name + ' ($' + t.onlinePrice + ')',
      value: t.id,
    }
  })
}

const MindbodyRewardDefinition = () => {
  const { watch, register } = useFormContext()
  const { project } = useProject()
  const pricingOptionsQuery = useQuery(['pricingOptions', project!.id], () =>
    MindbodyService.getPricingOptions(project!.id)
  )

  return (
    <>
      <Container mt={3}>
        <Text variant="h5">Reward Definition</Text>
        <Box mt={2} width="100%">
          <Select
            label="Description"
            options={pricingOptionsQuery?.data?.data ? mapToPricingOptions(pricingOptionsQuery.data.data) : []}
            containerProps={{ sx: { width: '100%' } }}
            placeholder="Select a reward"
            value={watch('rewardDefinitionDataId')}
            {...register('rewardDefinitionDataId')}
          />
        </Box>
      </Container>
    </>
  )
}

export default MindbodyRewardDefinition
