import { Alert, Box } from '@mui/material'
import { ContractType, NFTCommunity, RulesType } from '@pangea/types'
import { useEffect, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { Iconify, ImportCommunityButton, Text, TextField, Tooltip } from '@/components'
import { NFTCommunityDropdownItemSimple } from '@/components/NFTCommunityDropdown/components'
import NFTCommunityDropdown from '@/components/NFTCommunityDropdown'
import { useCommunityDetailsModal, useCommunityTraitsModal, useUserPreferences } from '@/hooks'
import { CommunityWithTraits, FormValues } from '@/modules/NFTCampaignWizard'
import Container from '../../Container'
import Info from '../Info'
import RoundContainer from '../RoundContainer'
import ImportCommunityModal from '../ImportCommunityModal'

interface NFTCommunitiesSectionProps {
  selectedCommunities: CommunityWithTraits[]
  removeSelectedCommunity: (address: string) => void
  onSelect: (community: NFTCommunity) => void
}

const NFTCommunitiesSection: React.FC<NFTCommunitiesSectionProps> = ({
  selectedCommunities,
  onSelect,
  removeSelectedCommunity,
}) => {
  const [isImportCommunityModalOpen, setIsImportCommunityModalOpen] = useState(false)
  const { open } = useCommunityDetailsModal()
  const { preferences, changePreferences } = useUserPreferences()
  const { open: openTraits } = useCommunityTraitsModal()
  const { control, watch, setValue } = useFormContext<FormValues>()
  const communities = useWatch({ control, name: 'communities' })

  const isCountDisabled = watch('trackNftUsed')

  useEffect(() => {
    const hasNonErc721 = communities.some((community) => community.contractType !== ContractType.ERC721)

    if (hasNonErc721) {
      setValue('trackNftUsed', false)
      setValue('type', RulesType.SEMI)
    } else {
      setValue('type', RulesType.NFT)
    }
  }, [communities, setValue])

  return (
    <Container mt={3}>
      <Text variant="h5">NFT Communities</Text>
      <Text variant="caption" color="text.secondary" display="block" mb={4}>
        Search for and select up to 8 NFT Communities to tie to your campaign
      </Text>

      {!!selectedCommunities?.length && (
        <Box mb={4}>
          <Text>Selected</Text>
          {selectedCommunities.map((community, i) => (
            <Box
              key={community.address}
              display="flex"
              alignItems="center"
              mt={i <= selectedCommunities.length - 1 ? 1 : 0}
            >
              <Container flexGrow={1} size="medium" display="flex" alignItems="center" mr={1}>
                <Tooltip title="Community details" onClick={() => open({ community, infoOnly: true })}>
                  <Info />
                </Tooltip>
                <NFTCommunityDropdownItemSimple
                  mode="advanced"
                  community={community}
                  style={{
                    listStyleType: 'none',
                    flexGrow: 1,
                    marginLeft: 16,
                  }}
                />
                <Tooltip title="Trait configuration">
                  <RoundContainer onClick={() => openTraits({ community })} ml={8} bgOnHover>
                    <Iconify
                      icon="mingcute:settings-2-line"
                      color={community.requiredTraits.length ? 'warning.main' : 'text.secondary'}
                    />
                  </RoundContainer>
                </Tooltip>
                <Tooltip
                  title={
                    isCountDisabled
                      ? "You can't set quantity because bind reward to individual NFT is enabled."
                      : 'Minimum tokens required'
                  }
                >
                  <Box>
                    <Controller
                      name={`communities.${i}.count`}
                      control={control}
                      defaultValue={community.count}
                      render={({ field }) => (
                        <TextField
                          disabled={isCountDisabled}
                          type="number"
                          {...field}
                          InputProps={{
                            type: 'number',
                          }}
                          sx={{
                            width: '3rem',
                            ml: 2,
                            '& input': {
                              height: '2.5rem',
                              textAlign: 'center',
                              p: 0.5,
                            },
                          }}
                          variant="filled"
                        />
                      )}
                    />
                  </Box>
                </Tooltip>
              </Container>

              <Tooltip title="Remove community">
                <RoundContainer onClick={() => removeSelectedCommunity(community.address)} bgOnHover>
                  <Iconify icon="eva:close-fill" color="text.secondary" />
                </RoundContainer>
              </Tooltip>
            </Box>
          ))}
        </Box>
      )}

      {preferences.alerts.traits && (
        <Alert
          severity="info"
          sx={{ mb: 4 }}
          variant="outlined"
          onClose={() => changePreferences('alerts', { traits: false })}
        >
          You can narrow down your audience even further by defining specific traits for each community.
        </Alert>
      )}

      <NFTCommunityDropdown title="Search NFT Communities" setValue={(community) => community && onSelect(community)} />

      <Text variant="caption" color="text.secondary" display="block" mt={4}>
        Can't find your community?
      </Text>
      <ImportCommunityButton sx={{ mt: 1 }}>Search for your community</ImportCommunityButton>

      {isImportCommunityModalOpen && <ImportCommunityModal onClose={() => setIsImportCommunityModalOpen(false)} />}
    </Container>
  )
}

export default NFTCommunitiesSection
