export function getDocsUrl(): string {
  const docsUrl = process.env.NEXT_PUBLIC_DOCS_URL as string;
  if (docsUrl) {
    return docsUrl;
  }
  return process.env.REACT_APP_DOCS_URL as string;
}

export function getPangeaUrl(): string {
  const pangeaUrl = process.env.NEXT_PUBLIC_PANGEA_URL as string;
  if (pangeaUrl) {
    return pangeaUrl;
  }
  return process.env.REACT_APP_PANGEA_URL as string;
}
