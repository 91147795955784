import { ApiError } from '../api/errors';
import { ErrorCodes } from '../api/types';
/**
 * **@PangeaPlanType** Enum for representing different Pangea plan types.
 */
export var PangeaPlanType;
(function (PangeaPlanType) {
    PangeaPlanType["FREE"] = "FREE";
    PangeaPlanType["STANDARD_MONTHLY"] = "STANDARD_MONTHLY";
    PangeaPlanType["STANDARD_ANNUAL"] = "STANDARD_ANNUAL";
    PangeaPlanType["BUSINESS_MONTHLY"] = "BUSINESS_MONTHLY";
    PangeaPlanType["BUSINESS_ANNUAL"] = "BUSINESS_ANNUAL";
    PangeaPlanType["ENTERPRISE"] = "ENTERPRISE";
})(PangeaPlanType || (PangeaPlanType = {}));
/**
 * **@StripeLookupKeys** Enum for representing Stripe lookup keys.
 */
export var StripeLookupKeys;
(function (StripeLookupKeys) {
    StripeLookupKeys["FREE"] = "FREE";
    StripeLookupKeys["STANDARD_M"] = "STANDARD_M";
    StripeLookupKeys["STANDARD_Y"] = "STANDARD_Y";
    StripeLookupKeys["BUSINESS_M"] = "BUSINESS_M";
    StripeLookupKeys["BUSINESS_Y"] = "BUSINESS_Y";
    StripeLookupKeys["STANDARD_API"] = "STANDARD_API";
    StripeLookupKeys["STANDARD_CLAIMS"] = "STANDARD_CLAIMS";
    StripeLookupKeys["BUSINESS_API"] = "BUSINESS_API";
    StripeLookupKeys["BUSINESS_CLAIMS"] = "BUSINESS_CLAIMS";
})(StripeLookupKeys || (StripeLookupKeys = {}));
export const mapToStripe = {
    [PangeaPlanType.FREE]: [StripeLookupKeys.FREE],
    [PangeaPlanType.STANDARD_MONTHLY]: [
        StripeLookupKeys.STANDARD_M,
        // StripeLookupKeys.STANDARD_API,
        // StripeLookupKeys.STANDARD_CLAIMS,
    ],
    [PangeaPlanType.STANDARD_ANNUAL]: [
        StripeLookupKeys.STANDARD_Y,
        // StripeLookupKeys.STANDARD_API,
        // StripeLookupKeys.STANDARD_CLAIMS,
    ],
    [PangeaPlanType.BUSINESS_MONTHLY]: [
        StripeLookupKeys.BUSINESS_M,
        // StripeLookupKeys.BUSINESS_API,
        // StripeLookupKeys.BUSINESS_CLAIMS,
    ],
    [PangeaPlanType.BUSINESS_ANNUAL]: [
        StripeLookupKeys.BUSINESS_Y,
        // StripeLookupKeys.BUSINESS_API,
        // StripeLookupKeys.BUSINESS_CLAIMS,
    ],
    [PangeaPlanType.ENTERPRISE]: [], // Add lookup keys for the Enterprise plan if needed
};
export function mapFromStripe(lookupKey) {
    switch (lookupKey) {
        case StripeLookupKeys.FREE:
            return PangeaPlanType.FREE;
        case StripeLookupKeys.BUSINESS_M:
            return PangeaPlanType.BUSINESS_MONTHLY;
        case StripeLookupKeys.BUSINESS_Y:
            return PangeaPlanType.BUSINESS_ANNUAL;
        case StripeLookupKeys.STANDARD_M:
            return PangeaPlanType.STANDARD_MONTHLY;
        case StripeLookupKeys.STANDARD_Y:
            return PangeaPlanType.STANDARD_ANNUAL;
        default:
            throw new ApiError(400, 'invalid stripe price lookup key', ErrorCodes.VALIDATION_ERROR);
    }
}
export function toPangeaPlanType(value) {
    if (Object.values(PangeaPlanType).includes(value)) {
        return value;
    }
    throw new ApiError(409, 'Invalid plan code:' + value, ErrorCodes.INVALID_CONTENT_TYPE);
}
export function isPangeaPaidPlan(plan) {
    return 'apiPrice' in plan && 'claimsPrice' in plan && 'licensePrice' in plan;
}
export function getPangeaPaidPlans(plans) {
    return plans.filter(isPangeaPaidPlan);
}
