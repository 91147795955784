import { Controller, useFormContext } from 'react-hook-form'
import { NftCampaignDetail } from '@pangea/types'
import { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { Text, TextField } from '@/components'

import Select from '@/components/form/Select'
import { DiscountType } from '@/modules/NFTCampaignWizard'
import Container from '../../Container'

interface WixRewardDefinitionProps {
  campaign: NftCampaignDetail
}

const BigCommerceRewardDefinition: React.FC<WixRewardDefinitionProps> = ({ campaign }) => {
  const [discountType, setDiscountType] = useState(DiscountType.fixed)
  const { control, setValue } = useFormContext()

  const onDiscountTypeChanged = useCallback(
    (newType: DiscountType) => {
      setDiscountType(newType)
      setValue('discountType', newType, { shouldDirty: true })
    },
    [setValue]
  )

  useEffect(() => {
    if ('percentage' in campaign.rewardDefinition.rewardDefinitionData) {
      onDiscountTypeChanged(DiscountType.percentage)
    } else if ('freeShipping' in campaign.rewardDefinition.rewardDefinitionData) {
      onDiscountTypeChanged(DiscountType.freeShipping)
    }
  }, [campaign.rewardDefinition.rewardDefinitionData, onDiscountTypeChanged])

  return (
    <>
      <Container mt={3}>
        <Text variant="h5" mb={3}>
          Reward Definition
        </Text>
        <Box display="flex">
          <Select
            label="Description"
            value={discountType}
            onChange={(e) => onDiscountTypeChanged(e.target.value as DiscountType)}
            options={Object.values(DiscountType).map((t) => {
              return {
                label: t,
                value: t,
              }
            })}
            containerProps={{ sx: { width: '100%', mr: 4 } }}
          />
          {discountType !== DiscountType.freeShipping && (
            <Controller
              name={discountType === DiscountType.fixed ? 'fixedAmount' : 'percentageAmount'}
              defaultValue={
                'percentage' in campaign.rewardDefinition.rewardDefinitionData
                  ? campaign.rewardDefinition.rewardDefinitionData.percentage
                  : 'valueInUsd' in campaign.rewardDefinition.rewardDefinitionData
                  ? campaign.rewardDefinition.rewardDefinitionData.valueInUsd
                  : 'freeShipping' in campaign.rewardDefinition.rewardDefinitionData
                  ? campaign.rewardDefinition.rewardDefinitionData.freeShipping
                  : undefined
              }
              control={control}
              render={({ field }) => (
                <TextField
                  label="Amount"
                  {...field}
                  InputProps={{
                    startAdornment: discountType === DiscountType.fixed ? '$ ' : undefined,
                    endAdornment: discountType === DiscountType.percentage ? ' %' : undefined,
                  }}
                  sx={{ flexGrow: 1 }}
                />
              )}
            />
          )}
        </Box>
      </Container>
    </>
  )
}

export default BigCommerceRewardDefinition
