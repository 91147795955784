import { Box, Switch } from '@mui/material'
import { ContractType, NFTCommunity, NftCampaignDetail } from '@pangea/types'
import { Controller, useFormContext } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { Text, TextField, Tooltip } from '@/components'
import { FormValues } from '@/modules/NFTCampaignWizard'
import Container from '../Container'
import getBindNftTooltipText from '../utils/getBindNftTooltipText'
import NFTCommunitiesSection from './RewardDefinition/NFTCommunitiesSection'

interface RequirementsTabProps {
  campaign: NftCampaignDetail
}

const RequirementsTab: React.FC<RequirementsTabProps> = ({ campaign }) => {
  const { control, watch, getValues, setValue } = useFormContext<FormValues>()
  const isBindDisabled = watch('communities').some((community) => community.count > 1)
  const containsNonErc721 = watch('communities').some((community) => community.contractType !== ContractType.ERC721)

  const onSelect = (community: NFTCommunity) => {
    const prev = getValues('communities')
    const isAlreadySelected = prev.find((p) => p.address === community.address)
    if (isAlreadySelected) return

    setValue('communities', [...prev, { ...community, requiredTraits: [], count: 1 }], { shouldDirty: true })
  }

  const removeSelectedCommunity = (address: string) => {
    const prev = getValues('communities')
    const filtered = prev.filter((p) => p.address !== address)
    setValue('communities', filtered, { shouldDirty: true })
  }

  return (
    <>
      <Container mb={3}>
        <Text variant="h5">Campaign presets</Text>
        <Box display="flex" my={4}>
          <Controller
            name="startDate"
            control={control}
            defaultValue={moment(campaign.startDate)}
            render={({ field }) => <DatePicker label="Start date" {...field} />}
          />
          <Controller
            name="endDate"
            control={control}
            defaultValue={moment(campaign.endDate)}
            render={({ field }) => <DatePicker label="End date" sx={{ mx: 4 }} {...field} />}
          />
          <Controller
            name="maxClaims"
            control={control}
            defaultValue={campaign.maxClaims || undefined}
            render={({ field }) => <TextField type="number" label="Max claims" {...field} />}
          />
        </Box>

        <Box display="flex">
          <Box display="flex" alignItems="center" mr={4}>
            <Box mr={2}>
              <Text variant="subtitle2" display="block">
                Bind reward to individual NFT
              </Text>
              <Text color="text.secondary" variant="caption">
                prevent generation of reward from the same NFT
              </Text>
            </Box>
            <Controller
              name="trackNftUsed"
              defaultValue={campaign.trackNftUsed}
              control={control}
              render={({ field }) => (
                <Tooltip title={getBindNftTooltipText(isBindDisabled, containsNonErc721)}>
                  <Box>
                    <Switch {...field} disabled={isBindDisabled || containsNonErc721} />
                  </Box>
                </Tooltip>
              )}
            />
          </Box>
          {/* <Box display="flex" alignItems="center">
            <Box mr={2}>
              <Text variant="subtitle2" display="block">
                SoulBound
              </Text>
              <Text color="text.secondary" variant="caption">
                Each Individual Nota Wallet user can only redeem a reward once
              </Text>
            </Box>
            <Controller
              name="soulBound"
              defaultValue={campaign.soulBound}
              control={control}
              render={({ field }) => <Switch {...field} />}
            />
          </Box> */}
        </Box>
      </Container>
      <NFTCommunitiesSection
        onSelect={onSelect}
        removeSelectedCommunity={removeSelectedCommunity}
        selectedCommunities={watch('communities')}
      />
    </>
  )
}

export default RequirementsTab
