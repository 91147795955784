import { NftCommunityCategory } from '@pangea/types'
import { Image } from '@/components'

interface CommunityCategoryIconProps {
  category: NftCommunityCategory
}

function getIconUrl(category: NftCommunityCategory): string {
  const categorySlug = category.toLocaleLowerCase().replaceAll(' ', '_')
  const url = `/assets/icons/components/ic_nft_community_dropdown_category_${categorySlug}.svg`
  return url
}

const CommunityCategoryIcon: React.FC<CommunityCategoryIconProps> = ({ category }) => (
  <Image alt={`${category} category icon`} src={getIconUrl(category)} width={16} />
)

export default CommunityCategoryIcon
