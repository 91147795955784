import { FC, ReactNode } from 'react'
import { Button, Iconify } from '@/components'

type CampaignPublishedActionProps = {
  children: ReactNode
  icon: 'mdi:open-in-new' | 'bxs:copy'
  onClick: () => void
}

const CampaignPublishedAction: FC<CampaignPublishedActionProps> = ({ children, icon, onClick }) => {
  return (
    <Button
      startIcon={<Iconify icon={icon} />}
      sx={{
        bgcolor: (theme) => theme.palette.grey[800],
        '&:hover': {
          bgcolor: (theme) => theme.palette.grey[700],
          boxShadow: 'none',
        },
        height: 48,
      }}
      fullWidth
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export default CampaignPublishedAction
