import { startTransition, useState } from 'react'
// hooks
import { Container, Grid, Box, Typography, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeElements } from '@stripe/stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { yupResolver } from '@hookform/resolvers/yup'

import { BillingService } from '@/api'
// ----------------------------------------------------------------------
import { useResponsive, useUser } from '@/hooks'

import { OnboardingStepFooter } from '@/components/onboarding'
import { BillingTypeEnum } from '@/modules/SelectPlan'
import { EnrichedPlan, isMonthlyBilling } from '../utils'
import {
  StripeTextFieldNumber,
  StripeTextFieldCVC,
  StripeTextFieldExpiry,
  OnStripeChange,
} from './components/Stripe/StripeTextField'
import { PaymentSummary, PaymentFailedDialog, PaymentSuccessDialog, PaymentProcessingDialog } from './components'
import RHFCountryDropdown from './components/forms/RHFCountryDropdown'
import RHFTextField from './components/forms/RHFTextField'
import { BillingDetailsSchema, IFormInput } from './types'

const icons = ['/assets/icons/payments/ic_mastercard.svg', '/assets/icons/payments/ic_visa.svg']

interface Props {
  projectId: string
  selectedPlan: EnrichedPlan
  toggleBillingPeriod: (type: BillingTypeEnum) => void
  onChangePlan: () => void
  onSuccess: () => void
}

const LicensePaymentContainer = ({ selectedPlan, onChangePlan, onSuccess, projectId, toggleBillingPeriod }: Props) => {
  const { metadata } = useUser()
  const isDesktop = useResponsive('up', 'md')
  const [, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [paymentError, setPaymentError] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const {
    control,
    getValues,
    handleSubmit,
    formState: { isValid },
  } = useForm<IFormInput>({
    resolver: yupResolver(BillingDetailsSchema),
    mode: 'onChange',
    defaultValues: {
      zipCode: '',
      country: undefined,
      companyName: '',
      cardHolder: '',
      billingAddress: '',
    },
  })
  const [state, setState] = useState({
    cardNumber: {
      complete: false,
      error: undefined,
    },
    expired: {
      complete: false,
      error: undefined,
    },
    cvc: {
      complete: false,
      error: undefined,
    },
  })

  const handlePurchase = async (res: any) => {
    await submitStripe(stripe, elements)()
  }

  const onElementChange =
    (field: keyof typeof state) =>
    ({ complete, error }: OnStripeChange) =>
      setState((prev) => ({ ...prev, [field]: { complete, error: error?.message } }))

  const { cardNumber, expired, cvc } = state

  const isFormDirty = !!cardNumber.error || !!expired.error || !!cvc.error || !isValid

  const submitStripe = (stripe: Stripe | null, elements: StripeElements | null) => async () => {
    try {
      setIsLoading(true)
      const cardElement = elements?.getElement(CardNumberElement)
      if (!cardElement || !stripe) {
        setError('Error')
        return
      }

      const subscriptions = await BillingService.createSubscription({ planId: selectedPlan.id, projectId })
      const validSubscription = subscriptions.find((s) => !!s.clientSecret)
      if (!validSubscription || !validSubscription.clientSecret) {
        throw new Error('Subscription not found!')
      }

      const values = getValues()
      const { error } = await stripe.confirmCardPayment(validSubscription.clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: values.cardHolder,
            email: metadata.email,
            phone: metadata.phone,
            address: {
              postal_code: values.zipCode,
              country: values.country?.code,
              line1: values.billingAddress,
            },
          },
        },
      })

      // console.log(paymentIntent)
      setIsLoading(false)
      if (error) {
        setError(error?.message || 'Error')
        setPaymentError(true)
      } else {
        setPaymentSuccess(true)
      }
    } catch (ex) {
      alert('Error')
    }
  }

  const handleSuccessConfirmation = () => {
    startTransition(() => {
      setPaymentSuccess(false)
      onSuccess()
    })
  }

  const backButtonProps = {
    onClick: onChangePlan,
  }

  return (
    <>
      <PaymentProcessingDialog title="Processing your payment" open={isLoading} />
      <PaymentFailedDialog onClose={() => setPaymentError(false)} open={paymentError} />
      <PaymentSuccessDialog title="Payment Successful!" onClose={handleSuccessConfirmation} open={paymentSuccess} />
      <Container
        sx={{
          pt: 15,
          pb: 10,
          minHeight: 1,
        }}
      >
        <form onSubmit={handleSubmit(handlePurchase)}>
          <Grid container spacing={isDesktop ? 3 : 5}>
            <Grid item xs={12} md={8}>
              <Box
                gap={5}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  md: 'repeat(1, 1fr)',
                }}
                sx={{
                  p: { md: 5 },
                  borderRadius: 2,
                  bgcolor: 'background.neutral',
                  border: (theme) => ({
                    md: `dashed 1px ${theme.palette.divider}`,
                  }),
                }}
              >
                <Box>
                  <div style={{ position: 'relative' }}>
                    <Typography variant="h6">Payment Info</Typography>
                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      sx={{ position: 'absolute', right: 20, top: 0 }}
                    >
                      {icons.map((icon) => (
                        <Box component="img" key={icon} src={icon} />
                      ))}
                    </Stack>
                  </div>

                  <Stack spacing={1.25} mt={5}>
                    <RHFTextField required={true} label="Cardholder Name" name="cardHolder" control={control} />

                    <StripeTextFieldNumber
                      label="Card number"
                      error={!!cardNumber.error}
                      helperText={cardNumber.error}
                      onChange={onElementChange('cardNumber')}
                    />

                    <StripeTextFieldExpiry
                      label="Expiration"
                      error={!!expired.error}
                      helperText={expired.error}
                      onChange={onElementChange('expired')}
                    />
                    <StripeTextFieldCVC error={!!cvc.error} helperText={cvc.error} onChange={onElementChange('cvc')} />
                  </Stack>
                </Box>
                <Box>
                  <Typography variant="h6">Billing Address</Typography>

                  <Stack spacing={1.25} mt={5}>
                    <RHFCountryDropdown control={control} />
                    <RHFTextField required={true} label="Zip Code" name="zipCode" control={control} />
                    <RHFTextField required={true} label="Billing Address" name="billingAddress" control={control} />
                    <RHFTextField required={true} label="Organisation Name" name="companyName" control={control} />
                  </Stack>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <PaymentSummary
                purchaseAllowed={!isFormDirty}
                onChangePlan={onChangePlan}
                isMonthly={isMonthlyBilling(selectedPlan.type)}
                subscription={selectedPlan?.subscription}
                priceInterval={selectedPlan?.licensePrice?.recurring?.interval || ''}
                pricing={selectedPlan.pricing}
                toggleSubscriptionType={(checked) =>
                  toggleBillingPeriod(checked ? BillingTypeEnum.ANNUALLY : BillingTypeEnum.MONTHLY)
                }
              />
            </Grid>
          </Grid>
        </form>
        <Box mt={2}>
          <OnboardingStepFooter backButtonProps={backButtonProps} />
        </Box>
      </Container>
    </>
  )
}

export default LicensePaymentContainer
