import { useSnackbar, OptionsObject, SnackbarKey } from 'notistack'

type UseSnackReturn = {
  enqueueSnackbar: (message: string, options?: OptionsObject) => SnackbarKey
  closeSnackbar: (key?: SnackbarKey) => void
}

export default function useSnack(): UseSnackReturn {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  return {
    enqueueSnackbar,
    closeSnackbar,
  }
}
