import { FC } from 'react'
import { Box } from '@mui/material'
import { useQuery } from 'react-query'
import { CommunityService } from '@/api'
import { Error, RecentCampaigns, Text, NftCommunityList, FeaturedCommunity } from '@/components'
import { useProject } from '@/hooks'
import SelectedCommunitiesBanner from '@/components/SelectedCommunitiesBanner'
import FeaturedCommunitySkeleton from '@/components/FeaturedCommunity/FeaturedCommunitySkeleton'

const Dashboard: FC = () => {
  const { project } = useProject()
  const {
    data: trending,
    isLoading,
    error,
  } = useQuery('top-2-trending', () => CommunityService.getTrending({ page: 1, pageSize: 2 }))
  if (!isLoading && (error || !trending)) return <Error />

  return (
    <>
      <SelectedCommunitiesBanner />

      <Box height="100%">
        <Text variant="h6" pb={2}>
          Trending
        </Text>
        <Box display="flex">
          {isLoading
            ? [{}, {}].map((_, i) => <FeaturedCommunitySkeleton key={i} mr={i === 0 ? 4 : 0} />)
            : trending?.map((community, i) => (
                <FeaturedCommunity key={community.address} mr={i === 0 ? 4 : 0} community={community} />
              ))}
        </Box>
        <Text variant="h6" pb={2} mt={6.5} borderBottom="1px solid rgba(145, 158, 171, 0.24)" mb={1}>
          Recommended NFT Communities
        </Text>
        <NftCommunityList
          communityQuery={{ queryKey: 'recommended', queryFn: () => CommunityService.getRecommended(project!.id) }}
          columns={2}
          rowGap={1.5}
          columnGap={8}
        />

        <Text variant="h6" mb={2} mt={7}>
          Your Recent Campaigns
        </Text>
        <RecentCampaigns />
      </Box>
    </>
  )
}

export default Dashboard
