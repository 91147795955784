import { Theme } from '@mui/material/styles'
import { AvatarProps } from '@/components/Avatar/Avatar'

// ----------------------------------------------------------------------
const COLORS = [
  'primary',
  'secondary',
  'info',
  'success',
  'warning',
  'error',
] as const

const SIZES: { [key: string]: number } = {
  tiny: 24,
  'extra-small': 32,
  small: 40,
  medium: 48,
  large: 64,
  'extra-large': 96,
} as const

export default function Avatar(theme: Theme) {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: ({ color, size, icon }: AvatarProps) => {
          const colorStyle = COLORS.map(
            (clr) =>
              clr === color && {
                ...{
                  backgroundColor: theme.palette[color].main,
                  color: icon
                    ? theme.palette.warning.contrastText
                    : theme.palette[color].contrastText,
                },
              }
          )

          const matchedSize = size ? size : 'medium'
          const sizeStyle = {
            ...{
              width: SIZES[matchedSize],
              height: SIZES[matchedSize],
            },
          }

          return [...colorStyle, sizeStyle]
        },
      },
    },
    MuiAvatarGroup: {
      defaultProps: {
        max: 4,
      },
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
        },
        avatar: {
          fontSize: 16,
          fontWeight: theme.typography.fontWeightMedium,
          '&:first-of-type': {
            fontSize: 12,
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.lighter,
          },
        },
      },
    },
  }
}
