import { Box, BoxProps } from '@mui/material'
import { NFTCommunity } from '@pangea/types'
import { Iconify, Text } from '@/components'
import { formatHumanizedCurencyAmount, humanizeNumber } from '@/utils/formats'
import { useCommunityDetailsModal } from '@/hooks'
import SocialConnection from './SocialConnection'

interface NftCommunityListItemProps extends BoxProps {
  community: NFTCommunity
  index: number
  detailed?: boolean
}

const NftCommunityListItem: React.FC<NftCommunityListItemProps> = ({
  community,
  index,
  detailed = false,
  ...boxProps
}) => {
  const { open } = useCommunityDetailsModal()

  const twitterHandle = community.socialHandles.twitter
  const discordHandle = community.socialHandles.discord
  const instagramHandle = community.socialHandles.instagram

  return (
    <>
      <Box
        {...boxProps}
        display="flex"
        justifyContent="space-between"
        p={detailed ? 2 : 1}
        borderRadius={1}
        sx={{
          ...boxProps.sx,
          '&:hover': {
            cursor: 'pointer',
            bgcolor: 'rgba(145, 158, 171, 0.08)',
          },
        }}
        onClick={() => open({ community })}
      >
        <Box flexGrow={1} display="flex" alignItems="center">
          <Text width={10} variant="body2">
            {index + 1}
          </Text>
          <img
            src={community.images.imageUrl}
            alt="community img"
            width={48}
            height={48}
            style={{ borderRadius: 8, marginInline: 16 }}
          />
          <Box>
            <Text variant="subtitle2">{community.name}</Text>
            {detailed && (
              <Box display="flex">
                {twitterHandle?.followers ? (
                  <SocialConnection icon="eva:twitter-fill" value={humanizeNumber(twitterHandle.followers)} />
                ) : null}
                {discordHandle?.followers ? (
                  <SocialConnection icon="ic:round-discord" value={humanizeNumber(discordHandle.followers)} />
                ) : null}
                {instagramHandle?.followers ? (
                  <SocialConnection icon="uil:instagram-alt" value={humanizeNumber(instagramHandle.followers)} />
                ) : null}
              </Box>
            )}
          </Box>
        </Box>
        {detailed ? (
          <Box display="flex">
            <Box mr={2}>
              <div>
                <Text variant="caption" color={(theme) => theme.palette.grey[500]}>
                  Avg. value
                </Text>
              </div>
              <Text variant="overline">{formatHumanizedCurencyAmount(community.tradingData.averagePriceUsd)}</Text>
            </Box>
            <Box>
              <div>
                <Text variant="caption" color={(theme) => theme.palette.grey[500]}>
                  Unique owners
                </Text>
              </div>
              <Text variant="overline">{humanizeNumber(community.tradingData.owners)}</Text>
            </Box>
          </Box>
        ) : (
          <Box flexGrow={1} display="flex" alignItems="center" justifyContent="flex-end">
            <Iconify icon="logos:ethereum" style={{ marginRight: '0.8rem' }} width={32} height={32} />
            <Box display="flex" ml={3}>
              <Text color="GrayText" variant="subtitle2">
                Avg. value
              </Text>
              <Text ml={0.5} variant="subtitle2">
                {formatHumanizedCurencyAmount(community.tradingData.averagePriceUsd)}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default NftCommunityListItem
