import { Box, Switch, Typography } from '@mui/material'
import { BillingTypeEnum } from '@/modules/SelectPlan'

interface BillingPeriodToggleProps {
  billingPeriod: BillingTypeEnum
  setBillingPeriod: (billingPeriod: BillingTypeEnum) => void
}

const BillingPeriodToggle: React.FC<BillingPeriodToggleProps> = ({ billingPeriod, setBillingPeriod }) => {
  return (
    <Box>
      <Typography variant="overline">MONTHLY</Typography>
      <Switch
        checked={billingPeriod === BillingTypeEnum.ANNUALLY}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setBillingPeriod(event.target.checked ? BillingTypeEnum.ANNUALLY : BillingTypeEnum.MONTHLY)
        }}
      />
      <Typography variant="overline">Annual (Save 35%)</Typography>
      <Typography variant="caption" align="left" sx={{ color: 'text.secondary', display: 'block', mb: 3 }}>
        * Plus applicable taxes
      </Typography>
    </Box>
  )
}

export default BillingPeriodToggle
