import { CurrentPlan, ResponseMessage, SubscriptionCreationResponse } from '@pangea/types'
import Stripe from 'stripe'
import { AvailablePlans } from '@/types/plan'
import { AuthenticatedClient } from '.'

async function getPlans() {
  const res = await AuthenticatedClient.get<AvailablePlans>('/billing/plans')
  return res.data
}

interface createSubscriptionProps {
  planId: string
  projectId: string
}

async function createSubscription({
  planId,
  projectId,
}: createSubscriptionProps): Promise<SubscriptionCreationResponse[]> {
  const res = await AuthenticatedClient.post<SubscriptionCreationResponse[], null>(
    `/project/${projectId}/billing/create-subscription?planId=${planId}`
  )
  return res.data
}

interface addPaymentMethodProps {
  paymentMethodId: string
  projectId: string
}

async function addPaymentMethod({ paymentMethodId, projectId }: addPaymentMethodProps): Promise<ResponseMessage> {
  const res = await AuthenticatedClient.post<ResponseMessage, null>(
    `/project/${projectId}/billing/add-payment-method?paymentMethodId=${paymentMethodId}`
  )
  return res.data
}

const getCurrentPlan = async (project: string) => {
  const res = await AuthenticatedClient.get<CurrentPlan>(`/project/${project}/billing/plan`)
  return res.data
}

const manageBilling = async (project: string) => {
  const returnTo = encodeURIComponent(window.location.href)
  const res = await AuthenticatedClient.post<Stripe.BillingPortal.Session, undefined>(
    `/project/${project}/billing/manage-billing?returnTo=${returnTo}`
  )

  return res.data
}

const BillingService = {
  addPaymentMethod,
  createSubscription,
  getPlans,
  getCurrentPlan,
  manageBilling,
}

export default BillingService
