import { objectToInlineStyles } from '@/utils/formats'

const cardStyles = {
  background: 'linear-gradient(90deg, #000000 0%, #000000 100%)',
  maxWidth: '500px',
  minHeight: 'fit-content',
  padding: '16px',
  position: 'relative',
  color: 'white',
  fontFamily: 'Inter, serif',
}

const claimButtonStyles = {
  width: '98px',
  height: '30px',
  borderRadius: '0',
  background: 'white',
  color: 'black',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
}

const containerStyles = {
  width: '56px',
  display: 'flex',
  alignItems: 'center',
}

const backgroundImgStyles = {
  height: '100%',
  objectFit: 'contain',
  objectPosition: 'right',
  zIndex: '10',
  position: 'absolute',
  top: '0px',
  right: '0px',
}

const logoStyles = {
  width: '100%',
  height: '14px',
  marginTop: '0.5rem',
}

interface BannerProps {
  title: string
  description: string
  campaignUrl: string
}

const getBanner = ({ title, description, campaignUrl }: BannerProps) => `
  <style>
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
  </style>
  <div style="${objectToInlineStyles(cardStyles)}">
    <div style="${objectToInlineStyles({ zIndex: '20', position: 'relative', width: '100%' })}">
      <h2 style="${objectToInlineStyles({ fontWeight: 'bold', margin: '0px' })}">
        ${title}
      </h2>
      <p style="${objectToInlineStyles({ fontSize: 'small', marginTop: '0px', marginBottom: '8px', maxWidth: '75%' })}">
        ${description}
      </p>
      <div style="${objectToInlineStyles({ display: 'flex', marginBottom: '12px' })}">
        <button style="${objectToInlineStyles(claimButtonStyles)}" onClick="(function(){
          const left = window.screenX + (window.outerWidth - 500) / 2;
          const top = window.screenY + (window.outerHeight - 800) / 2.5;
          window.open('${campaignUrl}', '_blank', 'width=500px, height=800px' + ', top=' + top + ', left=' + left);
      })();return false;">
          Claim
        </button>
      </div>
      <div style="${objectToInlineStyles(containerStyles)}">
      <img src="${window.location.origin}/logo/logo_full.svg" alt="logo" style="${objectToInlineStyles(logoStyles)}" />
      </div>
    </div>
    <img src="${window.location.origin}/assets/background/banner.png" alt="banner img" style="${objectToInlineStyles(
  backgroundImgStyles
)}" />
  </div>
`

export default getBanner
