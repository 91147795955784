import React, { createContext, useState } from 'react'
import loadable from '@loadable/component'
import { CommunityWithTraits } from '@/modules/NFTCampaignWizard'

const TraitsModal = loadable(() => import('@/components/Trait/TraitsModal'))

interface OpenTraitsModalProps {
  community: CommunityWithTraits
}

interface ICommunityTraitsModalContext {
  open: (props: OpenTraitsModalProps) => void
  close: () => void
}

export const CommunityTraitsModalContext = createContext({} as ICommunityTraitsModalContext)

const CommunityTraitsModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [props, setProps] = useState<OpenTraitsModalProps | null>(null)

  const close = () => setProps(null)

  return (
    <CommunityTraitsModalContext.Provider value={{ open: setProps, close }}>
      {children}
      {props && <TraitsModal {...props} onClose={close} />}
    </CommunityTraitsModalContext.Provider>
  )
}

export default CommunityTraitsModalProvider
