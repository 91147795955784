import { NFTCommunity, NftCommunityCategory } from '@pangea/types'
import { formatHumanizedCurencyAmount, humanizeNumberFallback } from '@/utils/formats'
import { ExtractedCommunityData } from './types'

export const undefinedValue = '--'
export const unknownCategorySrc = '/assets/icons/components/ic_nft_community_dropdown_category_unknown.svg'

function checkCategory(category: string): NftCommunityCategory | undefined {
  return Object.values(NftCommunityCategory).includes(category as NftCommunityCategory)
    ? (category as NftCommunityCategory)
    : undefined
}

export function extractCommunityData({
  address,
  blockchain,
  tradingData,
  category,
  socialHandles,
  images,
  name,
}: NFTCommunity): ExtractedCommunityData {
  const averagePrice = tradingData?.averagePriceUsd
  const averageValue = averagePrice ? formatHumanizedCurencyAmount(averagePrice) : undefined

  return {
    address: address,
    blockchain,
    name: name,
    imageUrl: images.imageUrl,
    tradingData: {
      category: checkCategory(category),
      averageValue,
      owners: humanizeNumberFallback(tradingData.owners),
    },
    socialHandles: {
      twitter: { followers: humanizeNumberFallback(socialHandles.twitter?.followers) },
      discord: { followers: humanizeNumberFallback(socialHandles.discord?.followers) },
    },
  }
}
