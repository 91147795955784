import { createContext, Dispatch, FC, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react'

import { Project } from '@/types/project'
import { useProjectContext } from '@/hooks'
import { EnrichedPlan } from '../pricing/utils'
import { getStepIndexById } from './utils'

type PartialProject = Partial<Project>
interface OnboardingContextType {
  activeStep: number
  onboardingData: PartialProject
  paymentAdded: boolean
  selectedPlan: EnrichedPlan | null
  setActiveStep: Dispatch<SetStateAction<number>>
  setOnboardingData: Dispatch<SetStateAction<PartialProject>>
  setPaymentAdded: Dispatch<SetStateAction<boolean>>
  setSelectedPlan: Dispatch<SetStateAction<EnrichedPlan | null>>
}

interface OnboardingcontextProps {
  children: ReactNode
}

export const OnboardingContext = createContext<OnboardingContextType | null>(null)

export const OnboardingContextProvider: FC<OnboardingcontextProps> = ({ children }) => {
  const { project } = useProjectContext()!
  const [onboardingData, setOnboardingData] = useState<PartialProject>(project || {})
  const [selectedPlan, setSelectedPlan] = useState<EnrichedPlan | null>(null)
  const [activeStep, setActiveStep] = useState(getStepIndexById())
  const [paymentAdded, setPaymentAdded] = useState(false)

  const memoizedValue = useMemo(
    () => ({
      activeStep,
      onboardingData,
      paymentAdded,
      selectedPlan,
      setActiveStep,
      setOnboardingData,
      setPaymentAdded,
      setSelectedPlan,
    }),
    [activeStep, onboardingData, paymentAdded]
  )

  useEffect(() => {
    setActiveStep(getStepIndexById(onboardingData?.onboardingStatus))
  }, [onboardingData?.onboardingStatus])

  const memoizedChildren = useMemo(() => children, [children])

  return <OnboardingContext.Provider value={memoizedValue}>{memoizedChildren}</OnboardingContext.Provider>
}
