export interface Token<T> {
  type: 'auth_token_user' | 'auth_token_customer';
  properties: T;
  iat: number;
  ext: number;
}

export enum SessionEvent {
  REMOVE = 'session:remove',
  UPDATE = 'session:update',
}
