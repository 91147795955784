import { Box, Grid } from '@mui/material'
import { useNavigate } from 'react-router'
import { useQuery } from 'react-query'
import { IntegrationType } from '@pangea/types'
import { Iconify, LoadingScreen, Text } from '@/components'
import CampaignTemplates from '@/components/NFTCampaign/CampaignTemplates'
import { TemplateService } from '@/api'
import GenericError from '@/components/GenericError'

const NFTCampaignTemplates = () => {
  const navigate = useNavigate()
  const { data, isLoading, isError } = useQuery('templates', () => TemplateService.getAll())

  if (isLoading) return <LoadingScreen />
  if (isError || !data) return <GenericError />

  const internalSecretIntegrationTemplates =
    data.data.filter((t) => t.integrationType === IntegrationType.INTERNAL) || []
  const mindbodyIntegrationTemplates = data.data.filter((t) => t.integrationType === IntegrationType.MINDBODY) || []
  const squareIntegrationTemplates = data.data.filter((t) => t.integrationType === IntegrationType.SQUARE) || []
  const shopifyIntegrationTemplates = data.data.filter((t) => t.integrationType === IntegrationType.SHOPIFY) || []
  const stripeItegrationTemplates = data.data.filter((t) => t.integrationType === IntegrationType.STRIPE) || []
  const lightspeedIntegrationTemplates = data.data.filter((t) => t.integrationType === IntegrationType.LIGHTSPEED) || []
  const wixIntegrationTemplates = data.data.filter((t) => t.integrationType === IntegrationType.WIX) || []
  const bigCommerceIntegrationTemplates =
    data.data.filter((t) => t.integrationType === IntegrationType.BIGCOMMERCE) || []

  return (
    <Box width="100%" height="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height={88}
        borderBottom="1px solid rgba(145, 158, 171, 0.32)"
        px={6}
      >
        <Box display="flex" alignItems="center">
          <Iconify icon="ic:round-chevron-left" sx={{ width: 28, height: 28 }} onClick={() => navigate(-1)} />
          <Text variant="h6" ml={2}>
            Select a campaign template
          </Text>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Grid container item lg={8} flexDirection="column" justifyContent="space-between" mt={10}>
          <CampaignTemplates templates={internalSecretIntegrationTemplates} mb={6} />
          <CampaignTemplates templates={mindbodyIntegrationTemplates} mb={6} />
          <CampaignTemplates templates={squareIntegrationTemplates} mb={6} />
          {shopifyIntegrationTemplates.length ? (
            <CampaignTemplates templates={shopifyIntegrationTemplates} mb={6} />
          ) : null}
          {stripeItegrationTemplates.length ? <CampaignTemplates templates={stripeItegrationTemplates} mb={6} /> : null}
          {lightspeedIntegrationTemplates.length ? (
            <CampaignTemplates templates={lightspeedIntegrationTemplates} mb={6} />
          ) : null}
          {wixIntegrationTemplates.length ? <CampaignTemplates templates={wixIntegrationTemplates} mb={6} /> : null}
          {bigCommerceIntegrationTemplates.length ? (
            <CampaignTemplates templates={bigCommerceIntegrationTemplates} />
          ) : null}
        </Grid>
      </Box>
    </Box>
  )
}

export default NFTCampaignTemplates
