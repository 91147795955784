import { FC } from 'react'
import {
  Avatar as MUIAvatar,
  AvatarProps as MUIAvatarProps,
  BadgeProps,
} from '@mui/material'
import Badge from '../Badge/Badge'

export interface AvatarProps extends MUIAvatarProps {
  color?: string
  icon?: React.ReactNode
  size?: string
  badgeProps?: BadgeProps
}

const Avatar: FC<AvatarProps> = ({ badgeProps, ...props }) =>
  badgeProps ? (
    <Badge {...badgeProps}>
      <MUIAvatar {...props} />
    </Badge>
  ) : (
    <MUIAvatar {...props} />
  )

export default Avatar
