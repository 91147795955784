import { CampaignPublishStatus, CampaignStatus } from '@pangea/types'
import Label from '../Label'
import { LabelColor } from '../Label/Label'

type Status = CampaignPublishStatus | CampaignStatus

const statusColor: Record<Status, LabelColor> = {
  publish: 'info',
  draft: 'warning',
  // @ts-ignore
  expired: 'error',
  live: 'success',
  unpublished: 'default',
}

export const CampaignStatusLabel: React.FC<{ status: Status }> = ({ status }) => {
  return (
    <Label color={statusColor[status]} sx={{ textTransform: 'capitalize' }}>
      {status}
    </Label>
  )
}

export default CampaignStatusLabel
