import { Alert, Box, Grid } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { openInNewTab } from '@shared/utils/browser'
import { NftCampaignDetail } from '@pangea/types'
import { Dialog, IconButton, Text } from '@/components'
import copyToClipboard from '@/utils/copyToClipboard'
import { useEnv } from '@/hooks'
import getBanner from '@/components/Snippets/Banner'
import getClaimRewardButton from '@/components/Snippets/ClaimButton'
import Select from '@/components/form/Select'
import CampaignPublishedAction from './CampaignPublishedAction'

interface CampaignPublishedModalProps {
  campaign: NftCampaignDetail
  onClose: () => void
  msg?: string
}

enum TemplateVariant {
  Button = 'Button',
  Banner = 'Banner',
}

const CampaignPublishedModal: React.FC<CampaignPublishedModalProps> = ({ campaign, onClose, msg }) => {
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateVariant>(TemplateVariant.Banner)
  const { REACT_APP_NOTA_URL } = useEnv()
  const campaignPageUrl = `${REACT_APP_NOTA_URL}/campaign/${campaign.id}`
  const claimRewardUrl = `${REACT_APP_NOTA_URL}/campaign/${campaign.id}/claim`

  const buttonSnippet = getClaimRewardButton(claimRewardUrl)
  const bannerSnippet = getBanner({
    ...campaign.rewardDefinition.rewardTemplateData.campaignDesign,
    campaignUrl: claimRewardUrl,
  })
  const selectedSnippet = useMemo(
    () => (selectedTemplate === TemplateVariant.Banner ? bannerSnippet : buttonSnippet),
    [selectedTemplate, bannerSnippet, buttonSnippet]
  )

  const handleViewClick = useCallback(() => {
    openInNewTab(campaignPageUrl)
  }, [campaignPageUrl])

  const handleCopy = useCallback(() => {
    copyToClipboard(selectedSnippet)
  }, [selectedSnippet])

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        sx: {
          p: 0,
          bgcolor: (theme) => theme.palette.grey[900],
        },
      }}
    >
      <Box
        sx={{
          px: 4,
          py: 3,
          background: (theme) => theme.gradients.main,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text variant="h6">{msg || 'Embed your campaign'}</Text>
        <IconButton onClick={onClose} sx={{ color: '#fff', fontSize: 30, p: 0 }}>
          &times;
        </IconButton>
      </Box>
      <Box p={4} pb={7}>
        <Alert severity="info">
          To promote your campaign we prepared templates which you can easily add to your web. Choose between banner or
          button, copy the snippet and include it on your website.
        </Alert>
        <Box my={4}>
          <Text variant="h6" color={(theme) => theme.palette.primary.light}>
            Select your template
          </Text>
          <Select
            sx={{ width: 500, mt: 1 }}
            label=""
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value as TemplateVariant)}
            options={[
              { label: TemplateVariant.Banner, value: TemplateVariant.Banner },
              { label: TemplateVariant.Button, value: TemplateVariant.Button },
            ]}
          />
        </Box>

        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={2} flexDirection="column">
              <Grid item>
                <Text variant="subtitle1">Copy the HTML code snippet to your webpage</Text>
              </Grid>
              <Grid item maxWidth="100%">
                <Box
                  sx={{
                    maxWidth: '100%',
                    background: (theme) => theme.palette.background.paper,
                    height: 220,
                    p: 2,
                    fontFamily: 'monospace',
                    overflow: 'auto',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: (theme) => theme.palette.grey[700],
                  }}
                >
                  {selectedSnippet}
                </Box>
              </Grid>
              <Grid item>
                <CampaignPublishedAction icon="bxs:copy" onClick={handleCopy}>
                  Copy Code
                </CampaignPublishedAction>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container spacing={2} flexDirection="column">
              <Grid item>
                <Text variant="subtitle1">This is what you will see on your website</Text>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    background: (theme) => theme.palette.background.paper,
                    height: 220,
                    py: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: (theme) => theme.palette.grey[700],
                  }}
                  dangerouslySetInnerHTML={{ __html: selectedSnippet }}
                />
              </Grid>
              <Grid item>
                <CampaignPublishedAction icon="mdi:open-in-new" onClick={handleViewClick}>
                  View Live Preview
                </CampaignPublishedAction>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default CampaignPublishedModal
