import { ErrorCodes } from '../api/types';
import { ApiError } from '../api/errors';
import { CampaignTemplateCode } from './reward';
/**
 * **@CampaignType** Enum representing the business cases for templates.
 * These are used to build shared elements, across integrations.
 * @example CampaignType.ITEM_GIVEAWAY
 */
export var CampaignType;
(function (CampaignType) {
    /** The **@CampaignType** for a giveaway campaign biz case */
    CampaignType["ITEM_GIVEAWAY"] = "ITEM_GIVEAWAY";
    /** The **@CampaignType** for a coupon code campaign biz case */
    CampaignType["COUPON_CODE"] = "COUPON_CODE";
    /** The **@CampaignType** for loyalty points campaign biz case */
    CampaignType["LOYALTY_POINTS"] = "LOYALTY_POINTS";
    /** The **@CampaignType** for secret reveal */
    CampaignType["SECRET"] = "SECRET";
})(CampaignType || (CampaignType = {}));
/**
 * **@Web2Sources** Enum representing possible sources of data for the **@Web2DataPoints**.
 * @example Web2Sources.SOCIAL
 */
export var Web2Sources;
(function (Web2Sources) {
    /** The **@Web2Sources** for social media */
    Web2Sources["SOCIAL"] = "SOCIAL";
    /** The **@Web2Sources** for direct user input */
    Web2Sources["DIRECT_INPUT"] = "DIRECT_INPUT";
    /** The **@Web2Sources** which are on the Nota USER model */
    Web2Sources["NOTA_AUTH"] = "AUTH";
})(Web2Sources || (Web2Sources = {}));
export function toCampaignTemplateCode(value) {
    if (Object.values(CampaignTemplateCode).includes(value)) {
        return value;
    }
    throw new ApiError(409, 'Invalid template code', ErrorCodes.INVALID_CONTENT_TYPE);
}
export function toCampaignType(value) {
    if (Object.values(CampaignType).includes(value)) {
        return value;
    }
    throw new ApiError(409, 'Invalid campaign type', ErrorCodes.INVALID_CONTENT_TYPE);
}
export const MINDBODY_REQUIRED_FIELDS = [
    {
        name: 'email',
        sources: [Web2Sources.SOCIAL, Web2Sources.NOTA_AUTH],
    },
    {
        name: 'firstName',
        sources: [Web2Sources.SOCIAL, Web2Sources.DIRECT_INPUT],
    },
    {
        name: 'lastName',
        sources: [Web2Sources.SOCIAL, Web2Sources.DIRECT_INPUT],
    },
];
export const REQUIRED_FIELDS_PHONE_ONLY = [
    {
        name: 'phone',
        sources: [Web2Sources.NOTA_AUTH],
    },
];
export function getCampaignRequirements(campaignTemplateCode) {
    const requirementsMap = {
        [CampaignTemplateCode.MINDBODY_ITEM_GIVEAWAY]: MINDBODY_REQUIRED_FIELDS,
        [CampaignTemplateCode.MINDBODY_COUPON_CODE]: MINDBODY_REQUIRED_FIELDS,
        [CampaignTemplateCode.SQUARE_LOYALTY_POINTS]: REQUIRED_FIELDS_PHONE_ONLY,
        [CampaignTemplateCode.INTERNAL_SECRET]: REQUIRED_FIELDS_PHONE_ONLY,
        [CampaignTemplateCode.SHOPIFY_DISCOUNT_CODE]: REQUIRED_FIELDS_PHONE_ONLY,
        [CampaignTemplateCode.STRIPE_DISCOUNT_CODE]: REQUIRED_FIELDS_PHONE_ONLY,
        [CampaignTemplateCode.LIGHTSPEED_GIFT_CARD]: REQUIRED_FIELDS_PHONE_ONLY,
        [CampaignTemplateCode.WIX_DISCOUNT_CODE]: REQUIRED_FIELDS_PHONE_ONLY,
        [CampaignTemplateCode.BIGCOMMERCE_DISCOUNT_CODE]: REQUIRED_FIELDS_PHONE_ONLY,
    };
    return requirementsMap[campaignTemplateCode] || [];
}
