// @mui
import { Stack, DialogTitle, DialogContent } from '@mui/material'
// components
import { Progress, Dialog, Text } from '@/components'

// ----------------------------------------------------------------------
interface Props {
  open: boolean
  title: string
}

export default function PaymentProcessingDialog({ open, title }: Props) {
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ overflow: 'unset' }}>
          <Stack spacing={3}>
            <Progress />
            <Text color="grey.500">This may take a few moments</Text>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}
