import { Box, BoxProps } from '@mui/material'
import { Text, Iconify } from '@/components'

interface SocialConnectionProps extends BoxProps {
  icon: string
  value: string
}

const SocialConnection: React.FC<SocialConnectionProps> = ({ icon, value }) => {
  return (
    <Box display="flex" alignItems="center" mr={2}>
      <Iconify icon={icon} width={16} />
      <Text variant="caption" color={(theme) => theme.palette.grey[500]} ml={0.5}>
        {value}
      </Text>
    </Box>
  )
}

export default SocialConnection
