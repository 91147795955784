import { Box, BoxProps } from '@mui/material'

const containerSize = {
  medium: {
    borderRadius: 1,
    px: 2,
    py: 1.25,
  },
  large: {
    borderRadius: 2,
    px: 5,
    py: 3,
  },
}

interface ContainerProps extends BoxProps {
  size?: 'medium' | 'large'
}

const Container: React.FC<ContainerProps> = ({ size = 'large', ...boxProps }) => (
  <Box
    {...containerSize[size]}
    {...boxProps}
    sx={{
      bgcolor: 'background.neutral',
      ...boxProps.sx,
    }}
  />
)

export default Container
