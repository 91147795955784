import { ErrorCodes } from '../api/types';
import { ApiError } from '../api/errors';
/**
 * **@IntegrationStatus** Enum representing the possible statuses of an integration.
 * @example IntegrationStatus.ACTIVE
 */
export var IntegrationStatus;
(function (IntegrationStatus) {
    /** The **@IntegrationStatus** for an up-to-date and functional integration */
    IntegrationStatus["ACTIVE"] = "ACTIVE";
    /** The **@IntegrationStatus** for a disabled integration */
    IntegrationStatus["INACTIVE"] = "INACTIVE";
    /** The **@IntegrationStatus** for an integration that is pending additional configuration to be functional */
    IntegrationStatus["PENDING"] = "PENDING";
})(IntegrationStatus || (IntegrationStatus = {}));
/**
 * **@IntegrationType** Enum representing the possible integration types.
 * @example IntegrationType.MINDBODY
 */
export var IntegrationType;
(function (IntegrationType) {
    /** The **@IntegrationType** for MindBody */
    IntegrationType["MINDBODY"] = "MINDBODY";
    /** The **@IntegrationType** for Square */
    IntegrationType["SQUARE"] = "SQUARE";
    /** The **@IntegrationType** for Internal */
    IntegrationType["INTERNAL"] = "INTERNAL";
    /** The **@IntegrationType** for Shopify */
    IntegrationType["SHOPIFY"] = "SHOPIFY";
    /** The **@IntegrationType** for Stripe */
    IntegrationType["STRIPE"] = "STRIPE";
    /** The **@IntegrationType** for Lightspeed */
    IntegrationType["LIGHTSPEED"] = "LIGHTSPEED";
    /** The **@IntegrationType** for Lightspeed */
    IntegrationType["WIX"] = "WIX";
    /** The **@IntegrationType** for BigCommerce */
    IntegrationType["BIGCOMMERCE"] = "BIGCOMMERCE";
})(IntegrationType || (IntegrationType = {}));
export function mapIntegrationData(integration) {
    if (integration) {
        return {
            id: integration.id,
            projectId: integration.projectId,
            integrationType: toIntegrationType(integration.integrationType),
            status: toIntegrationStatus(integration.status),
            integrationData: integration.integrationData,
            createdAt: integration.createdAt,
            updatedAt: integration.updatedAt || integration.createdAt,
        };
    }
    return null;
}
export function toIntegrationType(value) {
    if (value && Object.values(IntegrationType).includes(value)) {
        return value;
    }
    throw new ApiError(409, 'Invalid Integration type', ErrorCodes.INVALID_CONTENT_TYPE);
}
export function toIntegrationStatus(value) {
    if (value && Object.values(IntegrationStatus).includes(value)) {
        return value;
    }
    throw new ApiError(409, 'Invalid Integration status', ErrorCodes.INVALID_CONTENT_TYPE);
}
