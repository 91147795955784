import { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'
import loadable from '@loadable/component'
import { INFTCommunityDetailsDialogProps } from '@/components/NFTCommunityDetailsDialog/NFTCommunityDetailsDialog'

const NFTCommunityDetailsDialog = loadable(() => import('@/components/NFTCommunityDetailsDialog'))

type OpenDetailsModalProps = Omit<INFTCommunityDetailsDialogProps, 'open' | 'onClose'>

interface ICommunityDetailsModalContext {
  open: (props: OpenDetailsModalProps) => void
  close: () => void
}

export const CommunityDetailsModalContext = createContext({} as ICommunityDetailsModalContext)

const CommunityDetailsModalProvider = () => {
  const [props, setProps] = useState<OpenDetailsModalProps | null>(null)

  const close = () => setProps(null)

  return (
    <CommunityDetailsModalContext.Provider value={{ open: setProps, close }}>
      <Outlet />

      {props && <NFTCommunityDetailsDialog {...props} open onClose={close} />}
    </CommunityDetailsModalContext.Provider>
  )
}

export default CommunityDetailsModalProvider
