import { FC } from 'react'
import { Tabs as MUITabs, Tab as MUITab, TabsProps as MUITabsProps, TabProps } from '@mui/material'

interface TabsProps extends MUITabsProps {
  tabs: TabProps[]
}

const Tabs: FC<TabsProps> = ({ tabs, ...props }) => {
  return (
    <MUITabs {...props}>
      {tabs.map((tabProps, index) => (
        <MUITab key={index} {...{ ...tabProps, label: tabProps.label || tabProps.value }} />
      ))}
    </MUITabs>
  )
}

export default Tabs
