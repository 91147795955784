import React, { startTransition, useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { Elements } from '@stripe/react-stripe-js'
import { Stripe, loadStripe } from '@stripe/stripe-js'
import { PangeaPlanType, ProjectOnboardingStatus } from '@pangea/types'
import BillingService from '@/api/BillingService'
import useOnboarding from '@/hooks/useOnboarding'
import { Loader } from '@/components'
import { OnboardingStep, OnboardingStepFooter } from '@/components/onboarding'
import { PaymentContainer, LicensePaymentContainer } from '@/components/pricing/Payment'
import { PaymentAdded, PricingPlanCard } from '@/components/pricing'
import {
  EnrichedPlan,
  enrichData,
  isAnnualBilling,
  isMonthlyBilling,
  getPricing,
  getAdditionalCosts,
  links,
  isFreePlan,
} from '@/components/pricing/utils'
import { ProjectService } from '@/api'
import { useProject } from '@/hooks'
import { AdditionalCosts, BillingPeriodToggle } from '@/components/pricing/Payment/components'

const DefaultStripePk =
  'pk_test_51MwmcjCkxUByGvqQMrJv5qzhVoPrG7KLr7iVXHfDhlusVSZA6Z5OvoVDj3g9PZd1Dh9SBYV5NE9sANOtAhSo9ynW00T4zy5QfE'

export enum BillingTypeEnum {
  MONTHLY,
  ANNUALLY,
}

const SelectPlan: React.FC = () => {
  const [billingType, setBillingType] = useState<BillingTypeEnum>(BillingTypeEnum.ANNUALLY)
  const [stripePublishKey, setStripePublishKey] = useState<string>('')
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)
  const [billingPlan, setBillingPlan] = useState<string>('')
  const { paymentAdded, setPaymentAdded, selectedPlan, setSelectedPlan, setOnboardingData } = useOnboarding()
  const { project } = useProject()
  const updateProjectMutation = useMutation(ProjectService.updateProject, {
    onSuccess: (data) => setOnboardingData({ ...onboardingData, ...data }),
  })

  useEffect(() => {
    if (!stripePublishKey) {
      return
    }

    setStripePromise(loadStripe(stripePublishKey))
  }, [stripePublishKey])

  const { setActiveStep, onboardingData } = useOnboarding()

  const { isLoading, error, data } = useQuery('billingData', BillingService.getPlans, {
    onSuccess: (res) => {
      setStripePublishKey(res?.stripePk || DefaultStripePk)
    },
  })

  const projectId = onboardingData.id

  const handlePlanSelect = (planId: string) => {
    const selectedPlan = activePlans.find((p) => p.id === planId)

    if (selectedPlan?.type === PangeaPlanType.FREE) {
      return updateProjectMutation.mutate({
        id: project!.id,
        // ...onboardingData,
        onboardingStatus: ProjectOnboardingStatus.START_JOURNEY,
      })
    }

    setBillingPlan(planId)
    setSelectedPlan(selectedPlan || null)
  }

  const filterFn = billingType === BillingTypeEnum.MONTHLY ? isMonthlyBilling : isAnnualBilling

  const plans = data?.plans || []
  const activePlans: EnrichedPlan[] = plans.map(enrichData(getPricing(plans)))

  if (isLoading || !activePlans) return <Loader height="100%" />

  if (error) return <div>An error has occurred:</div>

  const activePlan = activePlans.find((x) => x.id === billingPlan)
  const isFreePlanSelected = activePlan?.type === PangeaPlanType.FREE

  const additionalCosts = getAdditionalCosts(plans)

  const handleBackStep = () => {
    startTransition(() => {
      setActiveStep((s) => s - 1)
    })
  }

  const handleNextStepWithoutPayment = () => {
    startTransition(() => {
      setActiveStep((s) => s + 1)
    })
  }

  const handleNextStep = () => {
    startTransition(() => {
      setPaymentAdded(true)
      setActiveStep((s) => s + 1)
    })
  }
  const title = paymentAdded
    ? selectedPlan && isFreePlan(selectedPlan?.type)
      ? 'Payment method stored'
      : 'Pangea plan purchased!'
    : billingPlan
    ? 'Select Your Payment method'
    : 'Flexible plans tailored to your size and needs'

  const isPaymentContainerVisible = activePlan && isFreePlanSelected && projectId
  const isLicensePaymentContainerVisible = activePlan && !isFreePlanSelected && projectId

  const backButtonProps = {
    onClick: () =>
      setOnboardingData({
        ...onboardingData,
        onboardingStatus: ProjectOnboardingStatus.SETUP_WORKSPACE,
      }),
  }

  return (
    <OnboardingStep title={title} description="">
      <Stack display="flex" flexDirection="column" flex={1} height="100%">
        {selectedPlan && paymentAdded && (
          <PaymentAdded
            onNextStep={handleNextStepWithoutPayment}
            onBackStep={handleBackStep}
            selectedPlan={selectedPlan}
          />
        )}
        {!paymentAdded && !billingPlan && (
          <Box display="flex" flexDirection="column" flex={1}>
            <Box sx={{ my: 1 }}>
              <BillingPeriodToggle billingPeriod={billingType} setBillingPeriod={setBillingType} />
            </Box>
            <Box gap={3} display="grid" gridTemplateColumns={{ md: 'repeat(3, 1fr)' }}>
              {activePlans
                .filter((bp) => filterFn(bp.type) || bp.type === PangeaPlanType.FREE)
                .map((card: any, index: number) => (
                  <PricingPlanCard key={card.id} card={card} index={index} onPlanSelect={handlePlanSelect} />
                ))}
            </Box>
            {!!additionalCosts && <AdditionalCosts additionalCosts={additionalCosts} links={links} />}
          </Box>
        )}
        {!paymentAdded && !!stripePromise && (
          <Elements stripe={stripePromise}>
            {isPaymentContainerVisible && (
              <PaymentContainer
                projectId={projectId}
                onAddLaterClick={handleNextStepWithoutPayment}
                onPaymentMethodAdded={handleNextStep}
              />
            )}
            {isLicensePaymentContainerVisible && (
              <LicensePaymentContainer
                onChangePlan={() => {
                  setBillingPlan('')
                }}
                projectId={projectId}
                selectedPlan={activePlan}
                onSuccess={handleNextStep}
                toggleBillingPeriod={(type) => {
                  const currentPlanType = activePlan?.type
                  const newTypeName = type === BillingTypeEnum.ANNUALLY ? 'ANNUAL' : 'MONTHLY'
                  const newPlanType = currentPlanType.split('_')[0] + '_' + newTypeName
                  const selectedPlan = activePlans.find((p) => p.type === newPlanType)

                  if (selectedPlan) {
                    setBillingPlan(selectedPlan.id)
                    setBillingType(type)
                  }
                }}
              />
            )}
          </Elements>
        )}
        {!isLicensePaymentContainerVisible && <OnboardingStepFooter backButtonProps={backButtonProps} />}
      </Stack>
    </OnboardingStep>
  )
}

export default SelectPlan
