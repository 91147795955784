import { Box } from '@mui/material'
import { NFTCommunity } from '@pangea/types'
import { Image, Text } from '@/components'
import { formatHumanizedCurencyAmount, humanizeNumber } from '@/utils/formats'
import { useCommunityDetailsModal } from '@/hooks'

interface CollectableCardProps {
  collectable: NFTCommunity
}

const CollectableCard: React.FC<CollectableCardProps> = ({ collectable }) => {
  const { open } = useCommunityDetailsModal()

  return (
    <Box
      display="inline-block"
      width={254}
      height={254}
      p={2}
      bgcolor="background.paper"
      borderRadius={1}
      mr={2}
      onClick={() => open({ community: collectable })}
      sx={{
        cursor: 'pointer',
        transition: 'background-color 0.2s ease-in-out',
        '&:hover': {
          bgcolor: 'background.neutral',
        },
      }}
    >
      <Image
        src={collectable.images.imageUrl}
        alt="community img"
        style={{ borderRadius: 8, height: 120 }}
        mb={2}
        onDragStart={(e) => e.preventDefault()}
      />
      <Text mb={1} overflow="hidden" textOverflow="ellipsis">
        {collectable.name}
      </Text>
      <Box display="flex">
        <Box mr={2}>
          <div>
            <Text variant="body2" color={(theme) => theme.palette.grey[500]}>
              Avg. value
            </Text>
          </div>
          <Text variant="subtitle2">{formatHumanizedCurencyAmount(collectable.tradingData.averagePriceUsd)}</Text>
        </Box>
        <Box>
          <div>
            <Text variant="body2" color={(theme) => theme.palette.grey[500]}>
              Unique owners
            </Text>
          </div>
          <Text variant="subtitle2">{humanizeNumber(collectable.tradingData.owners)}</Text>
        </Box>
      </Box>
    </Box>
  )
}

export default CollectableCard
