import moment from 'moment'
import { CampaignTemplateCode, castRewardDefinitionData, NftCampaignDetail } from '@pangea/types'
import { FormValues } from '@/modules/NFTCampaignWizard'

const getInitialValues = (data: NftCampaignDetail): FormValues => {
  let rewardDefinitionDataId = undefined

  //todo: change this for each template code (the initial values)
  if (
    CampaignTemplateCode.MINDBODY_COUPON_CODE === data.template.templateCode ||
    CampaignTemplateCode.MINDBODY_ITEM_GIVEAWAY === data.template.templateCode
  ) {
    const rewardDefinitionData = castRewardDefinitionData<typeof data.template.templateCode>(
      data.rewardDefinition.rewardDefinitionData
    )
    rewardDefinitionDataId = rewardDefinitionData.id
  }

  return {
    name: data.name || '',
    // @ts-ignore
    communities: data.nftCommunities.map((c) => ({
      ...c,
      requiredTraits: data.nftRules.rules.find((r) => r.smartContractId === c.address)?.requiredTraits || [],
      count: data.nftRules.rules.find((r) => r.smartContractId === c.address)?.count || 1,
    })),
    startDate: moment(data.startDate),
    endDate: moment(data.endDate),
    maxClaims: data.maxClaims,
    trackNftUsed: data.trackNftUsed,
    soulBound: data.soulBound,
    campaignTitle: data.rewardDefinition.rewardTemplateData.campaignDesign.title,
    campaignDescription: data.rewardDefinition.rewardTemplateData.campaignDesign.description,
    rewardTitle: data.rewardDefinition.rewardTemplateData.rewardDesign.title,
    rewardDescription: data.rewardDefinition.rewardTemplateData.rewardDesign.description,
    rewardDefinitionDataId: rewardDefinitionDataId,
    fixedAmount:
      'valueInUsd' in data.rewardDefinition.rewardDefinitionData
        ? data.rewardDefinition.rewardDefinitionData.valueInUsd
        : undefined,
    percentageAmount:
      'percentage' in data.rewardDefinition.rewardDefinitionData
        ? data.rewardDefinition.rewardDefinitionData.percentage
        : undefined,
    couponId:
      'couponId' in data.rewardDefinition.rewardDefinitionData
        ? data.rewardDefinition.rewardDefinitionData.couponId
        : '',
    type: data.nftRules.type,
    reward:
      'reward' in data.rewardDefinition.rewardDefinitionData
        ? data.rewardDefinition.rewardDefinitionData.reward
        : undefined,
  }
}

export default getInitialValues
