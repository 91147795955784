import useProjectContext from './useProjectContext'

const useProject = () => {
  const project = useProjectContext()
  if (!project) {
    throw new Error('Project not defined')
  }
  return project
}

export default useProject
