import { CampaignTemplateCode } from '@pangea/types'
import { Box, BoxProps } from '@mui/material'
import Iconify from '@/components/Iconify/Iconify'

const templateIcons: Record<CampaignTemplateCode, string> = {
  [CampaignTemplateCode.MINDBODY_ITEM_GIVEAWAY]: 'material-symbols:trophy',
  [CampaignTemplateCode.MINDBODY_COUPON_CODE]: 'mdi:coupon',
  [CampaignTemplateCode.SQUARE_LOYALTY_POINTS]: 'ic:round-star',
  [CampaignTemplateCode.INTERNAL_SECRET]: 'mdi:coupon',
  [CampaignTemplateCode.SHOPIFY_DISCOUNT_CODE]: 'ic:baseline-shopify',
  [CampaignTemplateCode.STRIPE_DISCOUNT_CODE]: 'fa-brands:stripe',
  [CampaignTemplateCode.LIGHTSPEED_GIFT_CARD]: 'mdi:coupon',
  [CampaignTemplateCode.WIX_DISCOUNT_CODE]: 'mdi:coupon',
  [CampaignTemplateCode.BIGCOMMERCE_DISCOUNT_CODE]: 'simple-icons:bigcommerce',
}

interface TemplateTypeIconProps extends Omit<BoxProps, 'width' | 'height'> {
  type: CampaignTemplateCode
  size: number
}

const TemplateTypeIcon: React.FC<TemplateTypeIconProps> = ({ type, size, ...boxProps }) => {
  return (
    <Box
      sx={{ background: 'linear-gradient(102.58deg, #4776E6 4.35%, #5D26C1 64.71%)' }}
      minWidth={size}
      minHeight={size}
      borderRadius={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...boxProps}
    >
      <Iconify icon={templateIcons[type]} width={size / 1.5} />
    </Box>
  )
}

export default TemplateTypeIcon
