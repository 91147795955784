import { FC } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { Avatar, Text, BlockchainIcon } from '@/components'
import { NFTCommunityDropdownItemProps } from '../types'
import { extractCommunityData } from '../utils'
import TradingData from './TradingData'

const NFTCommunityDropdownItemSimple: FC<NFTCommunityDropdownItemProps> = ({ community, ...props }) => {
  const { blockchain, imageUrl, name, tradingData } = extractCommunityData(community)

  return (
    <li {...props}>
      <Grid container>
        <Grid item xs={7}>
          <Stack direction="row" alignItems="center" gap={2} height="100%">
            <Avatar size="tiny" src={imageUrl} variant="rounded" />

            <Box>
              <Text variant="subtitle1">{name}</Text>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Stack direction="row" gap={2} alignItems="center" justifyContent="end">
            <BlockchainIcon blockchain={blockchain} />
            <TradingData isAdvanced={false} title="Avg. Value" body={tradingData?.averageValue} />
          </Stack>
        </Grid>
      </Grid>
    </li>
  )
}

export default NFTCommunityDropdownItemSimple
