interface Gradients {
  main: string
  cosmicPull: string
}

declare module '@mui/material/styles' {
  interface Theme {
    gradients: Gradients
  }
  interface ThemeOptions {
    gradients?: Gradients
  }
}

const customGradients = {
  main: 'linear-gradient(102.58deg, #4776E6 4.35%, #5D26C1 64.71%)',
  cosmicPull:
    // eslint-disable-next-line max-len
    'conic-gradient(from 3.63deg at 48.88% 50%, #8B7DFC -28.12deg, #92C0F7 44.14deg, #A96CFF 60deg, #92C0F7 217.75deg, #885CFF 241.88deg, #885CFF 256.72deg, #8B7DFC 331.88deg, #92C0F7 404.14deg)',
}

export default customGradients
