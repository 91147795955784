// @mui
import { Switch, Divider, Typography, Stack, Box, BoxProps } from '@mui/material'
// components
import { Label, Button } from '@/components'
import { Pricing, links } from '@/components/pricing/utils'
import { formatCurrencyAmount } from '@/utils/formats'

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  isMonthly: boolean
  priceInterval: string
  subscription: string
  purchaseAllowed: boolean
  onChangePlan: () => void
  pricing: Pricing
  toggleSubscriptionType: (val: boolean) => void
}

export default function PaymentSummary({
  purchaseAllowed = true,
  subscription,
  pricing,
  onChangePlan,
  priceInterval,
  isMonthly,
  sx,
  toggleSubscriptionType,
  ...other
}: Props) {
  return (
    <Box
      sx={{
        p: 5,
        borderRadius: 2,
        bgcolor: 'background.neutral',
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h6" sx={{ mb: 5 }}>
        Summary
      </Typography>

      <Stack spacing={2.5}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Subscription
          </Typography>

          <Label color="error">{subscription}</Label>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Billed {`${isMonthly ? 'Monthly' : 'Yearly'}`}
          </Typography>
          <Switch checked={!isMonthly} onChange={(e, val) => toggleSubscriptionType(val)} />
        </Stack>

        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <Typography variant="h5">$</Typography>

          <Typography variant="h2">{pricing.monthly}</Typography>

          <Typography component="span" sx={{ mb: 1, alignSelf: 'center', color: 'text.secondary' }}>
            /mo
          </Typography>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Total Billed</Typography>

          <Typography variant="h6">
            {' '}
            {formatCurrencyAmount(isMonthly ? pricing?.monthly || 0 : pricing?.yearly || 0)}
          </Typography>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
      </Stack>

      {/* <Typography component="div" variant="caption" sx={{ color: 'text.secondary', mt: 1 }}>
        * Plus applicable taxes
      </Typography> */}

      <Button disabled={!purchaseAllowed} fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }}>
        Purchase
      </Button>

      <Stack alignItems="center" spacing={1}>
        <Button sx={{ color: 'grey.500' }} variant="text" color="inherit" onClick={onChangePlan}>
          Select other plan
        </Button>

        <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'center' }}>
          By completing this purchase, you confirm that you have read, understood, and agree to our{' '}
          <a target="_blank" rel="noreferrer" href={links.terms}>
            Terms and Conditions
          </a>
          . Please take a moment to review them before proceeding with your transaction.
        </Typography>
      </Stack>
    </Box>
  )
}
