import { Controller, useFormContext } from 'react-hook-form'
import { NftCampaignDetail, StripeGetDiscountsResponse } from '@pangea/types'
import { Box, Link } from '@mui/material'
import { useQuery } from 'react-query'
import { useCallback, useState } from 'react'
import { Button, Error, Iconify, Loader, Text, Autocomplete, TextField } from '@/components'
import { StripeService } from '@/api'
import Container from '../../Container'

interface StripeRewardDefinitionProps {
  campaign: NftCampaignDetail
}

const StripeRewardDefinition: React.FC<StripeRewardDefinitionProps> = ({ campaign }) => {
  const { control } = useFormContext()
  const { data, isFetching, isError, refetch } = useQuery(
    ['stripe-coupons', campaign.projectId, campaign.integration?.id],
    () => StripeService.getCoupons(campaign.projectId, campaign.integration!.id)
  )

  const getSelectedCoupon = useCallback((campaign: NftCampaignDetail, data?: StripeGetDiscountsResponse) => {
    const rewardData = campaign.rewardDefinition.rewardDefinitionData

    if ('couponId' in rewardData && data) {
      const targetCouponId = rewardData.couponId
      const coupon = data.coupons.find((c) => c.id === targetCouponId)

      if (!coupon) return undefined

      return {
        label: coupon.name,
        value: coupon.id,
      }
    }

    return undefined
  }, [])

  const [selectedCoupon, setSelectedCoupon] = useState(getSelectedCoupon(campaign, data))

  return (
    <>
      <Container mt={3}>
        <Text variant="h5" mb={3}>
          Reward Definition
        </Text>
        {isFetching ? (
          <Loader />
        ) : isError || !data ? (
          <Error msg="Failed to load coupons" />
        ) : (
          <Box>
            <Box display="flex">
              <Controller
                name="couponId"
                control={control}
                defaultValue={
                  'couponId' in campaign.rewardDefinition.rewardDefinitionData
                    ? campaign.rewardDefinition.rewardDefinitionData.couponId
                    : undefined
                }
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    fullWidth
                    defaultValue={getSelectedCoupon(campaign, data)}
                    value={selectedCoupon}
                    onChange={(_, val) => {
                      if (val && typeof val !== 'string' && 'value' in val) {
                        setSelectedCoupon(val)
                        onChange(val.value)
                      }
                    }}
                    options={data.coupons.map((coupon) => ({ label: coupon.name, value: coupon.id }))}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
                    renderInput={(params) => (
                      <TextField {...params} label="Select your coupon" variant="outlined" fullWidth />
                    )}
                    sx={{ mr: 2 }}
                  />
                )}
              />

              <Button secondary startIcon={<Iconify icon="ion:reload" />} sx={{ px: 4 }} onClick={() => refetch()}>
                Refresh
              </Button>
            </Box>
            <Text variant="caption">
              You can manage your coupons{' '}
              <Link href="https://dashboard.stripe.com/coupons" target="_blank">
                here
              </Link>
              .
            </Text>
          </Box>
        )}
      </Container>
    </>
  )
}

export default StripeRewardDefinition
