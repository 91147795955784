import { LightspeedOAuthAuthorizeResponse } from '@pangea/types'
import { AuthenticatedClient } from '.'

const authorize = async (projectId: string) => {
  const url = `/project/${projectId}/integrations/lightspeed/authorize`
  const res = await AuthenticatedClient.get<LightspeedOAuthAuthorizeResponse>(url)

  return res.data
}

const LightspeedService = {
  authorize,
}

export default LightspeedService
