import { createContext, useState } from 'react'
import { IntegrationType } from '@pangea/types'
import { Outlet } from 'react-router-dom'
import loadable from '@loadable/component'

const MindbodyIntegrationModal = loadable(() => import('@/components/integration/MindbodyIntegrationModal'))
const SquareIntegrationModal = loadable(() => import('@/components/integration/SquareIntegrationModal'))
const ShopifyIntegrationModal = loadable(() => import('@/components/integration/ShopifyIntegrationModal'))
const StripeIntegrationModal = loadable(() => import('@/components/integration/StripeIntegrationModal'))
const LightspeedIntegrationModal = loadable(() => import('@/components/integration/LightspeedIntegrationModal'))
const WixIntegrationModal = loadable(() => import('@/components/integration/WixIntegrationModal'))
const BigCommerceIntegrationModal = loadable(() => import('@/components/integration/BigCommerceIntegrationModal'))

export interface IntegrationEvents {
  onSuccess: () => void
  onError: () => void
}

interface IIntegrationModalContext {
  open: (type: IntegrationType, integrationEvents: IntegrationEvents) => void
  close: () => void
}

export const IntegrationModalContext = createContext({} as IIntegrationModalContext)

const IntegrationModalProvider = () => {
  const [type, setType] = useState<IntegrationType>()
  const [integrationEvents, setIntegrationEvents] = useState<IntegrationEvents>()

  const open = (type: IntegrationType, integrationEvents: IntegrationEvents) => {
    setType(type)
    setIntegrationEvents(integrationEvents)
  }

  const close = () => {
    setType(undefined)
    setIntegrationEvents(undefined)
  }

  return (
    <IntegrationModalContext.Provider value={{ open, close }}>
      <Outlet />

      {type === IntegrationType.MINDBODY && <MindbodyIntegrationModal onClose={close} {...integrationEvents} />}
      {type === IntegrationType.SQUARE && <SquareIntegrationModal onClose={close} {...integrationEvents} />}
      {type === IntegrationType.SHOPIFY && <ShopifyIntegrationModal onClose={close} {...integrationEvents} />}
      {type === IntegrationType.STRIPE && <StripeIntegrationModal onClose={close} {...integrationEvents} />}
      {type === IntegrationType.LIGHTSPEED && <LightspeedIntegrationModal onClose={close} {...integrationEvents} />}
      {type === IntegrationType.WIX && <WixIntegrationModal onClose={close} {...integrationEvents} />}
      {type === IntegrationType.BIGCOMMERCE && <BigCommerceIntegrationModal onClose={close} {...integrationEvents} />}
    </IntegrationModalContext.Provider>
  )
}

export default IntegrationModalProvider
