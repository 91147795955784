import { FC } from 'react'
import { Box, Container, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AnimationLayout, Avatar, Button } from '@/components'
import { OnboardingContextProvider } from '@/components/onboarding'
import Text from '@/components/Text'
import { URLS } from '@/utils/routes'

const OnboardingSuccess: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  function onStartExploringButtonClick() {
    navigate(URLS.NFT_CAMPAIGNS)
  }

  function onReadDocumentationButtonClick() {
    if (window) {
      window.open(process.env.REACT_APP_DOCS_URL as string, '_blank')?.focus()
    }
  }

  return (
    <AnimationLayout>
      <OnboardingContextProvider>
        <Box
          sx={{
            display: 'flex',
            minHeight: '100vh',
            justifyContent: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left',
            backgroundColor: '#101010',
            backgroundImage: 'url(/assets/background/onboarding_screen.png)',
          }}
        >
          <Container
            id="onboardingContainer"
            maxWidth={false}
            sx={{
              width: '100%',
              maxWidth: theme.breakpoints.values['xl'],
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  width: '500px',
                  height: '450px',
                  bgcolor: 'background.neutral',
                  alignItems: 'center',
                  borderRadius: 2,
                  padding: 2,
                  justifyContent: 'center',
                }}
              >
                <Box display="flex" flexDirection="column" sx={{ alignItems: 'center', width: '100%' }}>
                  <Avatar sx={{ width: 210, height: 210 }} src="/assets/badge_rocket.png"></Avatar>
                  <Text variant="h5" sx={{ marginTop: '24px' }}>
                    You rock!
                  </Text>
                  <Text variant="subtitle1" sx={{ marginTop: '16px' }} align="center">
                    Now that you are all set up,
                    <br />
                    it's time to start building your campaigns!
                  </Text>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '16px', marginTop: '24px' }}>
                    <Button
                      style={{
                        border: '1px solid rgba(145, 158, 171, 0.32)',
                        color: '#919EAB',
                      }}
                      size="large"
                      variant="outlined"
                      color="secondary"
                      onClick={onReadDocumentationButtonClick}
                    >
                      Read documentation
                    </Button>
                    <Button size="large" onClick={onStartExploringButtonClick}>
                      Start exploring!
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </OnboardingContextProvider>
    </AnimationLayout>
  )
}

export default OnboardingSuccess
