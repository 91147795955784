type DefaultCustomEventPayload = {
  latestUpdate: number;
};

type CustomEventPayload<T> = DefaultCustomEventPayload & T;
export type CustomEvt<T> = CustomEvent<CustomEventPayload<T>>;

export function dispatchCustomEvent<T>(eventName: string, data?: T): void {
  window.dispatchEvent(new CustomEvent(eventName, { detail: { latestUpdate: Date.now(), ...data } }));
}

export function openInNewTab(url: string) {
  window.open(url, '_blank');
}

export const openPopup = (
  url: string,
  width: number,
  height: number,
  target: React.HTMLAttributeAnchorTarget = '_blank'
) => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const popup = window.open(url, target, `width=${width},height=${height},left=${left},top=${top}`);

  return popup;
};

export function generateQueryParams(obj: Record<string, string | number>): string {
  return Object.keys(obj)
    .filter((key) => Boolean(obj[key]))
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
}
