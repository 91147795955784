import { Box, BoxProps } from '@mui/material'
import { NFTCommunity } from '@pangea/types'
import { Button, Text } from '@/components'
import { formatHumanizedCurencyAmount, humanizeNumber } from '@/utils/formats'
import { useCommunityDetailsModal } from '@/hooks'
import Label from '../NFTCommunityDetailsDialog/Label'

interface FeaturedCommunityProps extends BoxProps {
  community: NFTCommunity
}

const FeaturedCommunity: React.FC<FeaturedCommunityProps> = ({ community, ...boxProps }) => {
  const { open } = useCommunityDetailsModal()

  return (
    <Box key={community.address} flexGrow={1} maxWidth={524} borderRadius={1} {...boxProps}>
      <img
        src={community.images.bannerImageUrl}
        alt={`${community.name} poster`}
        style={{
          width: '100%',
          height: 64,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          backgroundColor: 'rgba(145, 158, 171, 0.16)',
          p: 2,
          pt: '6px',
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        }}
      >
        <Box position="relative">
          <img
            src={community.images.imageUrl}
            alt={community.name}
            width={80}
            height={80}
            style={{ marginRight: '1rem', borderRadius: 8, objectFit: 'cover', position: 'absolute', top: -52 }}
          />
          <Text ml="96px" variant="h5">
            {community.name}
          </Text>
        </Box>
        <Box mt={1.5} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Label title="Category">
            <Text fontSize={18} fontWeight={700}>
              {community.category}
            </Text>
          </Label>
          <Label title="Avg. value">
            <Text fontSize={18} fontWeight={700}>
              {formatHumanizedCurencyAmount(community.tradingData.averagePriceUsd)}
            </Text>
          </Label>
          <Label title="Total volume">
            <Text fontSize={18} fontWeight={700}>
              {formatHumanizedCurencyAmount(community.tradingData.totalVolumeUsd)}
            </Text>
          </Label>
          <Label title="Unique owners">
            <Text fontSize={18} fontWeight={700}>
              {humanizeNumber(community.tradingData.owners)}
            </Text>
          </Label>
        </Box>
        <Button
          fullWidth
          sx={{
            mt: 2,
            bgcolor: 'rgba(124, 72, 219, 0.16)',
            color: 'primary.light',
            '&:hover': {
              bgcolor: 'rgba(124, 72, 219, 0.75)',
              color: 'white',
            },
          }}
          onClick={() => open({ community })}
        >
          See details
        </Button>
      </Box>
    </Box>
  )
}

export default FeaturedCommunity
