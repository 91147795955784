/**
 * Returnes humanized version of number
 * @param  number - Target number value
 * @return  Humanized version of number
 */
export function humanizeNumber(number: number): string {
  return Intl.NumberFormat('en', {
    notation: 'compact',
  }).format(number)
}

export function humanizeNumberFallback(number: number | undefined, fallback = '--'): string {
  if (number === undefined) {
    return fallback
  }
  return humanizeNumber(number)
}

/**
 * Formats amount with the currency
 * @param amount
 * @returns amount with currency string
 */
export function formatCurrencyAmount(amount: number | string): string {
  return `$ ${amount}`
}

/**
 * Formats humanized amount with the currency
 * @param amount
 * @returns amount with currency string
 */
export function formatHumanizedCurencyAmount(amount: number): string {
  const humanized = humanizeNumber(amount)
  return formatCurrencyAmount(humanized)
}

export const formatHumanizedByteNumber = (number: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const base = 1024

  if (number === 0) return '0 B'

  const exponent = Math.min(Math.floor(Math.log(number) / Math.log(base)), units.length - 1)
  const value = (number / Math.pow(base, exponent)).toFixed(1)
  const isWholeNumber = value.endsWith('.0')

  return `${isWholeNumber ? value.replace('.0', '') : value}${units[exponent]}`
}

export function formatDate(ts?: number): string | undefined {
  if (!ts) {
    return undefined
  }
  const format = Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'medium' })
  return format.format(new Date(ts))
}

export function formatAsPercentage(num: number) {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num / 100)
}

export const objectToInlineStyles = (styles: Record<string, string>) =>
  Object.entries(styles)
    .map(([key, value]) => `${key.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`)}: ${value};`)
    .join(' ')
