import { FC, useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Card, Container, Typography, useMediaQuery, useTheme, Theme } from '@mui/material'
import { getDocsUrl } from '@shared/utils/urlResolver'
import { ReturnToService } from '@shared/services/ReturnToService'
import { Button, Iconify, Logo } from '@/components'
import { DesktopAuth, MobileAuth } from '@/components/auth'
import { useSession } from '@/hooks'
import { PANGEA_WEB_URL } from '@/constants'

const Auth: FC = () => {
  const theme = useTheme()
  const session = useSession()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const returnToService = useMemo(() => new ReturnToService(), [])

  function inIframe() {
    try {
      return !!window.opener || window.self !== window.top
    } catch (e) {
      return false
    }
  }

  useEffect(() => {
    const returnTo = searchParams.get('return_to')
    if (returnTo) {
      returnToService.setReturnTo(returnTo)
    }

    const token = searchParams.get('authToken')
    if (token) {
      session.setSession(token)
    }

    if (token || session.token) {
      // If we are inside an IFrame, send the token up and let it handle it.
      if (inIframe()) {
        window.parent.postMessage(token || session.token, {
          targetOrigin: getDocsUrl(),
        })
        window.opener.postMessage(token || session.token, {
          targetOrigin: window.location.origin,
        })
        return
      }

      const returnToStr = returnToService.getReturnTo()
      if (returnToStr) {
        returnToService.clearReturnTo()
        navigate(returnToStr)
      } else {
        navigate('/')
      }
    }
  }, [])

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: 'url(/assets/background/login_screen.jpg)',
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          maxWidth: 1440,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        {isMdUp && (
          <Box sx={{ position: 'absolute', top: 80, left: 70 }}>
            <Button
              startIcon={<Iconify icon="ci:chevron-left" />}
              variant="text"
              // LinkComponent={RouterLink}
              href={PANGEA_WEB_URL}
              sx={{
                width: 194,
                height: 48,
                color: theme.palette.grey[500],
              }}
            >
              Back to homepage
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Card
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.48)',
              maxWidth: 666,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingY: 10,
              paddingX: isMdUp ? 10 : 4,
              gap: isMdUp ? 7 : 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 6,
              }}
            >
              <Logo width={isMdUp ? 334 : 'auto'} height="auto" />
              {isMdUp && (
                <Typography variant="h4" align="center" color={theme.palette.grey[500]}>
                  Your bridge to Web3
                </Typography>
              )}
            </Box>
            {isMdUp ? <DesktopAuth /> : <MobileAuth />}
          </Card>
        </Box>
      </Container>
    </Box>
  )
}

export default Auth
