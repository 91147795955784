import { FC, useMemo } from 'react'
import { AvatarGroup as MUIAvatarGroup, AvatarGroupProps as MUIAvatarGroupProps } from '@mui/material'
import { sizeof } from 'stylis'
import { AvatarProps } from './Avatar'
import Avatar from './Avatar'

export type AvatarType = Omit<AvatarProps, 'size' | 'variant'>

interface AvatarGroupProps extends MUIAvatarGroupProps {
  avatars: AvatarType[]
  size?: string | 'medium'
}

const AvatarGroup: FC<AvatarGroupProps> = ({ avatars, size = 'medium', ...props }) => {
  const avtrs = useMemo(() => avatars.map((avatarProps, index) => <Avatar key={index} {...avatarProps} />), [avatars])

  return (
    <MUIAvatarGroup total={sizeof(avatars)} {...props}>
      {avtrs}
    </MUIAvatarGroup>
  )
}

export default AvatarGroup
