import { Box, BoxProps, Skeleton } from '@mui/material'

const InfoBox = () => (
  <Box>
    <Skeleton variant="text" width={75} height={15} />
    <Skeleton variant="text" width={35} height={30} />
  </Box>
)

const FeaturedCommunitySkeleton: React.FC<BoxProps> = (props) => (
  <Box flexGrow={1} maxWidth={524} height={210} borderRadius={1} bgcolor="rgba(145, 158, 171, 0.16)" {...props}>
    <Skeleton variant="rounded" sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} height={64} />
    <Box position="relative">
      <Skeleton variant="rounded" width={80} height={80} sx={{ position: 'absolute', top: -54, left: 16 }} />
      <Skeleton variant="text" sx={{ ml: 13, mt: 1 }} width={200} />
      <Box display="flex" px={2} mt={1.5} justifyContent="space-between">
        <InfoBox />
        <InfoBox />
        <InfoBox />
        <InfoBox />
      </Box>
      <Box px={2} mt={1}>
        <Skeleton variant="rounded" width="100%" height={36} />
      </Box>
    </Box>
  </Box>
)

export default FeaturedCommunitySkeleton
