import { FC } from 'react'
import { Button as MUIButton, ButtonProps as MUIButtonProps } from '@mui/material'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

const secondaryButtonStyles: MUIButtonProps['sx'] = {
  bgcolor: (theme) => theme.palette.grey[700],
  '&:hover': {
    bgcolor: (theme) => theme.palette.grey[600],
    boxShadow: 'none',
  },
}

const secondaryOutlinedButtonStyles: MUIButtonProps['sx'] = {
  color: (theme) => theme.palette.grey[700],
  borderColor: (theme) => theme.palette.grey[700],
  '&:hover': {
    borderColor: 'transparent',
    bgcolor: (theme) => theme.palette.grey[700],
    color: (theme) => theme.palette.text.primary,
  },
}

export interface ButtonProps extends MUIButtonProps {
  loadingProps?: LoadingButtonProps
  secondary?: boolean
}

const getSecondaryButtonStyles = (variant: MUIButtonProps['variant']) => {
  if (variant === 'outlined') return secondaryOutlinedButtonStyles

  return secondaryButtonStyles
}

const Button: FC<ButtonProps> = ({ loadingProps, ...props }) => {
  const secondaryProps = props.secondary ? getSecondaryButtonStyles(props.variant) : {}

  return loadingProps ? (
    <LoadingButton {...props} {...loadingProps} sx={{ ...secondaryProps, ...props.sx }} />
  ) : (
    <MUIButton {...props} sx={{ ...secondaryProps, ...props.sx }} />
  )
}

export default Button
