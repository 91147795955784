import { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'

interface Preferences {
  alerts: {
    traits: boolean
    lookingForCommunity: boolean
  }
}

interface UserPreferencesContextData {
  preferences: Preferences
  changePreferences: (category: keyof Preferences, preferences: Partial<Preferences[keyof Preferences]>) => void
}

const defaultPreferences: Preferences = {
  alerts: {
    traits: true,
    lookingForCommunity: true,
  },
}

export const UserPreferencesContext = createContext({} as UserPreferencesContextData)

const loadPreferences = () => {
  try {
    const preferences = JSON.parse(localStorage.getItem('preferences') || 'null')
    return preferences || defaultPreferences
  } catch (err) {
    return defaultPreferences
  }
}

const UserPreferencesProvider = () => {
  const [preferences, setPreferences] = useState<Preferences>(loadPreferences())

  const changePreferences: UserPreferencesContextData['changePreferences'] = (key, data) => {
    setPreferences((prevPreferences) => {
      const newPreferences = {
        ...prevPreferences,
        [key]: {
          ...prevPreferences[key],
          ...data,
        },
      }
      localStorage.setItem('preferences', JSON.stringify(newPreferences))
      return newPreferences
    })
  }

  return (
    <UserPreferencesContext.Provider value={{ preferences, changePreferences }}>
      <Outlet />
    </UserPreferencesContext.Provider>
  )
}

export default UserPreferencesProvider
