import { FC } from 'react'
// @mui
import { styled } from '@mui/material/styles'
import { Step, Stepper, StepLabel, StepConnector, stepConnectorClasses, SxProps } from '@mui/material'
import { StepIconProps } from '@mui/material/StepIcon'

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderRadius: 1,
    borderTopWidth: 3,
    borderColor: theme.palette.divider,
  },
}))

const QontoStepIconRoot = styled('div')<{
  ownerState: { active?: boolean; completed?: boolean }
}>(({ theme, ownerState }) => ({
  height: 22,
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.disabled,
  ...(ownerState.active && {
    color: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    color: theme.palette.primary.main,
  }),
  '& .QontoStepIcon-completedIcon': {
    zIndex: 1,
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}))

const QontoStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      <div className="QontoStepIcon-circle" />
    </QontoStepIconRoot>
  )
}

interface StepperProgressProps {
  activeStep: number
  sx?: SxProps
  steps: Array<{
    id: number
    label?: string
  }>
}

const StepperProgress: FC<StepperProgressProps> = ({ activeStep, steps, ...props }) => {
  return (
    <Stepper activeStep={activeStep} connector={<QontoConnector />} {...props}>
      {steps.map((step) => (
        <Step key={step.id}>
          <StepLabel StepIconComponent={QontoStepIcon}>{step.label || ''}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default StepperProgress
