import { Box } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useRef, useState } from 'react'
import { Iconify, TextField } from '@/components'
import { FormValues } from '@/modules/NFTCampaignWizard'

const EditCampaignNameInput = () => {
  const { control, formState } = useFormContext<FormValues>()
  const [canEdit, setCanEdit] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Box display="flex" alignItems="center">
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            sx={{
              '& fieldset': canEdit ? {} : { border: 'none' },
              mr: 2,
            }}
            InputProps={{
              sx: { fontWeight: 900 },
            }}
            inputProps={{
              style: { paddingTop: 8, paddingBottom: 8 },
            }}
            {...field}
            inputRef={inputRef}
            disabled={!canEdit}
            onBlur={() => setCanEdit(false)}
            error={!!formState.errors.name}
            placeholder="Campaign name"
          />
        )}
      />
      <Iconify
        icon="eva:edit-fill"
        onClick={() => {
          setCanEdit(true)
          inputRef.current?.focus()
        }}
      />
    </Box>
  )
}

export default EditCampaignNameInput
