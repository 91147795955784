import { useContext } from 'react'
import { CommunityDetailsModalContext } from '../contexts/CommunityDetailsModalContext'

const useCommunityDetailsModal = () => {
  const { open, close } = useContext(CommunityDetailsModalContext)

  return { open, close }
}

export default useCommunityDetailsModal
