import { FC } from 'react'
// @mui
import { Step, Stepper, StepLabel, StepIcon, StepIconProps } from '@mui/material'

interface VerticalLinearStepperProps {
  activeStep: number
  steps: Array<{
    id: number
    label: string
  }>
}

const VerticalLinearStepper: FC<VerticalLinearStepperProps> = ({ activeStep, steps }) => {
  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={(props: StepIconProps) => (
                <StepIcon {...props} icon={props.icon} active={props.active || props.completed} completed={false} />
              )}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  )
}

export default VerticalLinearStepper
