import { StripeGetDiscountsResponse, StripeOAuthAuthorizeResponse } from '@pangea/types'
import { AuthenticatedClient } from '.'

const authorize = async (projectId: string) => {
  const url = `/project/${projectId}/integrations/stripe/authorize`
  const res = await AuthenticatedClient.get<StripeOAuthAuthorizeResponse>(url)

  return res.data
}

const getCoupons = async (projectId: string, integrationId: string) => {
  const ulr = `/project/${projectId}/integrations/stripe/coupons?integrationId=${integrationId}`
  const res = await AuthenticatedClient.get<StripeGetDiscountsResponse>(ulr)

  return res.data
}

const StripeService = {
  authorize,
  getCoupons,
}

export default StripeService
