/**
 * **@MemberListSource** Enumerates the possible sources for a member list.
 */
export var MemberListSource;
(function (MemberListSource) {
    /** Indicates that the member list source is a file */
    MemberListSource["FILE"] = "FILE";
    /** Indicates that the member list source is from an integration */
    MemberListSource["INTEGRATION"] = "INTEGRATION";
})(MemberListSource || (MemberListSource = {}));
