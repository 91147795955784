// @mui
/* eslint-disable */
import { Card, Typography, Box, Stack, CardProps } from '@mui/material'
// components
import { Label, Iconify, Button } from '@/components'
import { ButtonProps } from '@/components/Button/Button'
// assets
import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '@/assets/pricing'
import { EnrichedPlan, SubscriptionEnum } from './utils'

// ----------------------------------------------------------------------

interface Props extends CardProps {
  card: EnrichedPlan
  buttonText?: string
  onPlanSelect: (planId: string) => void
  buttonProps?: ButtonProps
  index: number
}

export default function PricingPlanCard({ card, index, sx, onPlanSelect, buttonText, buttonProps, ...other }: Props) {
  const { id: cardId, subscription, description, lists, labelAction, licensePrice, pricing, claimLimit } = card
  const price = licensePrice?.pricePerIntervalInUSD?.toLocaleString() || 0
  return (
    <Card
      sx={{
        p: 5,
        boxShadow: (theme) => theme.customShadows.z24,
        ...((index === 0 || index === 2) && {
          boxShadow: 'none',
          bgcolor: 'transparent',
          border: (theme) => `dashed 1px ${theme.palette.divider}`,
        }),
        ...sx,
      }}
      {...other}
    >
      {index === 1 && (
        <Label color="info" sx={{ top: 16, right: 16, position: 'absolute' }}>
          POPULAR
        </Label>
      )}

      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {subscription}
      </Typography>

      <Stack spacing={1} direction="row" sx={{ my: 2 }}>
        {(index === 1 || index === 2) && <Typography variant="h5">$</Typography>}

        <Typography variant="h2">{price === 0 ? 'Free' : pricing?.monthly}</Typography>

        {(index === 1 || index === 2) && (
          <Typography component="span" sx={{ alignSelf: 'center', color: 'text.secondary' }}>
            {/* /{pricing?.monthly}{intervalPrice} */}
            mo
          </Typography>
        )}
      </Stack>

      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
        }}
      >
        {description}
      </Typography>

      <Box sx={{ width: 80, height: 80, mt: 5 }}>
        {(subscription === SubscriptionEnum.BASIC && <PlanFreeIcon />) ||
          (subscription === SubscriptionEnum.STARTER && <PlanStarterIcon />) || <PlanPremiumIcon />}
      </Box>

      <Stack component="ul" spacing={2} sx={{ p: 0, my: 5 }}>
        <Stack
          component="li"
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            typography: 'body2',
          }}
        >
          <Iconify
            icon="eva:checkmark-fill"
            width={16}
            sx={{
              color: 'primary.main',
            }}
          />
          <Typography variant="body2">Claim Limit {claimLimit}</Typography>
        </Stack>
      </Stack>

      <Button
        fullWidth
        size="large"
        variant="contained"
        // disabled={index === 0}
        onClick={() => {
          onPlanSelect(cardId)
        }}
        {...buttonProps}
      >
        {buttonText || labelAction}
      </Button>
    </Card>
  )
}
