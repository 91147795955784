import { IntegrationDTO } from '@pangea/types'
import { AuthenticatedClient } from '.'

const getAll = async (projectId: string) => {
  const res = await AuthenticatedClient.get<IntegrationDTO[]>(`/project/${projectId}/integrations`)
  return res.data
}

const deleteIntegration = async (projectId: string, integrationId: string) => {
  await AuthenticatedClient.del<void>(`/project/${projectId}/integrations/${integrationId}`)
}

const setupIntegration = async (projectId: string, integrationType: string, requestId: string) => {
  const lowercaseIntegrationType = integrationType.toLowerCase()
  await AuthenticatedClient.get<void>(
    `/project/${projectId}/integrations/${lowercaseIntegrationType}/callback?requestId=${requestId}`
  )
}

const IntegrationService = {
  getAll,
  deleteIntegration,
  setupIntegration,
}

export default IntegrationService
