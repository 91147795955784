import { Stack } from '@mui/material'
import { SocialIcon, Text } from '@/components'
import { SocialHandleProps } from '../types'

const SocialHandle = ({ followers, socialHandle }: SocialHandleProps) => {
  if (!followers) {
    return null
  }

  return (
    <Stack direction="row" gap={1}>
      <SocialIcon socialHandle={socialHandle} />
      <Text variant="caption" color="grey.400">
        {followers}
      </Text>
    </Stack>
  )
}

export default SocialHandle
