import { useContext } from 'react'
import { OnboardingContext } from '@/components/onboarding'

const useOnboarding = () => {
  const context = useContext(OnboardingContext)
  if (!context) {
    throw new Error('useOnboardingContext must be used within an OnboardingContextProvider')
  }
  return context
}

export default useOnboarding
