import {
  AllRewardDefinitionData,
  CampaignStatus,
  NftCampaignDetail,
  SupportedBlockchains,
  UpdateNftCampaign,
} from '@pangea/types'
import { FormValues } from '@/modules/NFTCampaignWizard'
// @ts-ignore

const getUpdateData = (
  campaign: NftCampaignDetail,
  formData: FormValues,
  status: CampaignStatus,
  rewardDefinitionData: AllRewardDefinitionData
): UpdateNftCampaign => ({
  nftRules: {
    type: formData.type,
    rules: [
      ...formData.communities.map((c) => ({
        blockchain: c.blockchain as SupportedBlockchains,
        smartContractId: c.address,
        requiredTraits: c.requiredTraits,
        count: parseInt(`${c.count}`),
      })),
    ],
  },
  startDate: formData.startDate.toDate(),
  endDate: formData.endDate.toDate(),
  maxClaims: formData.maxClaims || undefined,
  trackNftUsed: formData.trackNftUsed,
  soulBound: formData.soulBound,
  status,
  name: formData.name,
  rewardDefinition: {
    rewardTemplateData: {
      campaignDesign: {
        title: formData.campaignTitle,
        description: formData.campaignDescription,
        photo: '',
      },
      rewardDesign: {
        title: formData.rewardTitle,
        description: formData.rewardDescription,
      },
    },
    rewardDefinitionData: rewardDefinitionData,
  },
})

export default getUpdateData
