import { Box } from '@mui/material'
import { Button, Iconify, Text } from '@/components'

interface RewardProps {
  brandImg: string
  brandName: string
  title: string
  description: string
}

const Reward: React.FC<RewardProps> = ({ brandImg, brandName, title, description }) => {
  return (
    <Box
      sx={{
        backgroundImage:
          'conic-gradient(from 4deg at 48.88% 50.00%, rgba(146, 192, 247, 0.80) 44.137554466724396deg, rgba(169, 108, 255, 0.80) 60.00000178813934deg, rgba(146, 192, 247, 0.80) 217.7541947364807deg, rgba(136, 92, 255, 0.80) 241.875deg, rgba(136, 92, 255, 0.80) 256.7241597175598deg, rgba(139, 125, 252, 0.80) 331.875deg);',
        width: 250,
        height: 275,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 1.5,
        borderRadius: 0.75,
        position: 'relative',
      }}
    >
      <img src={brandImg} alt="reward brand" width={42} height={42} style={{ borderRadius: 4 }} />
      <Text fontWeight={600} mt={0.75} mb={2.5}>
        {brandName}
      </Text>
      <Text fontWeight={600} mb={0.75}>
        {title}
      </Text>
      <Text fontSize="12.8px" fontWeight={500} mb={0.75}>
        {description}
      </Text>
      <Text
        sx={{
          width: '100%',
          height: 33,
          bgcolor: '#1F1F1F',
          borderRadius: 18,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 0.75,
        }}
      >
        888-AAG-4780
      </Text>
      <Button
        fullWidth
        sx={{
          color: '#101010',
          fontWeight: 400,
          height: 36,
          borderRadius: 18,
          bgcolor: 'white',
          '&:hover': {
            bgcolor: 'white',
          },
        }}
      >
        Copy code
      </Button>
      <Text mt={1.5} fontSize={9} fontWeight={700}>
        Claimed
        <Iconify icon="game-icons:check-mark" sx={{ height: 10 }} />
      </Text>

      <Box
        sx={{
          position: 'absolute',
          top: 85,
          left: 0,
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="24" viewBox="0 0 8 24" fill="none">
          <path
            d="M4.10369 21.008C2.92909 22.1826 1.53464 23.1143 -5.14984e-05 23.75L-5.13624e-05 0.377071C1.53464 1.01276 2.92909 1.94451 4.10369 3.1191C5.27829 4.2937 6.21003 5.68816 6.84572 7.22284C7.48141 8.75753 7.80859 10.4024 7.80859 12.0635C7.80859 13.7247 7.48141 15.3695 6.84572 16.9042C6.21003 18.4389 5.27829 19.8334 4.10369 21.008Z"
            fill="#101010"
          />
        </svg>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 85,
          right: -1,
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="24" viewBox="0 0 9 24" fill="none">
          <path
            d="M4.14631 3.11899C5.32091 1.94439 6.71536 1.01264 8.25005 0.376953L8.25005 23.7499C6.71537 23.1142 5.32091 22.1824 4.14631 21.0078C2.97171 19.8332 2.03997 18.4388 1.40428 16.9041C0.768591 15.3694 0.441406 13.7246 0.441406 12.0634C0.441406 10.4023 0.768591 8.75741 1.40428 7.22273C2.03997 5.68804 2.97171 4.29358 4.14631 3.11899Z"
            fill="#101010"
          />
        </svg>
      </Box>
    </Box>
  )
}

export default Reward
