import { RouteObject, useLocation } from 'react-router-dom'
import loadable from '@loadable/component'
import IntegrationCallbackHandler from '@/modules/IntegrationCallbackHandler'

export enum URLS {
  // Public
  LOGIN = '/auth/login',
  TOKEN = '/token',
  // Private
  DASHBOARD = '/',
  ONBOARDING = '/get-started',
  ONBOARDING_SUCCESS = '/get-started/you-rock',
  ONBOARDING_CONFIGURE_EXPERIENCE = '/get-started/configure-experience',
  ONBOARDING_SETUP_WORKSPACE = '/get-started/setup-workspace',
  ONBOARDING_SELECT_PLAN = '/get-started/select-plan',
  ONBOARDING_START_JOURNEY = '/get-started/start-journey',

  NFT_CAMPAIGNS = '/campaigns',
  NFT_CAMPAIGNS_CREATE = '/campaigns/create',
  NFT_CAMPAIGNS_WIZARD = '/campaigns/:campaignId',
  NFT_COMMUNITIES = '/nft-communities',
  EXPLORE_NFT_COMMUNITIES = '/nft-communities/explore',
  SEARCH_NFT_COMMUNITIES = '/nft-communities/search',
  INTEGRATIONS = '/integrations',
  USERS = '/users',
  API_KEYS = '/api-keys',
  ACCOUNT_SETTINGS = '/account-settings',
  INTEGRATION_SETUP = '/integration/setup',
  INTEGRATION_FAIL = '/integration/error',
  INTEGRATION_SUCCESS = '/integration/success',
}

export const INDEX_ROUTE = URLS.DASHBOARD

// public components
const Auth = loadable(() => import('@/modules/Auth'))
const PrivateRoute = loadable(() => import('@/components/PrivateRoute'))
// private components
const Dashboard = loadable(() => import('@/modules/Dashboard'))
const DashboardLayout = loadable(() => import('@/components/DashboardLayout'))
const Onboarding = loadable(() => import('@/modules/Onboarding'))
const OnboardingSuccess = loadable(() => import('@/modules/OnboardingSuccess'))
const ProjectContextProvider = loadable(() => import('@/contexts/ProjectContext'))
const NFTCampaign = loadable(() => import('@/modules/NFTCampaign'))
const NFTCampaignTemplates = loadable(() => import('@/modules/NFTCampaignTemplates'))
const NFTCampaignWizard = loadable(() => import('@/modules/NFTCampaignWizard'))
const Integrations = loadable(() => import('@/modules/Integrations'))
const IntegrationSetup = loadable(() => import('@/modules/IntegrationSetup'))
const ExploreNftCommunities = loadable(() => import('@/modules/ExploreNftCommunities'))
const SearchNftCommunities = loadable(() => import('@/modules/SearchNftCommunities'))
const CommunityDetailsModalProvider = loadable(() => import('@/contexts/CommunityDetailsModalContext'))
const AccountSettings = loadable(() => import('@/modules/AccountSettings'))
const IntegrationModalProvider = loadable(() => import('@/contexts/IntegrationModalContext'))
const ConfirmNavigationModalProvider = loadable(() => import('@/contexts/ConfirmNavigationModalContext'))
const SelectedCommunitiesProvider = loadable(() => import('@/contexts/SelectedCommunitiesContext'))
const PreferencesProvider = loadable(() => import('@/contexts/UserPreferencesContext'))
const NotFound = loadable(() => import('@/components/ErrorPages/404'))
const Users = loadable(() => import('@/modules/Users'))

const publicRoutes: RouteObject[] = [
  {
    element: <Auth />,
    path: URLS.LOGIN,
  },
  {
    element: <Auth />,
    path: URLS.TOKEN,
  },
  {
    element: <IntegrationCallbackHandler />,
    path: URLS.INTEGRATION_FAIL,
  },
  {
    element: <IntegrationCallbackHandler />,
    path: URLS.INTEGRATION_SUCCESS,
  },
]

const privateRoutes: RouteObject[] = [
  {
    element: <PrivateRoute />,
    children: [
      {
        element: <PreferencesProvider />,
        children: [
          {
            element: <ConfirmNavigationModalProvider />,
            children: [
              {
                element: <ProjectContextProvider />,
                children: [
                  {
                    element: <IntegrationModalProvider />,
                    children: [
                      {
                        element: <SelectedCommunitiesProvider />,
                        children: [
                          {
                            element: <CommunityDetailsModalProvider />,
                            children: [
                              {
                                element: <DashboardLayout />,
                                path: URLS.DASHBOARD,
                                children: [
                                  {
                                    element: <Dashboard />,
                                    path: URLS.DASHBOARD,
                                  },
                                  {
                                    element: <NFTCampaign />,
                                    path: URLS.NFT_CAMPAIGNS,
                                  },
                                  {
                                    element: <ExploreNftCommunities />,
                                    path: URLS.EXPLORE_NFT_COMMUNITIES,
                                  },
                                  {
                                    element: <SearchNftCommunities />,
                                    path: URLS.SEARCH_NFT_COMMUNITIES,
                                  },
                                  {
                                    element: <Integrations />,
                                    path: URLS.INTEGRATIONS,
                                  },
                                  {
                                    element: <Users />,
                                    path: URLS.USERS,
                                  },
                                  // {
                                  //   element: <h1>{URLS.API_KEYS}</h1>,
                                  //   path: URLS.API_KEYS,
                                  // },
                                  {
                                    element: <AccountSettings />,
                                    path: URLS.ACCOUNT_SETTINGS,
                                  },
                                ],
                              },
                              {
                                element: <NFTCampaignWizard />,
                                path: URLS.NFT_CAMPAIGNS_WIZARD,
                              },
                              {
                                element: <NFTCampaignTemplates />,
                                path: URLS.NFT_CAMPAIGNS_CREATE,
                              },
                              {
                                element: <Onboarding />,
                                path: URLS.ONBOARDING,
                              },
                              {
                                element: <OnboardingSuccess />,
                                path: URLS.ONBOARDING_SUCCESS,
                              },
                              {
                                element: <IntegrationSetup />,
                                path: URLS.INTEGRATION_SETUP,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]

export const routes = [
  ...publicRoutes,
  ...privateRoutes,
  {
    path: '*',
    element: <NotFound />,
  },
]

export function useIsPublicRoute(): boolean {
  const { pathname } = useLocation()
  return publicRoutes.some((route) => route.path === pathname)
}
