import moment from 'moment'
import { GridColDef, DataGrid } from '@mui/x-data-grid'
import { Box, Skeleton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CampaignTemplateCode, NftCampaignDetail, NftCampaignWithImages } from '@pangea/types'
import usePaginatedQuery from '@shared/hooks/usePaginatedQuery'
import CampaignStatusLabel from '@/components/NFTCampaign/CampaignStatus'
// import ShareCampaignMenu from '@/components/NFTCampaign/ShareCampaignMenu'
import CampaignOptions from '@/components/NFTCampaign/CampaignOptions'
import { getCampaignUrl } from '@/utils/links'
import { AvatarGroup, Text, Error, IntegrationIcon } from '@/components'
import { useProject } from '@/hooks'
import { CampaignService } from '@/api'
import TemplateTypeIcon from '../NFTCampaign/TemplateTypeIcon/TemplateTypeIcon'

type onDeleteFn = (campaignId: string) => Promise<void>

type NftCampaignListItem = {
  id: string
  utility: string
  communities: (string | undefined)[]
  integration: string
  templateType: CampaignTemplateCode
  endDate: string
  claims: string
  status: string
  share: string
  edit: string
  onDelete: onDeleteFn
  isSkeleton: boolean
}

const baseColumnProps: Partial<GridColDef> = {
  flex: 1,
  sortable: false,
}

const avatarSize = {
  width: 24,
  height: 24,
}

const getRows = (campaigns: NftCampaignWithImages[], onDelete: onDeleteFn): NftCampaignListItem[] => {
  return campaigns.map((c) => {
    const utility = c.rewardTemplateCode.toLowerCase().replaceAll('_', ' ')
    return {
      id: c.id,
      utility: c.name || utility[0].toUpperCase() + utility.slice(1),
      communities: c.images ? c.images.map((i) => i.imageUrl) : [],
      integration: c.integrationType,
      templateType: c.rewardTemplateCode,
      endDate: moment(c.endDate).format('MMM-DD-YYYY'),
      claims: c.claimedCount + '/' + c.maxClaims,
      status: c.publishStatus || c.status,
      share: '',
      edit: '',
      onDelete,
      isSkeleton: false,
      rewardDefinition: c.rewardDefinition,
    }
  })
}

const getSkeletonRow = (id: string): NftCampaignListItem => ({
  id,
  utility: '',
  communities: [],
  integration: '',
  endDate: '',
  claims: '',
  status: '',
  share: '',
  edit: '',
  onDelete: async () => {},
  isSkeleton: true,
  templateType: '' as CampaignTemplateCode,
})

export const columns: GridColDef<NftCampaignListItem>[] = [
  {
    field: 'utility',
    headerName: 'Campaign',
    ...baseColumnProps,
    renderCell: ({ row, value }) =>
      row.isSkeleton ? (
        <Skeleton variant="rounded" width="100%" />
      ) : (
        <Box display="flex" alignItems="center" width="100%" minWidth={0}>
          <TemplateTypeIcon type={row.templateType} size={24} mr={1} />
          <Text variant="body2" overflow="hidden" textOverflow="ellipsis">
            {value}
          </Text>
        </Box>
      ),
    width: 270,
  },
  {
    field: 'communities',
    headerName: 'NFT Communities',
    renderCell: (params) =>
      params.row.isSkeleton ? (
        <Skeleton variant="rounded" width="100%" />
      ) : (
        <AvatarGroup
          avatars={params.value.map((a: string) => ({
            src: a,
            sx: avatarSize,
          }))}
          max={4}
          sx={{
            '& .MuiAvatar-colorDefault': avatarSize,
          }}
        />
      ),
    ...baseColumnProps,
    width: 140,
  },
  {
    field: 'integration',
    headerName: 'Integration',
    renderCell: (params) => {
      if (params.row.isSkeleton) return <Skeleton variant="rounded" width="100%" />
      return <IntegrationIcon integration={params.value} width={64} />
    },
    ...baseColumnProps,
    width: 115,
  },
  {
    field: 'endDate',
    headerName: 'Expiry date',
    ...baseColumnProps,
    width: 110,
    renderCell: (params) => (params.row.isSkeleton ? <Skeleton variant="rounded" width="100%" /> : params.value),
  },
  {
    field: 'claims',
    headerName: 'Claims',
    ...baseColumnProps,
    width: 100,
    renderCell: (params) => (params.row.isSkeleton ? <Skeleton variant="rounded" width="100%" /> : params.value),
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (params) =>
      params.row.isSkeleton ? (
        <Skeleton variant="rounded" width="100%" />
      ) : (
        <CampaignStatusLabel status={params.value} />
      ),
    ...baseColumnProps,
    width: 90,
  },
  // {
  //   field: 'share',
  //   headerName: '',
  //   renderCell: (params) => (params.row.isSkeleton ? null : <ShareCampaignMenu />),
  //   ...baseColumnProps,
  //   maxWidth: 50,
  // },
  {
    field: 'edit',
    headerName: '',
    renderCell: (params) =>
      params.row.isSkeleton ? null : (
        <CampaignOptions
          campaign={params.row as any as NftCampaignDetail}
          onCampaignDeleted={(campaignId) => params.row.onDelete(campaignId)}
        />
      ),
    ...baseColumnProps,
    maxWidth: 50,
  },
]

interface RecentCampaignsProps {
  withPagination?: boolean
}

const RecentCampaigns: React.FC<RecentCampaignsProps> = ({ withPagination = false }) => {
  const navigate = useNavigate()
  const { project } = useProject()
  const { query, pagination } = usePaginatedQuery({
    queryKey: ['campaigns', project!.id],
    queryFn: (pagination) =>
      CampaignService.getAll(project!.id, withPagination ? pagination : { page: 1, pageSize: 5 }),
    initialPaginationConfig: {
      page: 1,
    },
  })
  const { data, isError, isLoading, isFetching, refetch } = query
  const { page, pageSize, setPage, setPageSize } = pagination

  const onCampaignDeleted = async (campaignId: string) => {
    await refetch()
  }

  if (!isLoading && (isError || !data)) return <Error msg="Failed to load recent campaigns" />

  return (
    <Box>
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              page,
              pageSize,
            },
          },
        }}
        onPaginationModelChange={(model) => {
          model.page > 0 && setPage(model.page)
          setPageSize(model.pageSize)
        }}
        pageSizeOptions={[5, 10, 15, 20]}
        paginationMode="server"
        disableColumnMenu
        disableVirtualization={true}
        columns={columns}
        onRowClick={(data) => navigate(getCampaignUrl(data.id as string))}
        rows={
          isLoading
            ? [...Array(4)].map((_, i) => getSkeletonRow(i + ''))
            : getRows(data.data, (campaignId) => onCampaignDeleted(campaignId))
        }
        rowHeight={50}
        sx={{
          opacity: isFetching ? 0.5 : 1,
          minWidth: 125,
          border: '1px solid rgba(145, 158, 171, 0.32)',
          borderRadius: 2,
          p: 2,
          '& .MuiDataGrid-columnHeaders': {
            bgcolor: 'background.paper',
            borderRadius: 1,
            borderBottom: 'none',
          },
          '& .MuiDataGrid-row': {
            borderTop: 'none',
            borderBottom: '0.5px solid #637381',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            width: 0,
            background: 'transparent',
          },
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            position: 'relative',
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            height: 'fit-content !important',
          },
          '& .MuiDataGrid-footerContainer': {
            display: !withPagination ? 'none' : '',
          },
        }}
      />
    </Box>
  )
}

export default RecentCampaigns
