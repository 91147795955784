import { useCallback, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import { useSettingsContext } from '@/components/settings'
import useResponsive from '@/hooks/useResponsive'
import { Main } from '@/components'
import { Sidebar } from '@/components'
import { SidebarHorizontal, SidebarMini } from '@/components/Sidebar'

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext()
  const [open, setOpen] = useState(false)

  const isDesktop = useResponsive('up', 'lg')
  const isNavHorizontal = themeLayout === 'horizontal'
  const isNavMini = themeLayout === 'mini'

  // const handleOpen = () => {
  //   setOpen(true)
  // }

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const renderNavVertical = useMemo(() => <Sidebar hidden={open} onHide={handleClose} />, [open, handleClose])

  if (isNavHorizontal) {
    return (
      <>
        {/* <Header onOpenNav={handleOpen} /> */}

        {isDesktop ? <SidebarHorizontal /> : renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </>
    )
  }

  if (isNavMini) {
    return (
      <>
        {/* <Header onOpenNav={handleOpen} /> */}

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <SidebarMini /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    )
  }

  return (
    <>
      {/* <Header onOpenNav={handleOpen} /> */}

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  )
}
