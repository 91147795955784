export class ApiError extends Error {
    statusCode;
    message;
    errorCode;
    fieldErrors;
    constructor(statusCode, message, errorCode, fieldErrors) {
        super(message);
        this.statusCode = statusCode;
        this.message = message;
        this.errorCode = errorCode;
        this.fieldErrors = fieldErrors;
    }
}
export function toFieldErrors(msg, fields) {
    return fields.reduce((errors, field) => {
        errors[field] = {
            message: msg,
        };
        return errors;
    }, {});
}
