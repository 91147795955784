import { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, useTheme } from '@mui/material'
import { Button, Iconify, Text } from '@/components'
import { PANGEA_WEB_URL } from '@/constants'

const DesktopAuth: FC = () => {
  const theme = useTheme()

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Text variant="subtitle2" align="center">
          is not available on mobile yet {':('}
        </Text>
        <Text variant="caption" color={theme.palette.grey[500]} align="center" maxWidth={320}>
          To experience the full potential of Pangea Labs please switch to desktop
        </Text>
      </Box>
      <Button
        startIcon={<Iconify icon="ci:chevron-left" />}
        variant="text"
        LinkComponent={RouterLink}
        href={PANGEA_WEB_URL}
        sx={{
          width: 194,
          height: 48,
          color: theme.palette.grey[500],
        }}
      >
        Back to homepage
      </Button>
    </>
  )
}

export default DesktopAuth
