import { IntegrationType } from '@pangea/types'

export function isCampaignIntegrationRequired(type: IntegrationType) {
  switch (type) {
    case IntegrationType.INTERNAL:
      return false
    default:
      return true
  }
}
