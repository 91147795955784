import { FC } from 'react'
import { IntegrationType } from '@pangea/types'
import PangeaLogo from '@/assets/logo/pangea_labs.svg'
import MindbodyLogo from '@/assets/logo/mindbody.svg'
import SquareLogo from '@/assets/logo/square.svg'
import ShopifyLogo from '@/assets/logo/shopify.svg'
import StripeLogo from '@/assets/logo/stripe.svg'
import LightspeedLogo from '@/assets/logo/lightspeed.svg'
import WixLogo from '@/assets/logo/wix.svg'
import BigCommerceLogo from '@/assets/logo/bigcommerce.svg'

type IntegrationIconProps = {
  integration: IntegrationType
} & React.HTMLProps<HTMLImageElement>

const integrationIcons = {
  [IntegrationType.INTERNAL]: PangeaLogo,
  [IntegrationType.MINDBODY]: MindbodyLogo,
  [IntegrationType.SQUARE]: SquareLogo,
  [IntegrationType.SHOPIFY]: ShopifyLogo,
  [IntegrationType.STRIPE]: StripeLogo,
  [IntegrationType.LIGHTSPEED]: LightspeedLogo,
  [IntegrationType.WIX]: WixLogo,
  [IntegrationType.BIGCOMMERCE]: BigCommerceLogo,
}

const IntegrationIcon: FC<IntegrationIconProps> = ({ integration, ...imgProps }) => {
  return <img alt={`${integration} logo`} src={integrationIcons[integration]} {...imgProps} />
}

export default IntegrationIcon
