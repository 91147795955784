interface EnvVars {
  REACT_APP_API_URL: string
  REACT_APP_NOTA_API_URL: string
  REACT_APP_DOCS_URL: string
  REACT_APP_NOTA_URL: string
}

function useEnv(): EnvVars {
  return {
    REACT_APP_API_URL: process.env.REACT_APP_API_URL!,
    REACT_APP_NOTA_API_URL: process.env.REACT_APP_NOTA_API_URL!,
    REACT_APP_DOCS_URL: process.env.REACT_APP_DOCS_URL!,
    REACT_APP_NOTA_URL: process.env.REACT_APP_NOTA_URL!,
  }
}

export default useEnv
