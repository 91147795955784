import { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { getSessionToken, jwtDecode, logout, setSessionToken } from '@shared/session/session'
import { CustomEvt } from '@shared/utils/browser'
import { SessionEvent } from '@shared/types/session'
import { UserSession } from '@pangea/types'
import { AuthenticatedClient } from '@/api'
import { LoadingScreen } from '@/components'
import { Session } from '@/types/session'
import { URLS } from './routes'

export interface SessionStore {
  setSession: (accessToken: string) => void
  invalidateSession: () => void
  isLoading: boolean
  data?: UserSession
  token?: string
}

export const SessionCtx = createContext<SessionStore | null>(null)

export type SessionProviderProps = {
  children: ReactNode
}

export const SessionProvider: FC<SessionProviderProps> = ({ children }) => {
  const [sessionData, setSessionData] = useState<UserSession>()
  const [token, setToken] = useState<string>()

  const deleteSession = useCallback(async () => {
    await logout()
    setSessionData(undefined)
    setToken(undefined)
  }, [])

  const setSession = (token: string, skipSaving = false) => {
    // set token on AuthenticatedClient
    AuthenticatedClient.setAccessToken(token)

    // Update session
    const parsedToken = jwtDecode<Session>(token)
    setSessionData(parsedToken.properties)
    setToken(token)

    // save token to local storage
    if (!skipSaving) {
      setSessionToken(token)
    }
  }

  const handleTokenUpdate = useCallback((e: CustomEvt<{ token: string }>) => {
    setSession(e.detail.token)
  }, [])

  const { isLoading } = useQuery('checkSession', getSessionToken, {
    onError: deleteSession,
    onSuccess: (data) => {
      if (!data) {
        return
      }
      setSession(data, true)
    },
    refetchOnReconnect: true,
  })

  useEffect(() => {
    window.addEventListener(SessionEvent.UPDATE, handleTokenUpdate as EventListener)
    window.addEventListener(SessionEvent.REMOVE, deleteSession)
    return () => {
      window.removeEventListener(SessionEvent.UPDATE, handleTokenUpdate as EventListener)
      window.removeEventListener(SessionEvent.REMOVE, deleteSession)
    }
  }, [deleteSession, handleTokenUpdate])

  const memoizedChildren = useMemo(() => children, [children])

  if (isLoading) {
    return <LoadingScreen />
  }

  if (!token && !isLoading && window.location.pathname !== URLS.LOGIN && window.location.pathname !== URLS.TOKEN) {
    const location = window.location
    const currLocation = `${location.pathname}${location.search}`
    window.location.assign(`${URLS.LOGIN}?return_to=${encodeURIComponent(currLocation)}`)
    return null
  }

  const sessionDetails = {
    invalidateSession: deleteSession,
    isLoading,
    setSession,
    data: sessionData,
    token,
  }

  return <SessionCtx.Provider value={sessionDetails}>{memoizedChildren}</SessionCtx.Provider>
}
