import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Stepper(theme: Theme) {
  return {
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: theme.palette.divider,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-completed': {
            fontWeight: 600,
          },
          '&.Mui-active': {
            fontWeight: 600,
          },
          '&.Mui-disabled': {
            fontWeight: 600,
          },
        },
      },
    },
  }
}
