import { Controller, useFormContext } from 'react-hook-form'
import { NftCampaignDetail } from '@pangea/types'
import { Box } from '@mui/material'
import { Text, TextField } from '@/components'

import Container from '../../Container'

interface LightspeedRewardDefinitionProps {
  campaign: NftCampaignDetail
}

const LightspeedRewardDefinition: React.FC<LightspeedRewardDefinitionProps> = ({ campaign }) => {
  const { control } = useFormContext()

  return (
    <>
      <Container mt={3}>
        <Text variant="h5" mb={3}>
          Reward Definition
        </Text>
        <Box display="flex">
          <Controller
            name="fixedAmount"
            defaultValue={
              'valueInUsd' in campaign.rewardDefinition.rewardDefinitionData
                ? campaign.rewardDefinition.rewardDefinitionData.valueInUsd
                : undefined
            }
            control={control}
            render={({ field }) => (
              <TextField label="Amount" {...field} InputProps={{ startAdornment: '$' }} sx={{ flexGrow: 1 }} />
            )}
          />
        </Box>
      </Container>
    </>
  )
}

export default LightspeedRewardDefinition
