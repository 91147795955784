import { useContext } from 'react'
import { SessionCtx, SessionStore } from '@/utils/session'

function useSession() {
  const session = useContext(SessionCtx) as SessionStore
  if (!session) {
    throw new Error('useSession should be used inside SessionContext')
  }
  return session
}

export default useSession
