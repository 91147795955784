import { Theme } from '@mui/material/styles'
import { TextFieldProps } from '@mui/material'

// ----------------------------------------------------------------------

export default function TextField(theme: Theme) {
  const defaultProps: TextFieldProps = {
    variant: 'outlined',
    InputLabelProps: { shrink: true },
    fullWidth: true,
  }
  return {
    MuiTextField: {
      defaultProps,
      styleOverrides: {
        root: {
          '.MuiInputBase-root': {
            '&:hover': {
              borderColor: theme.palette.text.primary,
            },
          },
        },
      },
    },
  }
}
