import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { URLS } from '@/utils/routes'
import { LoadingScreen } from '@/components'
import { useSession } from '@/hooks'
import { AuthService } from '@/api'

const PrivateRoute = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { isLoading: isLoadingSession, data, setSession } = useSession()
  const [isLoading, setIsLoading] = useState(isLoadingSession)

  const checkToken = useCallback(async () => {
    if (!data) {
      setIsLoading(true)
      try {
        const newToken = await AuthService.refreshToken()
        setSession(newToken)
        setIsLoading(false)
      } catch (err) {
        // return_to
        const currLocation = `${location.pathname}${location.search}`
        navigate(`${URLS.LOGIN}?return_to=${encodeURIComponent(currLocation)}`, { replace: true })
      }
    }
  }, [data, navigate, setSession, location])

  useEffect(() => {
    let isMounted = true

    isMounted && checkToken()

    return () => {
      isMounted = false
    }
  }, [checkToken])

  if (isLoading) {
    return <LoadingScreen />
  }

  return <Outlet />
}

export default PrivateRoute
