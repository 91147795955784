import { Box } from '@mui/material'
import { useState } from 'react'
import { AxiosError } from 'axios'
import { TextField, Text, Button, Iconify, Dialog } from '@/components'
import { useProject, useSnack } from '@/hooks'
import { isValidUrl } from '@/utils/links'
import { CommunityImportService } from '@/api'

interface ImportCommunityModalProps {
  onClose: () => void
}

const ImportCommunityModal: React.FC<ImportCommunityModalProps> = ({ onClose }) => {
  const { project } = useProject()
  const { enqueueSnackbar } = useSnack()
  const [url, setUrl] = useState('')
  const [isUrlValid, setIsUrlValid] = useState(true)

  const addCommunity = async () => {
    const isValid = isValidUrl(url)
    if (!isValid) return setIsUrlValid(isValid)

    try {
      await CommunityImportService.importOpenSeaCommunity(project!.id, url)
      enqueueSnackbar('Your community was added', { variant: 'success' })
    } catch (error) {
      if (error instanceof AxiosError && error.response && 'message' in error.response.data) {
        return enqueueSnackbar(error.response.data.message, { variant: 'error' })
      }
      enqueueSnackbar('Failed to add community', { variant: 'error' })
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value)
    setIsUrlValid(true)
  }

  return (
    <Dialog open onClose={onClose}>
      <>
        <Text variant="h5" mb={2}>
          Add new community
        </Text>
        <Text variant="body1">
          To add your community, just enter the open sea URL below, and we will make sure that we get the details about
          it and make it available to use in your campaign
        </Text>
        <Box my={5}>
          <TextField label="Open sea URL" value={url} onChange={handleChange} sx={{ mb: 0.5 }} error={!isUrlValid} />
          {!isUrlValid && (
            <Text color="error" variant="caption">
              Please provide a valid URL
            </Text>
          )}
        </Box>
        <Box display="flex" height={50} mt={2}>
          <Button
            secondary
            startIcon={<Iconify icon="eva:close-fill" />}
            fullWidth
            sx={{ mr: 4, borderRadius: 50 }}
            onClick={onClose}
          >
            Close
          </Button>
          <Button fullWidth sx={{ borderRadius: 50 }} onClick={addCommunity}>
            Add community
          </Button>
        </Box>
      </>
    </Dialog>
  )
}

export default ImportCommunityModal
