import { FC } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { Avatar, Iconify, Text, BlockchainIcon } from '@/components'
import { NFTCommunityDropdownItemProps } from '../types'
import { extractCommunityData } from '../utils'
import SocialHandle from './SocialHandle'
import TradingData from './TradingData'

const NFTCommunityDropdownItemAdvanced: FC<NFTCommunityDropdownItemProps> = ({ showInfo, community, ...props }) => {
  const { blockchain, imageUrl, name, socialHandles, tradingData } = extractCommunityData(community)

  return (
    <>
      <li {...props}>
        <Grid container sx={{ height: 64 }} alignItems="center">
          <Grid item sm={8} md={5}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Avatar src={imageUrl} variant="rounded" />
              <Box>
                <Text variant="subtitle1">{name}</Text>
                <Stack direction="row" gap={2}>
                  <SocialHandle followers={socialHandles?.twitter?.followers} socialHandle="twitter" />
                  <SocialHandle followers={socialHandles?.discord?.followers} socialHandle="discord" />
                </Stack>
              </Box>
            </Stack>
          </Grid>
          <Grid item md={7} sm={4} display="flex" justifyContent="space-between" alignItems="center" gap={2}>
            <Box flex={6} display="flex" alignItems="center">
              <Box flex={0.5} flexBasis={1}>
                <BlockchainIcon blockchain={blockchain} />
              </Box>
              <Box flexBasis={100} flex={2}>
                <TradingData icon={tradingData?.category} title="Category" body={tradingData?.category} />
              </Box>
              <Box flex={1} sx={{ display: { sm: 'none', md: 'block' } }}>
                <TradingData title="Avg. Value" body={tradingData?.averageValue} />
              </Box>
              <Box flex={1} sx={{ display: { sm: 'none', md: 'block' } }}>
                <TradingData title="Unique Owners" body={tradingData?.owners} />
              </Box>
            </Box>
            {showInfo && (
              <Box
                px={1}
                display="flex"
                onClick={(e) => {
                  e.stopPropagation()
                  showInfo()
                }}
              >
                <Iconify sx={{ width: 20, height: 'auto' }} icon="material-symbols:info-outline" />
              </Box>
            )}
          </Grid>
        </Grid>
      </li>
    </>
  )
}

export default NFTCommunityDropdownItemAdvanced
