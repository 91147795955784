export class ReturnToService {
  private readonly RETURN_TO_KEY = 'authReturnTo';

  public setReturnTo(url: string) {
    if (!sessionStorage) {
      return;
    }
    sessionStorage.setItem(this.RETURN_TO_KEY, url);
  }

  public getReturnTo(): string | undefined {
    if (!sessionStorage) {
      return undefined;
    }
    return sessionStorage.getItem(this.RETURN_TO_KEY) || undefined;
  }

  public clearReturnTo() {
    if (!sessionStorage) {
      return undefined;
    }
    sessionStorage.removeItem(this.RETURN_TO_KEY);
  }
}
