import { Box } from '@mui/material'
import ThemeProvider from '@/theme'

interface ErrorPageContainerProps {
  children: React.ReactNode
}

const ErrorPageContainer: React.FC<ErrorPageContainerProps> = ({ children }) => {
  return (
    <ThemeProvider>
      <Box
        bgcolor="#161C24"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        {children}
      </Box>
    </ThemeProvider>
  )
}

export default ErrorPageContainer
