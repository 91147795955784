import { Theme } from '@mui/material/styles'

export default function Breadcrumbs(theme: Theme) {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          display: 'inline-flex',
          alignItems: 'center',
          '& .MuLink-underlineHover': { textDecorationColor: 'white' },
          '& .MuiLink-root': {
            display: 'inherit',
            '& .MuiTypography-root': {
              color: 'white',
              display: 'inherit',
            },
            '& .MuiIcon-root': {
              color: 'white',
              display: 'inherit',
            },
          },
          '& .MuiBox-root': {
            display: 'inherit',
            '& .MuiTypography-root': {
              color: theme.palette.text.disabled,
            },
          },
          '& .MuiIcon-root': {
            marginRight: theme.spacing(1),
            display: 'inherit',
          },
        },
        separator: {
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
        },
      },
    },
  }
}
