import { NFTCommunityDropdownItemSimple, NFTCommunityDropdownItemAdvanced } from './components'
import { NFTCommunityDropdownItemProps } from './types'

const NFTCommunityDropdownItem: React.FC<NFTCommunityDropdownItemProps> = (props) => {
  return props.mode === 'advanced' ? (
    <NFTCommunityDropdownItemAdvanced {...props} />
  ) : (
    <NFTCommunityDropdownItemSimple {...props} />
  )
}

export default NFTCommunityDropdownItem
