import * as React from 'react'
import {
  AuBankAccountElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  FpxBankElement,
  IbanElement,
  IdealBankElement,
} from '@stripe/react-stripe-js'
import { InputAdornment, IconButton, TextField, TextFieldProps } from '@mui/material'
import { useMemo } from 'react'
import { Iconify, Popover } from '@/components'
import StripeInputComponent from './StripeInputComponent'

type StripeElement =
  | typeof AuBankAccountElement
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement
  | typeof FpxBankElement
  | typeof IbanElement
  | typeof IdealBankElement

export type OnStripeChange = {
  complete: boolean
  error?: {
    message?: string
  }
}

type StripeTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  noLabel?: boolean
  onChange: (change: OnStripeChange) => void
}

export const StripeTextField = (props: StripeTextFieldProps & { stripeElement: StripeElement }) => {
  const { InputProps, stripeElement, onChange, ...rest } = props

  return (
    <TextField
      fullWidth
      InputProps={{
        ...InputProps,
        inputProps: {
          ...InputProps?.inputProps,
          shrink: true,
          component: useMemo(
            () => (props: any) =>
              stripeElement({
                ...props,
                options: {
                  disabled: rest.disabled,
                  ...(rest.noLabel ? { placeholder: '' } : {}),
                  style: {
                    base: { color: 'white' },
                    invalid: { color: 'white' },
                  },
                },
              }),
            [rest.disabled, rest.noLabel, stripeElement]
          ),
        },
        inputComponent: StripeInputComponent,
      }}
      //@ts-ignore
      onChange={onChange}
      {...rest}
    />
  )
}

export const StripeTextFieldNumber: React.FC<StripeTextFieldProps> = (props) => {
  return (
    <StripeTextField
      label="Credit Card Number"
      {...props}
      stripeElement={CardNumberElement}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton tabIndex={-1} size="small" edge="end">
              <Iconify icon="eva:credit-card-fill" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export const StripeTextFieldExpiry: React.FC<StripeTextFieldProps> = (props) => {
  return (
    <StripeTextField
      label="Expires"
      stripeElement={CardExpiryElement}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton tabIndex={-1} size="small" edge="end">
              <Iconify icon="eva:calendar-fill" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}

export const StripeTextFieldCVC: React.FC<StripeTextFieldProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const fieldRef = React.useRef<HTMLInputElement>(null)
  const cvcPopoverOpen = Boolean(anchorEl)

  const handleOpenPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  function handleClosePopover() {
    setAnchorEl(null)
  }

  return (
    <>
      <StripeTextField
        ref={fieldRef}
        label="CVC Code"
        stripeElement={CardCvcElement}
        InputProps={{
          endAdornment: (
            <InputAdornment tabIndex={-1} position="end">
              <IconButton tabIndex={-1} size="small" edge="end" onClick={handleOpenPopoverClick}>
                <Iconify icon="eva:lock-fill" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
      <Popover
        open={cvcPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        arrow="bottom-center"
        sx={{ maxWidth: 200, typography: 'body2', textAlign: 'center' }}
      >
        Three-digit number on the back of your VISA card
      </Popover>
    </>
  )
}
