// @mui
import { Box, Stack, Dialog, DialogTitle, DialogProps, DialogActions, DialogContent } from '@mui/material'
// components
import { Button, Image } from '@/components'
import { images } from '@/assets/pricing'
// ----------------------------------------------------------------------

interface Props extends DialogProps {
  onClose: VoidFunction
  open: boolean
}

export default function PaymentFailedDialog({ open, onClose, ...other }: Props) {
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
        <DialogTitle textAlign="center"> Payment failed!</DialogTitle>

        <DialogContent sx={{ overflow: 'unset' }}>
          <Stack spacing={3}>
            <Box alignItems="center" justifyContent="center" display="flex">
              <Image src={images.failure} />
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
          {/* <Button color="inherit" variant="outlined" onClick={onClose}>
            Cancel
          </Button> */}

          <Button color="primary" variant="contained" onClick={onClose}>
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
