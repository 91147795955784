import { AuthenticatedClient } from './'

function getLoginOrigin() {
  return process.env.REACT_APP_NOTA_URL
}

function getLoginUrl() {
  return `${getLoginOrigin()}/auth/login`
}

async function refreshToken() {
  return AuthenticatedClient.refreshAccessToken()
}

const logout = () => AuthenticatedClient.logout()

const AuthService = {
  getLoginOrigin,
  getLoginUrl,
  refreshToken,
  logout,
}

export default AuthService
