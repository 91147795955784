import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

interface Props {
  control: any
  label: string
  required: boolean
  name: string
  placeholder?: string
  disabled?: boolean
}

const RHFTextField = ({ placeholder = '', control, label, name, required, disabled }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={disabled}
          placeholder={placeholder}
          fullWidth
          error={!!error}
          helperText={error ? error?.message : ' '}
          label={label}
          {...field}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  )
}

export default RHFTextField
