import {
  CreateNftCampaign,
  NftCampaignDetail,
  NftCampaignWithImages,
  Paginated,
  PaginationInput,
  UpdateNftCampaign,
} from '@pangea/types'
import { AuthenticatedClient } from './'

const withPagination = (url: string, pagination: PaginationInput) =>
  `${url}?page=${pagination.page}&pageSize=${pagination.pageSize}`

const create = async (projectId: string, data: CreateNftCampaign) => {
  const res = await AuthenticatedClient.post<NftCampaignDetail, CreateNftCampaign>(
    `/project/${projectId}/nft-campaigns`,
    data
  )
  return res.data
}

const update = async (projectId: string, campaignId: string, data: UpdateNftCampaign) => {
  const res = await AuthenticatedClient.patch<NftCampaignDetail, UpdateNftCampaign>(
    `/project/${projectId}/nft-campaigns/${campaignId}`,
    data
  )
  return res.data
}

const getById = async (projectId: string, campaignId: string) => {
  const res = await AuthenticatedClient.get<NftCampaignDetail>(`/project/${projectId}/nft-campaigns/${campaignId}`)
  return res.data
}

const getAll = async (projectId: string, pagination: PaginationInput) => {
  const url = withPagination(`/project/${projectId}/nft-campaigns`, pagination)
  const res = await AuthenticatedClient.get<Paginated<NftCampaignWithImages>>(url)
  return res.data
}

const softDelete = async (projectId: string, campaignId: string) => {
  const res = await AuthenticatedClient.del(`/project/${projectId}/nft-campaigns/${campaignId}`)
  return res.data
}

const CampaignService = {
  create,
  update,
  getById,
  getAll,
  softDelete,
}

export default CampaignService
