import { Grid, GridProps, Skeleton } from '@mui/material'
import { CampaignTemplate } from '@pangea/types'
import { IntegrationIcon, Text } from '@/components'
import PredefinedTemplate from './PredefinedTemplate'
import PredefinedTemplateSkeleton from './PredefinedTemplateSkeleton'

type CampaignTemplatesProps = GridProps &
  (
    | {
        templates?: never
        label?: React.ReactNode
        isLoading: true
      }
    | {
        templates: CampaignTemplate[]
        label?: React.ReactNode
        isLoading?: false
      }
  )

const CampaignTemplates: React.FC<CampaignTemplatesProps> = ({ templates, label, isLoading, ...gridProps }) => {
  return (
    <Grid container flexDirection="column" {...gridProps}>
      <Grid item>
        <Text variant="h6" display="flex" alignItems="center" mb={1}>
          {isLoading ? (
            <Skeleton variant="rounded" width={100} />
          ) : (
            <IntegrationIcon integration={templates[0].integrationType} width={64} />
          )}
        </Text>
      </Grid>
      <Grid item container>
        {isLoading
          ? [...Array(2)].map((_, i) => (
              <Grid item lg={6} key={i}>
                <PredefinedTemplateSkeleton mr={2} />
              </Grid>
            ))
          : templates.map((template) => (
              <Grid item lg={6} key={template.id}>
                <PredefinedTemplate template={template} mr={2} />
              </Grid>
            ))}
      </Grid>
    </Grid>
  )
}

export default CampaignTemplates
