import { CreateProject, UpdateProject } from '@pangea/types'
import { Project } from '@/types/project'
import { AuthenticatedClient } from './'

async function getProject(projectId: string) {
  const res = await AuthenticatedClient.get<Project>(`/project/${projectId}`)
  return res.data
}

async function createProject(projectData: CreateProject) {
  const res = await AuthenticatedClient.post<Project, CreateProject>('/project', projectData)
  return res.data
}

interface UpdateProjectParams extends UpdateProject {
  id: string
}

async function updateProject({ id, ...projectData }: UpdateProjectParams) {
  const res = await AuthenticatedClient.patch<Project, Partial<UpdateProject>>(`/project/${id}`, projectData)
  return res.data
}

interface UploadLogoParams {
  id: string
  binary: Blob
  contentType: string
}

async function uploadLogo({ id, binary, contentType }: UploadLogoParams) {
  const res = await AuthenticatedClient.put<Project, Blob>(`/project/${id}/logo?contentType=${contentType}`, binary, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return res.data
}

const ProjectService = {
  getProject,
  createProject,
  updateProject,
  uploadLogo,
}

export default ProjectService
