import { Box, BoxProps, Skeleton } from '@mui/material'

const PredefinedTemplateSkeleton: React.FC<BoxProps> = (boxProps) => {
  return (
    <Box bgcolor="rgba(145, 158, 171, 0.16)" borderRadius={2} p={3} display="flex" {...boxProps}>
      <Skeleton variant="rounded" width={64} height={64} sx={{ mr: 3 }} />
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Skeleton variant="text" width={100} height={30} />
        <Skeleton variant="text" width={150} height={20} />
        <Skeleton variant="text" width={50} height={20} />
      </Box>
    </Box>
  )
}

export default PredefinedTemplateSkeleton
