import { ContractType } from '@pangea/types'

const getBindNftTooltipText = (isBindDisabled: boolean, containsNonErc721: boolean) => {
  if (isBindDisabled)
    return "You can't bind reward to individual NFT because your quantity on NFT rule is greater than 1."
  if (containsNonErc721)
    return `You can't bind reward to individual NFT because you selected a community with contract type ${ContractType.ERC1155}`

  return "You can't bind reward to individual NFT because your quantity on NFT rule is greater than 1."
}

export default getBindNftTooltipText
