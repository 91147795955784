import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Stack, Drawer } from '@mui/material'
import { NAV } from '../../config-global'
import Logo from '../Logo'
import Scrollbar from '../scrollbar'
import { NavSectionVertical } from '../NavSection'
import navConfig from './config-navigation'
import NavDocs from './NavDocs'
import NavAccount from './NavAccount'
import NavToggleButton from './NavToggleButton'
import useResponsive from '@/hooks/useResponsive'

type TSidebarProps = {
  hidden?: boolean
  onHide?: VoidFunction
}

const Sidebar: FC<TSidebarProps> = ({ hidden, onHide }) => {
  const { pathname } = useLocation()

  const isDesktop = useResponsive('up', 'lg')

  useEffect(() => {
    if (hidden) onHide?.()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
        alignItems="center"
      >
        <Logo />

        <NavAccount />
      </Stack>

      <NavSectionVertical data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <NavDocs />
    </Scrollbar>
  )

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      <NavToggleButton />

      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={hidden}
          onClose={onHide}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
}

export default Sidebar
