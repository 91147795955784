import { useState } from 'react'
import { Box } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'
import { FormProvider, useForm } from 'react-hook-form'
import { Moment } from 'moment'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  AllRewardDefinitionData,
  CampaignStatus,
  CampaignTemplateCode,
  ErrorCodes,
  IntegrationType,
  MindbodyCouponCodeReward,
  NftCampaignDetail,
  NFTCommunity,
  RulesType,
  SquareLoyaltyPointsReward,
  InternalSecretReward,
} from '@pangea/types'
import loadable from '@loadable/component'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorResponse } from '@pangea/types/dist/api/types'
import { LoadingScreen, Tabs } from '@/components'
import { CampaignService } from '@/api'
import { useProject, useSnack } from '@/hooks'
import RequirementsTab from '@/components/NFTCampaign/Wizard/RequirementsTab'
import RewardTab from '@/components/NFTCampaign/Wizard/RewardTab'
import GenericError from '@/components/GenericError'
import getUpdateData from '@/components/NFTCampaign/utils/getUpdateData'
import getInitialValues from '@/components/NFTCampaign/utils/getInitialValues'
import NFTCampaignWizardHeader from '@/components/NFTCampaign/Wizard/NFTCampaignWizardHeader'
import PreviewTabs from '@/components/NFTCampaign/Wizard/PreviewTab'
import CommunityTraitsModalProvider from '@/contexts/CommunityTraitsModalContext'

const ConfirmPublishModal = loadable(() => import('@/components/NFTCampaign/Wizard/ConfirmPublishModal'))
const CampaignPublishedModal = loadable(() => import('@/components/NFTCampaign/Wizard/CampaignPublishedModal'))

const schema = yup.object().shape({
  name: yup.string().required('Campaign name is required'),
})

enum Tab {
  requirements = 'Requirements',
  reward = 'Reward',
  preview = 'Preview',
}

export enum DiscountType {
  fixed = 'Fixed amount discount',
  percentage = 'Percentage discount',
  freeShipping = 'Free Shipping',
}

export type CommunityWithTraits = NFTCommunity & {
  requiredTraits: {
    traitName: string
    traitOption: string
  }[]
  count: number
}

export type FormValues = {
  name: string
  communities: CommunityWithTraits[]
  startDate: Moment
  endDate: Moment
  maxClaims: number | null
  trackNftUsed: boolean
  soulBound: boolean
  campaignTitle: string
  campaignDescription: string
  rewardTitle: string
  rewardDescription: string
  rewardDefinitionDataId: string | undefined
  loyaltyPoints: number | undefined
  fixedAmount: number | undefined
  percentageAmount: number | undefined
  freeShipping: boolean | undefined
  couponId: string
  type: RulesType
  discountType: DiscountType | undefined
  reward: string | undefined
}

const TAB_WIDTH = 1200

const NFTCampaignWizard = () => {
  const client = useQueryClient()
  const { enqueueSnackbar } = useSnack()
  const { campaignId } = useParams() as { campaignId: string }
  const [currentTab, setCurrentTab] = useState(Tab.requirements)
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false)
  const [isPublishedModalOpen, setIsPublishedModalOpen] = useState(false)
  const { project } = useProject()
  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
  })
  const { getValues } = form
  const {
    data: campaign,
    isFetching,
    isError,
  } = useQuery(['campaign', campaignId], () => CampaignService.getById(project!.id, campaignId), {
    onSuccess: (data) => {
      const initialVales = getInitialValues(data)
      form.reset(initialVales)
    },
  })

  const updateCampaign = async ({ status, campaign }: { status: CampaignStatus; campaign: NftCampaignDetail }) => {
    const campaignData = getValues()

    //todo: change this for each template code (the form data values)
    let rewardDefinitionData: AllRewardDefinitionData = {}
    const templateCode = campaign.template.templateCode

    if (CampaignTemplateCode.INTERNAL_SECRET === templateCode) {
      rewardDefinitionData = {
        name: campaignData.name,
        reward: campaignData.reward,
      } as InternalSecretReward
    } else if (
      CampaignTemplateCode.MINDBODY_COUPON_CODE === templateCode ||
      CampaignTemplateCode.MINDBODY_ITEM_GIVEAWAY === templateCode
    ) {
      rewardDefinitionData = {
        id: campaignData.rewardDefinitionDataId,
      } as MindbodyCouponCodeReward
    } else if (CampaignTemplateCode.SQUARE_LOYALTY_POINTS === templateCode) {
      rewardDefinitionData = {
        points: campaignData.loyaltyPoints,
      } as SquareLoyaltyPointsReward
    } else if (CampaignTemplateCode.SHOPIFY_DISCOUNT_CODE === templateCode) {
      rewardDefinitionData = {
        percentage: campaignData.percentageAmount,
        valueInUsd: campaignData.fixedAmount,
      }
    } else if (CampaignTemplateCode.STRIPE_DISCOUNT_CODE === templateCode) {
      rewardDefinitionData = {
        couponId: campaignData.couponId,
      }
    } else if (CampaignTemplateCode.LIGHTSPEED_GIFT_CARD === templateCode) {
      rewardDefinitionData = {
        valueInUsd: campaignData.fixedAmount,
      }
    } else if (CampaignTemplateCode.WIX_DISCOUNT_CODE === templateCode) {
      rewardDefinitionData = {
        valueInUsd: campaignData.fixedAmount,
        percentage: campaignData.percentageAmount,
        freeShipping: campaignData.freeShipping,
      }
    } else if (CampaignTemplateCode.BIGCOMMERCE_DISCOUNT_CODE === templateCode) {
      switch (campaignData.discountType) {
        case DiscountType.fixed: {
          rewardDefinitionData = {
            valueInUsd: campaignData.fixedAmount,
          }
          break
        }
        case DiscountType.percentage: {
          rewardDefinitionData = {
            percentage: campaignData.percentageAmount,
          }
          break
        }
        case DiscountType.freeShipping: {
          rewardDefinitionData = {
            freeShipping: true,
          }
          break
        }
      }
    }

    const data = getUpdateData(campaign, campaignData, status, rewardDefinitionData)
    const res = await CampaignService.update(project!.id, campaignId, data)
    setIsPublishModalOpen(false)

    if (status === 'publish') setIsPublishedModalOpen(true)

    return res
  }

  const { mutate } = useMutation(['update-campaign', campaignId], updateCampaign, {
    onSuccess: (data) => {
      client.setQueryData(['campaign', campaignId], data)
    },
    onError: (err, variables) => {
      // @ts-ignore
      const data = err?.response?.data as ErrorResponse | undefined
      let message: string = 'Failed to update campaign'

      if (data && data.errorCode === ErrorCodes.INVALID_CAMPAIGN_INTEGRATION_STATE) {
        if (variables.campaign.integration?.integrationType === IntegrationType.SQUARE) {
          message =
            'Failed to validate your Square integration. ' +
            'Please make sure that your Square account is active and that it has an active Loyalty Program.'
        }
      }

      enqueueSnackbar(message, { variant: 'error', persist: true })
    },
  })

  if (isFetching) return <LoadingScreen />
  if (isError || !campaign) return <GenericError />

  const mutateIfValid = async (callback: () => void) => {
    const isNameValid = await form.trigger('name')

    if (!isNameValid) {
      form.formState.errors.name?.message && enqueueSnackbar(form.formState.errors.name.message, { variant: 'error' })
      return
    }

    return callback()
  }

  return (
    <FormProvider {...form}>
      <CommunityTraitsModalProvider>
        <Box width="100%" height="100%" display="flex" flexDirection="column">
          <NFTCampaignWizardHeader
            campaign={campaign}
            saveChanges={() => mutateIfValid(() => mutate({ status: campaign.status as CampaignStatus, campaign }))}
            publishCampaign={() => mutateIfValid(() => mutate({ status: 'publish', campaign }))}
            deactivateCampaign={() => mutateIfValid(() => mutate({ status: 'draft', campaign }))}
          />
          <Box px={6} pt={4} height="100%" display="flex" flexDirection="column">
            <TabContext value={currentTab}>
              <Tabs
                value={currentTab}
                onChange={(e, val) => setCurrentTab(val)}
                variant="fullWidth"
                tabs={[{ value: Tab.requirements }, { value: Tab.reward }, { value: Tab.preview }]}
                sx={{ width: 460 }}
              />
              <TabPanel value={Tab.requirements} sx={{ maxWidth: TAB_WIDTH, height: '100%' }}>
                <RequirementsTab campaign={campaign} />
              </TabPanel>
              <TabPanel value={Tab.reward} sx={{ maxWidth: TAB_WIDTH }}>
                <RewardTab campaign={campaign} />
              </TabPanel>
              <TabPanel value={Tab.preview} sx={{ flexGrow: 1 }}>
                <PreviewTabs campaign={campaign} />
              </TabPanel>
            </TabContext>
          </Box>

          {isPublishModalOpen && (
            <ConfirmPublishModal
              isOpen
              onClose={() => setIsPublishModalOpen(false)}
              onConfirm={() => mutateIfValid(() => mutate({ status: 'publish', campaign }))}
            />
          )}
          {isPublishedModalOpen && (
            <CampaignPublishedModal
              campaign={campaign}
              onClose={() => setIsPublishedModalOpen(false)}
              msg="Your campaign is successfully published!"
            />
          )}
        </Box>
      </CommunityTraitsModalProvider>
    </FormProvider>
  )
}

export default NFTCampaignWizard
