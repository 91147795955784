import { SquareGetLoyaltyProgramResponse } from '@pangea/types'
import { AuthenticatedClient } from '.'

const authorize = async (projectId: string) => {
  const res = await AuthenticatedClient.get<{ url: string }>(`/project/${projectId}/integrations/square/authorize`)
  return res.data
}

const getLoyaltyProgram = async (projectId: string, integrationId: string) => {
  const res = await AuthenticatedClient.get<SquareGetLoyaltyProgramResponse>(
    `/project/${projectId}/integrations/square/loyalty?integrationId=${integrationId}`
  )
  return res.data
}

const SquareService = {
  authorize,
  getLoyaltyProgram,
}

export default SquareService
