import { Box } from '@mui/material'
import { Text } from '@/components'
import { formatCurrencyAmount } from '@/utils/formats'

interface AdditionalCostsProps {
  additionalCosts: {
    apiCost: number
    claimCost: number
  }
  links: {
    pricing: string
  }
}

const AdditionalCosts: React.FC<AdditionalCostsProps> = ({ additionalCosts, links }) => (
  <Box display="flex" alignItems="flex-end" justifyContent="center" flexDirection="column" sx={{ my: 1 }}>
    <Text color="grey.500" variant="caption" textAlign="end">
      {`Overages: ${formatCurrencyAmount(additionalCosts.apiCost)} per 1 API request, ${formatCurrencyAmount(
        additionalCosts.claimCost
      )} per claim. `}
      <a rel="noreferrer" target="_blank" href={links.pricing}>
        See detailed Pricing Plans/Features
      </a>
    </Text>
    <Text color="grey.500" variant="caption" textAlign="end">
      A claim is a user (souldbound via Nota Wallet - phone number, wallet address) who claimed the NFT Campaign defined
      by the biz
    </Text>
  </Box>
)

export default AdditionalCosts
