// hooks
import { startTransition, useState } from 'react'
import { Grid, Box, Typography, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import { useTheme } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
// ----------------------------------------------------------------------
import { useUser, useResponsive } from '@/hooks'
import { BillingService } from '@/api'
import { OnboardingStepFooter } from '@/components/onboarding'
import {
  StripeTextFieldNumber,
  StripeTextFieldCVC,
  StripeTextFieldExpiry,
  OnStripeChange,
} from './components/Stripe/StripeTextField'

import RHFCountryDropdown from './components/forms/RHFCountryDropdown'
import RHFTextField from './components/forms/RHFTextField'
import { PaymentFailedDialog, PaymentProcessingDialog, PaymentSuccessDialog } from './components'
import { BillingDetailsSchema, IFormInput } from './types'

const icons = ['/assets/icons/payments/ic_mastercard.svg', '/assets/icons/payments/ic_visa.svg']

interface Props {
  projectId: string
  onAddLaterClick: () => void
  onPaymentMethodAdded: () => void
  // TODO: thing of a better name for this
  isInAccountSettings?: boolean
  disabled?: boolean
}

const PaymentContainer = ({
  onAddLaterClick,
  projectId,
  onPaymentMethodAdded,
  isInAccountSettings,
  disabled,
}: Props) => {
  disabled = false
  const theme = useTheme()
  const isDesktop = useResponsive('up', 'md')
  const { metadata } = useUser()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [paymentError, setPaymentError] = useState<boolean>(false)
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false)

  const {
    control,
    getValues,
    formState: { isValid },
  } = useForm<IFormInput>({
    resolver: yupResolver(BillingDetailsSchema),
    mode: 'onChange',
    defaultValues: {
      zipCode: '',
      country: undefined,
      companyName: '',
      cardHolder: '',
      billingAddress: '',
    },
  })

  const [state, setState] = useState({
    cardNumber: {
      complete: false,
      error: undefined,
    },
    expired: {
      complete: false,
      error: undefined,
    },
    cvc: {
      complete: false,
      error: undefined,
    },
  })
  const onElementChange =
    (field: keyof typeof state) =>
    ({ complete, error }: OnStripeChange) =>
      setState((prev) => ({ ...prev, [field]: { complete, error: error?.message } }))

  const { cardNumber, expired, cvc } = state

  const isFormDirty = !!cardNumber.error || !!expired.error || !!cvc.error || !isValid

  const [, setError] = useState<string>('')
  const stripe = useStripe()
  const elements = useElements()

  const handleSuccessConfirmation = () => {
    startTransition(() => {
      setPaymentSuccess(false)
      onPaymentMethodAdded()
    })
  }

  const submitStripe = (stripe: Stripe | null, elements: StripeElements | null) => async () => {
    const cardElement = elements?.getElement(CardNumberElement)
    if (!cardElement || !stripe) {
      setError('Error')
      return
    }
    try {
      setIsLoading(true)
      const values = getValues()
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: values.cardHolder,
          email: metadata.email,
          phone: metadata.phone,
          address: {
            postal_code: values.zipCode,
            country: values.country?.code,
            line1: values.billingAddress,
          },
        },
      })

      if (error) {
        setIsLoading(false)
        setPaymentError(true)
      } else {
        await BillingService.addPaymentMethod({ paymentMethodId: paymentMethod?.id, projectId })
        setIsLoading(false)
        setPaymentSuccess(true)
      }
    } catch (ex: any) {
      setIsLoading(false)
      setPaymentError(true)
    }
  }

  return (
    <Stack direction="column" display="flex" flex={1}>
      <PaymentProcessingDialog title="Storing your payment information for future use" open={isLoading} />
      <PaymentFailedDialog onClose={() => setPaymentError(false)} open={paymentError} />
      <PaymentSuccessDialog
        title="Payment information successfully stored!"
        onClose={handleSuccessConfirmation}
        open={paymentSuccess}
      />
      <Box display="flex" flexDirection="column" flex={1}>
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          gap={1}
          maxWidth={isInAccountSettings ? '100%' : theme.breakpoints.values['onboarding-content']}
        >
          <Grid container spacing={isDesktop ? 3 : 5}>
            <Grid item xs={12} md={12}>
              <Box
                gap={5}
                display="grid"
                gridTemplateColumns={
                  isInAccountSettings
                    ? {}
                    : {
                        xs: 'repeat(1, 1fr)',
                        md: 'repeat(2, 1fr)',
                      }
                }
                sx={{
                  p: { md: 5 },
                  borderRadius: 2,
                  bgcolor: 'background.neutral',
                  ...(isInAccountSettings
                    ? {}
                    : {
                        border: (theme) => ({
                          md: `dashed 1px ${theme.palette.divider}`,
                        }),
                      }),
                }}
              >
                <div>
                  <div style={{ position: 'relative' }}>
                    <Typography variant="h6">Payment Info</Typography>
                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      sx={{ position: 'absolute', right: 20, top: 0 }}
                    >
                      {icons.map((icon) => (
                        <Box component="img" key={icon} src={icon} />
                      ))}
                    </Stack>
                  </div>
                  <Stack spacing={1.25} mt={5}>
                    <StripeTextFieldNumber
                      fullWidth
                      error={!!cardNumber.error}
                      helperText={cardNumber.error}
                      onChange={onElementChange('cardNumber')}
                      disabled={disabled}
                      noLabel
                    />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <StripeTextFieldExpiry
                        error={!!expired.error}
                        helperText={expired.error}
                        onChange={onElementChange('expired')}
                        disabled={disabled}
                      />
                      <StripeTextFieldCVC
                        error={!!cvc.error}
                        helperText={cvc.error}
                        onChange={onElementChange('cvc')}
                        disabled={disabled}
                      />
                    </Stack>
                    <RHFTextField
                      required={true}
                      label="Cardholder Name"
                      name="cardHolder"
                      control={control}
                      disabled={disabled}
                    />
                  </Stack>
                </div>
                <Box>
                  <Typography variant="h6">Billing Address</Typography>

                  <Stack spacing={1.25} mt={5}>
                    <RHFCountryDropdown control={control} disabled={disabled} />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <RHFTextField
                        required={true}
                        label="Zip Code"
                        name="zipCode"
                        control={control}
                        disabled={disabled}
                      />
                      <RHFTextField
                        required={true}
                        label="Billing Address"
                        name="billingAddress"
                        control={control}
                        disabled={disabled}
                      />
                    </Stack>
                    <RHFTextField
                      required={true}
                      label="Organisation Name"
                      name="companyName"
                      control={control}
                      disabled={disabled}
                    />
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {disabled ? null : (
        <OnboardingStepFooter
          secondaryButtonProps={{
            onClick: onAddLaterClick,
            children: 'Add later',
          }}
          forwardButtonProps={{
            onClick: submitStripe(stripe, elements),
            disabled: isFormDirty,
            children: 'Add card',
          }}
        />
      )}
    </Stack>
  )
}

export default PaymentContainer
