import { UseMutationResult } from 'react-query'

// TODO: Fix this types
// @ts-ignore
import { NFTCommunity, ProjectBase, ProjectType } from '@pangea/types'
import { Project } from '@/types/project'

type ProjectMutation = UseMutationResult<
  Project,
  {
    response: {
      data: {
        message: string
      }
    }
  },
  ProjectBase,
  unknown
>

export interface ConfigureYourExperienceState {
  step: ConfigureExperienceSteps
  type?: ProjectType
  id?: string
  websiteUrl?: string
  contractAddress?: string
}

export interface BrandEntryProps {
  configureYourExperienceState: ConfigureYourExperienceState
  setConfigureYourExperienceState: React.Dispatch<React.SetStateAction<ConfigureYourExperienceState>>
}

export interface BrandNFTCommunityEntryProps extends BrandEntryProps {
  projectMutation: ProjectMutation
  NFTCommunity?: NFTCommunity
  isLoading?: boolean
}

export interface BrandWebsiteEntryProps extends BrandEntryProps {
  projectMutation: ProjectMutation
  websiteUrl?: string
}

export enum ConfigureExperienceSteps {
  SELECT_GOAL,
  BRAND_ENTRY,
  COMPLETE,
}
