import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Collapse } from '@mui/material'
import useActiveLink from '@/hooks/useActiveLink'
import { NavListProps } from '../types'
import NavItem from './NavItem'

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps
  depth: number
  hasChild: boolean
}

export default function NavList({ data, depth, hasChild }: NavListRootProps) {
  const { pathname } = useLocation()

  const { active, isExternalLink } = useActiveLink(data.path, true)

  const [open, setOpen] = useState(active)

  useEffect(() => {
    if (!active) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active}
        isExternalLink={isExternalLink}
        onClick={handleToggle}
      />

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          {data.children.map((list: NavListProps) => (
            <NavList key={list.title + list.path} data={list} depth={depth + 1} hasChild={!!list.children} />
          ))}
        </Collapse>
      )}
    </>
  )
}
