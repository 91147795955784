import useOnboarding from '@/hooks/useOnboarding'
import { Steps } from './utils'

const OnboardingSteps: React.FC = () => {
  const { activeStep } = useOnboarding()
  const Component = Steps[activeStep].component
  return <Component />
}

export default OnboardingSteps
