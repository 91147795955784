import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { openInNewTab } from '@shared/utils/browser'
import loadable from '@loadable/component'
import { NftCampaignDetail } from '@pangea/types'
import { useEnv, useProject } from '@/hooks'
import { CampaignService } from '@/api'
import IconButton from '../Button/IconButton'
import { Iconify, Menu } from '..'
import CampaignPublishedModal from './Wizard/CampaignPublishedModal'

const ConfirmDeleteModal = loadable(() => import('@/components/NFTCampaign/ConfirmDeleteModal'))

type onDeleteFn = (campaignId: string) => Promise<void>

interface CampaignOptionsProps {
  campaign: NftCampaignDetail
  onCampaignDeleted: onDeleteFn
}

const CampaignOptions: React.FC<CampaignOptionsProps> = ({ campaign, onCampaignDeleted }) => {
  const { REACT_APP_NOTA_URL } = useEnv()
  const navigate = useNavigate()
  const { project } = useProject()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isEmbedModalOpen, setIsEmbedModalOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const onCampaignDeleteClick = () => {
    setDeleteConfirmationModalOpen(true)
  }

  const deleteCampaign = async () => {
    setLoading(true)
    try {
      await CampaignService.softDelete(project!.id, campaign.id)
      await onCampaignDeleted(campaign.id)
    } catch (e) {
      alert('Error deleting campaign: ' + e)
    } finally {
      setDeleteConfirmationModalOpen(false)
      setLoading(false)
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={handleClick}>
        <Iconify icon="simple-line-icons:options-vertical" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        items={[
          {
            startIcon: <Iconify icon="eva:edit-fill" />,
            children: 'Edit',
            onClick: () => navigate(`/campaigns/${campaign.id}`),
          },
          // { startIcon: <Iconify icon="mingcute:code-line" />, children: 'Embed on page' },
          {
            startIcon: <Iconify icon="majesticons:open-line" />,
            children: 'Open Utility',
            onClick: () => openInNewTab(`${REACT_APP_NOTA_URL}/campaign/${campaign.id}`),
          },
          campaign.status === 'live'
            ? {
                startIcon: <Iconify icon="mingcute:code-fill" />,
                children: 'Embed campaign',
                onClick: () => setIsEmbedModalOpen(true),
              }
            : {},
          // { startIcon: <Iconify icon="mi:download" />, children: 'Export claims' },
          // { startIcon: <Iconify icon="bxs:copy" />, children: 'Duplicate' },
          {
            startIcon: <Iconify icon="ph:trash-bold" />,
            children: 'Delete',
            sx: {
              color: 'error.main',
            },
            onClick: () => onCampaignDeleteClick(),
          },
        ]}
      />
      {isDeleteConfirmationModalOpen && (
        <ConfirmDeleteModal
          isOpen={isDeleteConfirmationModalOpen}
          onClose={() => setDeleteConfirmationModalOpen(false)}
          onConfirm={() => deleteCampaign()}
          enableButtons={!isLoading}
        />
      )}
      {isEmbedModalOpen && (
        <CampaignPublishedModal
          campaign={campaign}
          onClose={() => {
            setIsEmbedModalOpen(false)
            handleClose()
          }}
        />
      )}
    </div>
  )
}

export default CampaignOptions
