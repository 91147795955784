import { FC } from 'react'
import { FormControl, RadioGroup as MUIRadioGroup, RadioGroupProps } from '@mui/material'

const RadioGroup: FC<RadioGroupProps> = (props) => (
  <FormControl>
    <MUIRadioGroup {...props} />
  </FormControl>
)

export default RadioGroup
