// @mui
import { Box, Stack, DialogTitle, DialogProps, DialogActions, DialogContent } from '@mui/material'
// components
import { Button, Image, Dialog } from '@/components'
import { images } from '@/assets/pricing'
// ----------------------------------------------------------------------

interface Props extends DialogProps {
  onClose: VoidFunction
  title: string
}

export default function PaymentSuccessDialog({ title, open, onClose, ...other }: Props) {
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
        <DialogTitle textAlign="center">{title}</DialogTitle>

        <DialogContent sx={{ overflow: 'unset' }}>
          <Stack spacing={3}>
            <Box alignItems="center" justifyContent="center" display="flex">
              <Image src={images.success} />
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="contained" onClick={onClose}>
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
