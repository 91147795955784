import { useCallback, useRef, useState } from 'react'
import { alpha } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { getProjectLogoUrl } from '@shared/utils/project'
import { useProject, useUser } from '@/hooks'
import { Iconify } from '@/components'
import { Avatar } from '../Avatar'
import ProjectDropdown from './ProjectDropdown'

interface NavAccountProps {
  variant?: 'default' | 'mini'
}

export default function NavAccount({ variant = 'default' }: NavAccountProps) {
  const isMini = variant === 'mini'
  const { metadata } = useUser()
  const { project } = useProject()
  const boxRef = useRef<null | HTMLElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = useCallback(() => setIsOpen(true), [])
  const handleClose = useCallback(() => setIsOpen(false), [])

  const currProject = {
    photoURL: getProjectLogoUrl(project!.id),
    name: project?.name || 'Project name',
  }

  const { firstName, lastName } = metadata

  return (
    <>
      <Box
        ref={boxRef}
        id="project-menu"
        onClick={handleOpen}
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: isMini ? 'column' : 'row',
          padding: theme.spacing(2, isMini ? 0 : 2.5),
          borderRadius: 1.5,
          backgroundColor: !isMini ? alpha(theme.palette.grey[500], 0.12) : '',
          mb: isMini ? 8 : 0,
          cursor: 'pointer',
        })}
      >
        <Avatar src={currProject.photoURL} alt={currProject.name} />
        {!isMini && (
          <>
            <Box sx={{ ml: 2, width: '100%' }}>
              <Typography variant="subtitle2" noWrap overflow="ellipsis" minWidth={0}>
                {currProject.name}
              </Typography>
              {firstName && lastName && (
                <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                  {firstName} {lastName}
                </Typography>
              )}
            </Box>
            <Box display="flex" alignItems="center">
              <Iconify icon="tabler:chevron-down" />
            </Box>
          </>
        )}
      </Box>
      {isOpen && <ProjectDropdown anchorEl={boxRef.current} onClose={handleClose} />}
    </>
  )
}
