import { IntegrationType, IntegrationStatus as Status } from '@pangea/types'
import { Button } from '@/components'

const actions: Record<Status, string> = {
  [Status.ACTIVE]: 'Disconnect',
  [Status.PENDING]: 'Cancel',
  [Status.INACTIVE]: 'Connect',
}

interface IntegrationActionProps {
  openModal: (integrationType: IntegrationType) => void
  status: Status
  integrationType: IntegrationType
  showDisconnectModal: () => void
}

const IntegrationAction: React.FC<IntegrationActionProps> = ({
  openModal,
  status,
  integrationType,
  showDisconnectModal,
}) => {
  const actionHandlers: Record<Status, () => void> = {
    [Status.ACTIVE]: showDisconnectModal,
    [Status.PENDING]: showDisconnectModal,
    [Status.INACTIVE]: () => openModal(integrationType),
  }

  return (
    <>
      <Button
        variant="outlined"
        color={status === Status.INACTIVE ? 'primary' : 'error'}
        onClick={actionHandlers[status]}
      >
        {actions[status]}
      </Button>
    </>
  )
}

export default IntegrationAction
