import { Box, BoxProps } from '@mui/material'
import { forwardRef } from 'react'

interface RoundContainerProps extends BoxProps {
  bgOnHover?: boolean
}

const RoundContainer: React.FC<RoundContainerProps> = forwardRef((props, ref) => (
  <Box
    ref={ref}
    bgcolor="background.neutral"
    display="flex"
    alignItems="center"
    justifyContent="center"
    width={28}
    height={28}
    borderRadius={2}
    {...props}
    sx={{
      backgroundColor: props.bgOnHover ? '' : 'background.neutral',
      ':hover': {
        bgcolor: props.bgOnHover ? 'background.neutral' : '',
        cursor: props.bgOnHover ? 'pointer' : '',
      },
      ...props.sx,
    }}
  />
))

RoundContainer.displayName = 'RoundContainer'

export default RoundContainer
