import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import ThemeProvider from '@/theme'
import { SnackBarProvider, SomethingWentWrong } from '@/components'
import { SessionProvider } from '@/utils/session'
import { SettingsProvider } from '@/components/settings'
import { routes } from '@/utils/routes'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const router = createBrowserRouter(routes)

class App extends React.Component {
  state = {
    didComponentCatch: false,
  }

  componentDidCatch() {
    this.setState({ didComponentCatch: true })
  }

  render() {
    if (this.state.didComponentCatch) return <SomethingWentWrong />

    return (
      <SettingsProvider>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <SnackBarProvider>
              <SessionProvider>
                <AnimatePresence>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <RouterProvider router={router} />
                  </LocalizationProvider>
                </AnimatePresence>
              </SessionProvider>
            </SnackBarProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </SettingsProvider>
    )
  }
}

export default App
