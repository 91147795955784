import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Popover(theme: Theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: theme.spacing(1),
          overflow: 'inherit',
          boxShadow: theme.customShadows.dropdown,
          background: theme.palette.background.paper,
          borderRadius: Number(theme.shape.borderRadius) * 1.5,
        },
      },
    },
  }
}
