import { NFTCommunity } from '@pangea/types'
import { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useSnack } from '@/hooks'

interface SelectedCommunitiesContextData {
  selectedCommunities: NFTCommunity[]
  addCommunity: (community: NFTCommunity) => void
  removeCommunity: (community: NFTCommunity) => void
  clearCommunities: () => void
}

export const SelectedCommunitiesContext = createContext<SelectedCommunitiesContextData>(
  {} as SelectedCommunitiesContextData
)

const SelectedCommunitiesProvider = () => {
  const [selectedCommunities, setSelectedCommunities] = useState<NFTCommunity[]>([])
  const { enqueueSnackbar } = useSnack()

  const addCommunity = (community: NFTCommunity) => {
    if (!selectedCommunities.find((c) => c.address === community.address)) {
      if (selectedCommunities.length < 8) setSelectedCommunities([...selectedCommunities, community])
      else enqueueSnackbar('You can only select up to 8 communities', { variant: 'info' })
    }
  }
  const removeCommunity = (community: NFTCommunity) =>
    setSelectedCommunities(selectedCommunities.filter((c) => c.address !== community.address))
  const clearCommunities = () => setSelectedCommunities([])

  return (
    <SelectedCommunitiesContext.Provider
      value={{ selectedCommunities, addCommunity, removeCommunity, clearCommunities }}
    >
      <Outlet />
    </SelectedCommunitiesContext.Provider>
  )
}

export default SelectedCommunitiesProvider
