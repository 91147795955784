import { FC, useMemo } from 'react'
import { Menu as MUIMenu, MenuProps as MUIMenuProps, MenuItem, MenuItemProps, ListItemIcon } from '@mui/material'

interface MenuProps extends MUIMenuProps {
  items: IMenuItemProps[]
}

export interface IMenuItemProps extends MenuItemProps {
  startIcon?: React.ReactElement
}

const Menu: FC<MenuProps> = ({ items, ...props }) => {
  const menuItems = useMemo(() => {
    return items.map(({ startIcon, children, ...itemProps }: IMenuItemProps, index) => (
      <MenuItem key={index} {...itemProps}>
        {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
        {children}
      </MenuItem>
    ))
  }, [items])

  return <MUIMenu {...props}>{menuItems}</MUIMenu>
}

export default Menu
