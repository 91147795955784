import { Button, Image, Text } from '@/components'
import ErrorPageContainer from './ErrorPageContainer'

const SomethingWentWrong = () => {
  return (
    <ErrorPageContainer>
      <Image src="/assets/illustrations/something-went-wrong.svg" alt="404 illustration" maxWidth={440} />
      <Text variant="h1" fontSize={64} mt="59px" mb={2} textAlign="center">
        Oops...
      </Text>
      <Text textAlign="center" maxWidth={480}>
        An unexpected error has occurred, but rest assured, our developers have been alerted and are actively working to
        fix it.
      </Text>
      <Button sx={{ marginTop: '70px' }} onClick={() => window.location.replace('/')}>
        Return to Homepage
      </Button>
    </ErrorPageContainer>
  )
}

export default SomethingWentWrong
